import React from 'react';
import {Formik} from "formik";
import moment from "moment";
import {Dialog, DialogActions, DialogContent, Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography/Typography";
import {
    Button as FormButton,
    Button,
    EnrollmentFileUploader as FileUploader,
    Label
} from "../../../../../components/formInputs";
import {createStyles, Theme, withStyles} from "@material-ui/core/styles";
import {DialogTitleProps} from "../modals/ViewDocument";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import {ErrorCommon, SelectField, TextFieldCommon} from "../../../../../components/formikFormInputs";
import {
    ClientDocumentType,
    commonDocNameArr,
    cssPaths,
    DocNameAndType,
    wealthTransferDocArr,
    incomeEarnerDocArr,
    spouseDocArr, GWT_GEN2_STRATEGY,
} from "../../../../../constants";
import {useActions} from "../../../../../actions";
import * as Actions from "../../../../../actions/account";
import ViewAndDelete from "../../../Enrollment/EnrollmentHome/basicInfo/ViewAndDelete";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../reducers";
import Confirmation from '../modals/Confirmation';

const tags = {
    "document_tabs" : "document_tabs",
}

type Props = {
    clientDetail: any,
    open: boolean,
    handleClose: () => void,
    uploadCoverLetter: boolean,
    setCoverLetterReq: Function,
    coverLetterUploadedFromActionItem?: boolean,
    documentsTab? : boolean,
    setCoverLetterActiveCasePopUp?: Function,
    uploadAdditionalTaxDocs?: boolean,
    tag?: keyof typeof tags,
    documentList?:any
}
const titleStyles = (theme: Theme) =>
        createStyles({
            root: {
                margin: 0,
                padding: theme.spacing(2),
            },
            closeButton: {
                position: "absolute",
                right: theme.spacing(1),
                top: '18px',
                color: theme.palette.grey[500],
            },
        });
const DialogTitle = withStyles(titleStyles)((props: DialogTitleProps) => {
    const {children, classes, onClose, ...other} = props;
    return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                            <CloseIcon className="f20"/>
                        </IconButton>
                ) : null}
            </MuiDialogTitle>
    );
});


const submitFormik = (values: any, setSubmitting: Function, resetForm: Function) => {
}
const validate = ((value: any) => {
    const errors: { documentSubType?: string } = {};
    if (!value.documentSubType) {
        errors.documentSubType = "Required";
    }
    return errors;
});
const showTwoUploadForArr = ["IDENTITY_DOCUMENT", "TAX_DOCUMENT", "RESIDENCE_DOCUMENT", "TAX_DOCUMENT_INCOME_EARNER", "TAX_DOCUMENT_SPOUSE", "TAX_DOCUMENT_WEALTH_TRANSFER"];

function UploadSpecificDocument(props: Props) {
    const {clientDetail, open, handleClose, uploadCoverLetter, setCoverLetterReq, coverLetterUploadedFromActionItem = false, documentsTab = false, uploadAdditionalTaxDocs, setCoverLetterActiveCasePopUp
    } = props;
    const { user: { role } } = useSelector((state: RootState) => state.auth);
    const accountActions = useActions(Actions);
    let directory: string = (clientDetail && clientDetail.enrollment && clientDetail.enrollment.id) || clientDetail.enrollmentId;
    const [docType, setDocType] = React.useState("");
    const [selectArr, setSelectArr] = React.useState<{ id?: string, value?: string, name?: string, key?: string }[]>([]);
    const [docTypeText1, setDocTypeText1] = React.useState<string>("");
    const [docTypeText2, setDocTypeText2] = React.useState<string>("");
    const [docName1, setDocName1] = React.useState<string>("");
    const [docName2, setDocName2] = React.useState<string>("");
    const [onProgress, setOnProgress] = React.useState(false);
    const [docSubName, setDocSubName] = React.useState<string>("");
    const [openConfirmation , setOpenConfirmation]  = React.useState<boolean>(false)
    const [title, setTitle] = React.useState<string>("");
    const [identityFrontPrevPhoto, setIdentityFrontPrevPhoto] = React.useState("");
    const [identityBackPrevPhoto, setIdentityBackPrevPhoto] = React.useState("");

    const [utilityBillPrevPhoto, setUtilityBillPrevPhoto] = React.useState("");
    const [residenceFrontPrevPhoto, setResidenceFrontPrevPhoto] = React.useState("");

    const [residenceBackPrevPhoto, setResidenceBackPrevPhoto] = React.useState("");
    const [otherDocPrevPhoto, setOtherDocPrevPhoto] = React.useState("");

    const [taxDocumentYearOne, setTaxDocumentYearOne] = React.useState("");
    const [taxDocumentYearTwo, setTaxDocumentYearTwo] = React.useState("");


    const [actionObj1, setActionObj1] = React.useState<any>({});
    const [actionObj2, setActionObj2] = React.useState<any>({});

    const isGWTGen2 = (clientDetail.client && clientDetail.client.strategy && clientDetail.client.strategy.id === GWT_GEN2_STRATEGY);

    const user = clientDetail;
    const {client} = user;
    const userId: string = (client && client.userId) || "";
    const fileType: string = "image/jpg, image/jpeg, image/png, application/pdf";
    const [initialValues, setInitialValues] = React.useState({
        documentSubType: "",
    });
    const resetState = () => {
        setInitialValues({
            documentSubType: ""
        });
        setDocType("");
        setDocTypeText1("");
        setDocTypeText2("");
        setDocName1("");
        setDocName2("");
    }
    React.useEffect(() => {
        const arr = commonDocNameArr;
        const tempArr: any = [];
        //logic to have selection option according to identity type, usCitizen and isDocumentAddress of client : start
        let list = arr.filter((item, index) => {
            if (clientDetail.client && clientDetail.client.identityType && clientDetail.client.identityType === "drivingLicence") {
                return item !== "Passport";
            } else {
                return item !== "Drivers License";
            }
        });
        list = list.filter((item, index) => {
            if (clientDetail.client && (clientDetail.client.isDocumentAddress || clientDetail.client.identityType === "drivingLicence")) {
                return item !== "Utility Bill";
            } else {
                return item;
            }
        });
        list = list.filter((item, index) => {
            if (clientDetail.client && clientDetail.client.usCitizen) {
                return item !== "Visa" && item !== "Green Card" && item !== "Permanent Residency Card";
            } else {
                return item;
            }
        });


        if(uploadAdditionalTaxDocs){
            list = [];
        }

        if ((!documentsTab) || (documentsTab && clientDetail.enrollment && clientDetail.enrollment.clientSigned === 1)) {
            if (isGWTGen2) {
                const taxDocArr = arr.filter((item: string, _) => {
                    return (DocNameAndType[`${item}`].docType === "TAX_DOCUMENT") || (DocNameAndType[`${item}`].docType === "MORE_DOCUMENTS");
                }).map(item => (`${item} (GEN 1 GWT)`));
                list.push(...taxDocArr);
            } else if (clientDetail.client.currentlyEmployed && clientDetail.client.currentlyEmployed.toLowerCase() === "other") {
                if (clientDetail.client.parentsAreIncomeEarner) { // case of wealth transfer
                    list.push(...wealthTransferDocArr);
                } else {
                    list.push(...incomeEarnerDocArr);
                }
            } else {
                list.push(...spouseDocArr)
            }
        }

        /*list = list.filter((item, index) => {
            if (clientDetail.client.currentlyEmployed && clientDetail.client.currentlyEmployed.toLowerCase() !== "other") {
                return item !== "W2’s (Income Earner)" && item !== "Tax Returns (Income Earner)" && item !== "1099’s (Income Earner)";
            } else {
                return item;
            }
        });*/

        //console.log('currentlyEmployed', clientDetail.client.currentlyEmployed.toLowerCase());
        //logic to have selection option according to identity type, usCitizen and isDocumentAddress of client : end
        if (role === 4 && documentsTab && uploadAdditionalTaxDocs) {
            /*list = list.filter((item, _)=>{
                return item == "W2’s (Income Earner)" || item == "Tax Returns (Income Earner)" || item == "1099’s (Income Earner)";
            })*/
            if (clientDetail.client.currentlyEmployed && clientDetail.client.currentlyEmployed.toLowerCase() === "other") {
                if(clientDetail.client.parentsAreIncomeEarner) { // case of wealth transfer
                    list = wealthTransferDocArr;
                } else {
                    list = incomeEarnerDocArr;
                }
            } else {
                list = spouseDocArr;
            }
        }
        list.forEach((item, _) => {
            tempArr.push({
                id: item,
                value: item,
                name: item,
                key: item
            })
        })

        setSelectArr(tempArr);
        if (uploadCoverLetter) {
            setInitialValues({
                documentSubType: "Cover Letter"
            });
            setDocType("COVER_LETTER");
            setDocSubName("Cover Letter")
            setDocTypeText1("cover letter");
            setDocTypeText2("");
        }
        if (!uploadCoverLetter) {
            resetState();
        }
        return (() => {
            resetState();
        })
    }, [uploadCoverLetter]);

    React.useEffect(() => {
        if (docType === "TAX_DOCUMENT_INCOME_EARNER" || docType === "TAX_DOCUMENT_SPOUSE" || docType === "TAX_DOCUMENT_WEALTH_TRANSFER") {
            if (docName2) {
                setActionFn('back')
            }
            if (docName1) {
                setActionFn('front')
            }
        }
    }, [docType, docName1, docName2])
    const isDisable = ((Object.keys(actionObj1).length > 0 || Object.keys(actionObj2).length > 0) && (docType === "TAX_DOCUMENT_INCOME_EARNER" || docType === "TAX_DOCUMENT_SPOUSE" || docType === "TAX_DOCUMENT_WEALTH_TRANSFER"))
    const fieldObject: any = [{
        label: <Label label={!coverLetterUploadedFromActionItem ? "Document DropDown List" : "Selected Document"} required={!coverLetterUploadedFromActionItem ? true : false}/>,
        id: "documentSubType",
        name: "documentSubType",
        placeholder: "Select Carrier",
        component: SelectField,
        options: selectArr,
        disabled: coverLetterUploadedFromActionItem || isDisable,
        onSelectChange: async (val: string) => {
            if (val in DocNameAndType) {
                setDocType(DocNameAndType[val].docType);
                setDocSubName(val);
                setDocName1("");
                setDocName2("");
                switch (DocNameAndType[val].docType) {
                    case "IDENTITY_DOCUMENT":
                        setDocTypeText1("identity document front");
                        setDocTypeText2("identity document back");
                        break;
                    case "TAX_DOCUMENT":
                        setDocTypeText1("tax document year 1");
                        setDocTypeText2("tax document year 2");
                        break;
                    case "UTILITY_BILL":
                        setDocTypeText1("utility bill");
                        setDocTypeText2("");
                        break;
                    case "RESIDENCE_DOCUMENT":
                        setDocTypeText1("residence document front");
                        setDocTypeText2("residence document back");
                        break;
                    case "MORE_DOCUMENTS":
                        setDocTypeText1("more document");
                        setDocTypeText2("");
                        break;
                    case "COVER_LETTER":
                        setDocTypeText1("cover letter");
                        setDocTypeText2("");
                        break;
                    case "CARRIER_ILLUSTRATION_INPUTS":
                        setDocTypeText1("carrier illustration inputs");
                        setDocTypeText2("");
                        break;
                    case "TAX_DOCUMENT_INCOME_EARNER":
                        setDocTypeText1(`${val.toLowerCase()} year 1`);
                        setDocTypeText2(`${val.toLowerCase()} year 2`);
                        break;
                    case "TAX_DOCUMENT_SPOUSE":
                        setDocTypeText1(`${val.toLowerCase()} year 1`);
                        setDocTypeText2(`${val.toLowerCase()} year 2`);
                        break;
                    case "TAX_DOCUMENT_WEALTH_TRANSFER":
                        setDocTypeText1(`${val.toLowerCase()} year 1`);
                        setDocTypeText2(`${val.toLowerCase()} year 2`);
                        break;
                }
            }
            if ((DocNameAndType[val].docType === "TAX_DOCUMENT_INCOME_EARNER" ||
                            DocNameAndType[val].docType === "TAX_DOCUMENT_SPOUSE" || DocNameAndType[val].docType === "TAX_DOCUMENT_WEALTH_TRANSFER")
                    && client) {
                const res = await accountActions.getUploadedTaxDoc({clientId: client.id, docType: DocNameAndType[val].docType});
                res && res.length > 0 && res.forEach((item) => {
                    if (item.name.includes("YEAR 2") && item.documentSubType === val) {
                        setDocName2(item.path);
                    } else if (item.name.includes("YEAR 1") && item.documentSubType === val) {
                        setDocName1(item.path);
                    }
                })
            }
        },
    }];

    const IsNewDocument = () =>{
        let isNewDoc = true;
        if (docType && props.documentList) {
            props.documentList.findIndex((data)=> data.documentType == docType)
            if(props.documentList.findIndex((data)=> data.documentType == docType) > -1){
                isNewDoc = false
            }
        }
        return isNewDoc;

    } 
    React.useEffect(()=>{
        if (props.tag == tags.document_tabs && docType && !IsNewDocument()) {
            setOpenConfirmation(true)
        }
    },[docType])

    const updateDocument = async (value: string, field: string, frontOrBack: string) => {
        let payload = {};
        let documentName = "";
        if (docType) {
            switch (docType) {
                case ClientDocumentType.TAX_DOCUMENT :
                    documentName = frontOrBack == "front" ? "TAX DOCUMENT YEAR 1" : "TAX DOCUMENT YEAR 2";

                    payload["documentSubType"] = frontOrBack === "front" ? "YEAR_ONE" : "YEAR_TWO";
                    payload["taxDocumentYear1"] = frontOrBack === "front" ? value : "";
                    payload["taxDocumentYear2"] = frontOrBack === "back" ? value : "";
                    payload["newUploaded"] = false;

                    break;
                case ClientDocumentType.IDENTITY_DOCUMENT :
                    documentName = frontOrBack == "front" ?
                            docSubName === "Drivers License" ? "Driver License Front" : "Passport Front" :
                            docSubName === "Drivers License" ? "Driver License Back" : "Passport Back";

                    payload["documentSubType"] = frontOrBack;
                    payload["identityFrontPhoto"] = frontOrBack === "front" ? value : "";
                    payload["identityBackPhoto"] = frontOrBack === "back" ? value : "";
                    payload["newUploaded"] = false;

                    break;
                case ClientDocumentType.RESIDENCE_DOCUMENT :
                    documentName = frontOrBack == "front" ? "Residency Card Front" : "Residency Card Back";

                    payload["documentSubType"] = frontOrBack;
                    payload["residenceFrontPhoto"] = frontOrBack === "front" ? value : "";
                    payload["residenceBackPhoto"] = frontOrBack === "back" ? value : "";
                    payload["newUploaded"] = false;
                    break;
                case ClientDocumentType.UTILITY_BILL :
                    documentName = "Utility Bill";

                    payload["utilityBillPhoto"] = value;
                    payload["newUploaded"] = false;

                    break;
                case ClientDocumentType.MORE_DOCUMENTS:
                    documentName = "Other";

                    payload["otherDocument"] = value;
                    payload["otherDocName"] = documentName;
                    payload["newUploaded"] = false;

                    break;
                case ClientDocumentType.COVER_LETTER:
                    documentName = "Cover Letter";
                    payload["coverLetter"] = value;
                    payload["coverLetterName"] = documentName;
                    payload["newUploaded"] = false;
                    break;
                case ClientDocumentType.CARRIER_ILLUSTRATION_INPUTS:
                    documentName = "Carrier Illustration Inputs";
                    payload["carrierIllusrationInputs"] = value;
                    payload["carrierIllusrationInputsName"] = documentName;
                    payload["newUploaded"] = false;
                    break;

                case ClientDocumentType.TAX_DOCUMENT_INCOME_EARNER :
                    documentName = frontOrBack == "front" ? "TAX DOCUMENT INCOME EARNER YEAR 1" : "TAX DOCUMENT INCOME EARNER YEAR 2";

                    payload["documentSubType"] = frontOrBack === "front" ? "YEAR_ONE" : "YEAR_TWO";
                    payload["taxDocumentIncomeEarnerYear1"] = frontOrBack === "front" ? value : "";
                    payload["taxDocumentIncomeEarnerYear2"] = frontOrBack === "back" ? value : "";
                    payload["newUploaded"] = false;

                    break;

                case ClientDocumentType.TAX_DOCUMENT_SPOUSE :
                    documentName = frontOrBack == "front" ? "TAX DOCUMENT SPOUSE YEAR 1" : "TAX DOCUMENT SPOUSE YEAR 2";

                    payload["documentSubType"] = frontOrBack === "front" ? "YEAR_ONE" : "YEAR_TWO";
                    payload["taxDocumentIncomeEarnerYear1"] = frontOrBack === "front" ? value : "";
                    payload["taxDocumentIncomeEarnerYear2"] = frontOrBack === "back" ? value : "";
                    payload["newUploaded"] = false;

                    break;

                case ClientDocumentType.TAX_DOCUMENT_WEALTH_TRANSFER :
                    documentName = frontOrBack == "front" ? "TAX DOCUMENT WEALTH TRANSFER YEAR 1" : "TAX DOCUMENT WEALTH TRANSFER YEAR 2";

                    payload["documentSubType"] = frontOrBack === "front" ? "YEAR_ONE" : "YEAR_TWO";
                    payload["taxDocumentIncomeEarnerYear1"] = frontOrBack === "front" ? value : "";
                    payload["taxDocumentIncomeEarnerYear2"] = frontOrBack === "back" ? value : "";
                    payload["newUploaded"] = false;

                    break;
            }
        }
        payload["formType"] = "documents";
        payload["name"] = documentName;
        payload["docName"] = documentName;
        payload["documentType"] = field;
        payload["operation"] = "Uploaded";
        payload["path"] = value;
        payload["docSubName"] = docSubName;
        if (userId) {
            try {
                await accountActions.updateUserBasicDetails(payload, userId);
                if (docType === "COVER_LETTER") {
                    setCoverLetterReq(false);
                    if (typeof setCoverLetterActiveCasePopUp === 'function') {
                        setCoverLetterActiveCasePopUp(false);
                    }
                }
            } catch (err) {
                return false;
            }
        }
    };
    const setActionFn = (frontOrBack: string) => {
        if (docType) {
            switch (docType) {
                case ClientDocumentType.IDENTITY_DOCUMENT:
                    if (frontOrBack == 'front') {
                        setActionObj1({
                            key: "identityFrontPhoto",
                            type: ClientDocumentType.IDENTITY_DOCUMENT,
                            side: "front"
                        })
                    } else {
                        setActionObj2({
                            key: "identityBackPhoto",
                            type: ClientDocumentType.IDENTITY_DOCUMENT,
                            side: "back",
                        })
                    }
                    setTitle("Identity Document")
                    break;
                case ClientDocumentType.TAX_DOCUMENT :
                    if (frontOrBack == 'front') {
                        setActionObj1({
                            key: "TAX_DOCUMENT",
                            type: ClientDocumentType.TAX_DOCUMENT,
                            side: "YEAR_ONE",
                        })
                        setTitle("Tax Document Year 1")
                    } else {
                        setActionObj2({
                            key: "TAX_DOCUMENT",
                            type: ClientDocumentType.TAX_DOCUMENT,
                            side: "YEAR_TWO",
                        })
                        setTitle("Tax Document Year 2")
                    }
                    break;
                case  ClientDocumentType.UTILITY_BILL:
                    if (frontOrBack == 'front') {
                        setActionObj1({
                            key: "utilityBillPhoto",
                            type: ClientDocumentType.UTILITY_BILL,
                            side: "",
                        })
                    }
                    setTitle("Utility Bill");
                    break;
                case ClientDocumentType.RESIDENCE_DOCUMENT:
                    if (frontOrBack == 'front') {
                        setActionObj1({
                            key: "residenceFrontPhoto",
                            type: ClientDocumentType.RESIDENCE_DOCUMENT,
                            side: "front",
                        })
                    } else {
                        setActionObj2({
                            key: "residenceBackPhoto",
                            type: ClientDocumentType.RESIDENCE_DOCUMENT,
                            side: "back",
                        })
                    }
                    setTitle("Residence Document");
                    break;
                case ClientDocumentType.MORE_DOCUMENTS:
                    if (frontOrBack == 'front') {
                        setActionObj1({
                            key: "OtherDocument",
                            type: ClientDocumentType.MORE_DOCUMENTS,
                            side: "",
                        })
                    }
                    setTitle("Other Document");
                    break;
                case ClientDocumentType.COVER_LETTER:
                    if (frontOrBack == 'front') {
                        setActionObj1({
                            key: "coverLetter",
                            type: ClientDocumentType.COVER_LETTER,
                            side: "",
                        })
                    }
                    setTitle("Cover Letter");
                    break;
                case ClientDocumentType.CARRIER_ILLUSTRATION_INPUTS:
                    if (frontOrBack == 'front') {
                        setActionObj1({
                            key: "carrierIllustrationInputs",
                            type: ClientDocumentType.CARRIER_ILLUSTRATION_INPUTS,
                            side: "",
                        })
                    }
                    setTitle("Carrier Illustration Inputs");
                    break;
                case ClientDocumentType.TAX_DOCUMENT_INCOME_EARNER :
                    if (frontOrBack == 'front') {
                        setActionObj1({
                            key: "TAX_DOCUMENT_INCOME_EARNER",
                            type: ClientDocumentType.TAX_DOCUMENT_INCOME_EARNER,
                            side: "YEAR_ONE",
                        })
                        setTitle("Tax Document Income Earner Year 1")
                    } else {
                        setActionObj2({
                            key: "TAX_DOCUMENT_INCOME_EARNER",
                            type: ClientDocumentType.TAX_DOCUMENT_INCOME_EARNER,
                            side: "YEAR_TWO",
                        })
                        setTitle("Tax Document Income Earner Year 2")
                    }
                    break;

                case ClientDocumentType.TAX_DOCUMENT_SPOUSE :
                    if (frontOrBack == 'front') {
                        setActionObj1({
                            key: "TAX_DOCUMENT_SPOUSE",
                            type: ClientDocumentType.TAX_DOCUMENT_SPOUSE,
                            side: "YEAR_ONE",
                        })
                        setTitle("Tax Document Spouse Year 1")
                    } else {
                        setActionObj2({
                            key: "TAX_DOCUMENT_SPOUSE",
                            type: ClientDocumentType.TAX_DOCUMENT_SPOUSE,
                            side: "YEAR_TWO",
                        })
                        setTitle("Tax Document Spouse Year 2")
                    }
                    break;

                case ClientDocumentType.TAX_DOCUMENT_WEALTH_TRANSFER :
                    if (frontOrBack == 'front') {
                        setActionObj1({
                            key: "TAX_DOCUMENT_WEALTH_TRANSFER",
                            type: ClientDocumentType.TAX_DOCUMENT_WEALTH_TRANSFER,
                            side: "YEAR_ONE",
                        })
                        setTitle("Tax Document Wealth Transfer Year 1")
                    } else {
                        setActionObj2({
                            key: "TAX_DOCUMENT_WEALTH_TRANSFER",
                            type: ClientDocumentType.TAX_DOCUMENT_WEALTH_TRANSFER,
                            side: "YEAR_TWO",
                        })
                        setTitle("Tax Document Wealth Transfer Year 2")
                    }
                    break;

            }
        }
    }

    const onSaveClose = async(values) =>{
        if(showTwoUploadForArr.includes(docType) && docName2){
            await updateDocument(docName2, docType, "back");
        }
        if(values["documentSubType"] && docName1){
            await updateDocument(docName1, docType, "front");
        }
        handleClose()
    }

    return <>
        <link rel="stylesheet" href={`${process.env.PUBLIC_URL +  cssPaths.clientProfile}`}/>
        <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            Document Upload sss
        </DialogTitle>
        <DialogContent dividers style={{minHeight: 220,padding:'16px !important'}}>
            <div id="alert-dialog-description">
                {!coverLetterUploadedFromActionItem ? <Typography variant="body1" className="mb0">Please select the document type you would like to
                    upload.</Typography> : <Typography>Please upload the cover letter.</Typography>}
            </div>
            <Formik
                    initialValues={initialValues}
                    validate={values => validate(values)}
                    onSubmit={(values, {
                        setSubmitting,
                        setErrors,
                        setStatus,
                        resetForm,
                    }) => submitFormik(values, setSubmitting, resetForm)}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      dirty,
                      setFieldValue,
                      setFieldTouched
                  }) => {
                    return (
                            <form onSubmit={handleSubmit}>
                                <Grid className="mt20" container spacing={2}>
                                    {
                                        fieldObject.map((field, index) => {
                                            return (
                                                    <Grid item xs={12}
                                                          key={index}>
                                                        <TextFieldCommon
                                                                {...field}
                                                                values={values}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                onClose={() => {
                                                                }}
                                                                options={field.options}
                                                        />
                                                        <ErrorCommon errors={errors}
                                                                     name={field.name}
                                                                     touched={touched}/>
                                                    </Grid>
                                            );
                                        })
                                    }

                                        {
                                                (values["documentSubType"] && !(!!docName1)) &&
                                                <Grid item xs={12}>
                                                    <div className="fileUpload mt10 mb10">
                                                        <FileUploader
                                                                setOnProgress={setOnProgress}
                                                                onProgress={onProgress}
                                                                exactFileName={`${(docTypeText1.toUpperCase() + '_' + moment().format("DD-MM-YYYY HH:mm:ss")).replace(/ /g, "_")}`}
                                                                dirName={directory}
                                                                dirType={"documents"}
                                                                fileType={fileType}
                                                                width={15000}
                                                                height={15000}
                                                                content={`Upload ${docTypeText1}`}
                                                                onFinish={async (publicUrl: string, name: string) => {
                                                                    setFieldTouched('documentSubType');
                                                                    setDocName1(name);
                                                                    setActionFn('front');
                                                                    // await updateDocument(name, docType, "front");
                                                                    setOnProgress(false);
                                                                }}

                                                        />
                                                    </div>
                                                </Grid>
                                        }


                                        {
                                                (showTwoUploadForArr.includes(docType) && !(!!docName2)) &&
                                                <Grid item xs={12}>
                                                    <div className="fileUpload mt10 mb10">
                                                        <FileUploader
                                                                setOnProgress={setOnProgress}
                                                                onProgress={onProgress}
                                                                exactFileName={`${(docTypeText2.toUpperCase() + '_' + moment().format("DD-MM-YYYY HH:mm:ss")).replace(/ /g, "_")}`}
                                                                dirName={directory}
                                                                dirType={"documents"}
                                                                fileType={fileType}
                                                                width={15000}
                                                                height={15000}
                                                                content={`Upload ${docTypeText2}`}
                                                                onFinish={async (publicUrl: string, name: string) => {
                                                                    setFieldTouched('documentSubType');
                                                                    setDocName2(name);
                                                                    setActionFn('back');
                                                                    // await updateDocument(name, docType, "back");
                                                                    setOnProgress(false);
                                                                }}

                                                        />
                                                    </div>
                                                </Grid>
                                        }

                                        {
                                                values["documentSubType"] && docName1 &&
                                                <Grid item xs={12} sm={4}>
                                                <ViewAndDelete
                                                        identityPhoto={docName1}
                                                        clientSigned={false}
                                                        title={title}
                                                        action={actionObj1}
                                                        userId={userId}
                                                        directory={directory}
                                                        setIdentityFrontPrevPhoto={setIdentityFrontPrevPhoto}
                                                        setIdentityBackPrevPhoto={setIdentityBackPrevPhoto}
                                                        setUtilityBillPrevPhoto={setUtilityBillPrevPhoto}
                                                        setResidenceFrontPrevPhoto={setResidenceFrontPrevPhoto}
                                                        setResidenceBackPrevPhoto={setResidenceBackPrevPhoto}
                                                        setTaxDocumentYearOne={setTaxDocumentYearOne}
                                                        setTaxDocumentYearTwo={setTaxDocumentYearTwo}
                                                        setOtherDocPrevPhoto={setOtherDocPrevPhoto}
                                                        closeConfDialog={true}
                                                        emptyDocName1={() => {
                                                            setDocName1("");
                                                        }}
                                                        docSubName={docSubName}
                                                        setActionObj1={setActionObj1}
                                                />
                                                </Grid>
                                        }

                                        {
                                                showTwoUploadForArr.includes(docType) && docName2 &&
                                                <Grid item xs={12} sm={4}>
                                                <ViewAndDelete
                                                        identityPhoto={docName2}
                                                        clientSigned={false}
                                                        title={title}
                                                        action={actionObj2}
                                                        userId={userId}
                                                        directory={directory}
                                                        setIdentityFrontPrevPhoto={setIdentityFrontPrevPhoto}
                                                        setIdentityBackPrevPhoto={setIdentityBackPrevPhoto}
                                                        setUtilityBillPrevPhoto={setUtilityBillPrevPhoto}
                                                        setResidenceFrontPrevPhoto={setResidenceFrontPrevPhoto}
                                                        setResidenceBackPrevPhoto={setResidenceBackPrevPhoto}
                                                        setTaxDocumentYearOne={setTaxDocumentYearOne}
                                                        setTaxDocumentYearTwo={setTaxDocumentYearTwo}
                                                        setOtherDocPrevPhoto={setOtherDocPrevPhoto}
                                                        closeConfDialog={true}
                                                        emptyDocName2={() => {
                                                            setDocName2("");
                                                        }}
                                                        docSubName={docSubName}
                                                        setActionObj2={setActionObj2}
                                                />
                                                </Grid>
                                        }
                                    <Confirmation
                                        confirmDialog={openConfirmation}
                                        onCancel={{
                                            onClick: () => {
                                                handleClose()
                                            }
                                        }}
                                        onConfirm={{
                                            onClick: () => {
                                                setOpenConfirmation(false)
                                            }
                                        }}
                                    />

                                </Grid>
                                <FormButton className="mt20 floatRight" label="Save & Close" autoFocus onClick={() => {
                                  
                                        onSaveClose(values)
                                  
                                }} variant="contained" color="primary">
                                </FormButton>
                            </form>)
                    }}
                </Formik>

            </DialogContent>

        </Dialog></>
}

export default UploadSpecificDocument;