import React from "react";
import moment from "moment";
import { makeStyles, Theme, createStyles, MuiThemeProvider } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Grid,
    Typography,
    Card,
    CardContent
} from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import ProgressBar from "./ProgressBar";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { RootState } from "../../../../reducers";
import StepOne from "./Components/StepOne";
import StepTwo from "./Components/StepTwo";
import StepThree from "./Components/StepThree";
import {
    desireLifeTimeBenefits,
    ESTIMATION_WEB_HOOK_URL,
    LOGO_BASE_URL,
    NF_IUL_STRATEGY,
    StrategySlugEnum
} from "../../../../constants";
import EstimationFormikHandler from "./Components/EstimationFormikHandler";
// import EstimationQuickFormikHandler from "./Components/EstimationQuickFormikHandler";
import kaiZenTheme from "../../../../styles/theme/kaiZenTheme";
import TriZenTheme from "../../../../styles/theme/triZenTheme";
import EstimationResult from "./EstimationResult";
import Socket from "./Socket";
import * as Actions from "../../../../actions/estimation";
import { useActions } from "../../../../actions";
import TestingProcessDialog from "../../../../components/client/Estimation/TestingProcessDialog";
import ContributionConfirmationDialog from "../../../../components/client/Estimation/ContributionConfirmationDialog";
import ClientEstimationResult from "../../../../components/client/Estimation/ClientEstimationResult";
import EditLifeTimeDeathBenefitAmount from "../../../../components/client/Estimation/EditLifeTimeDeathBenefitAmount";
import EditCalculationInputsDialog from "../../../../components/client/Estimation/EditCalculationInputsDialog";
import Recalculate from "./Components/DialogBox/Recalculate";
import ViewFilesList from "./Components/DialogBox/ViewFilesList";
import StepperReactPlayer from "./Components/StepperReactPlayer";
import { datePickerFormat } from "../../../../utils/dateUtils";
import { history } from "../../../../configureStore";
import _ from "lodash";

import {ATLANTIC_HEALTH} from "../../../../constants";
import StepFour from "./Components/StepFour";
import { useCreateAllocationPayLoad } from "../Hooks/Hooks";
import { InvestmentValues, isALGCarrier } from "../utils/utils";
import { getCarrierCode, isFunc } from "../../../../utils/commonUtils";

export const webHookURL: string = `${ESTIMATION_WEB_HOOK_URL}`;

export const handleButtonClick = async (props: {
                                            loader: boolean,
                                            user: any,
                                            setIsEstimationDataLoading: Function,
                                            setIsReportRequestAutomated: Function,
                                            setRequestId: Function,
                                            setIsReportRequestCompleted: Function,
                                            estimationActions: any,
                                            setIsReportDataLoading?: Function,
                                            callingFrom: string | undefined,
                                            createAllocationPayLoad?: Function
                                        },
) => {
    const {
        loader = true,
        user,
        setIsReportRequestAutomated,
        setRequestId,
        setIsReportRequestCompleted,
        estimationActions,
        setIsReportDataLoading,
        callingFrom,
        createAllocationPayLoad
    } = props;
    //let data: any = localStorage.getItem("success") ? JSON.parse(localStorage.getItem("success") || "") : "";
    const uuid = uuidv4();
    let userId: string | undefined | null = user && user.id;
    //Client Re-Brand Case
    let reBrand = user.client && user.client.imo && user.client.imo.reBrand;
    //Demo Re-Brand Case
    if (user.demoStrategy && user.demoStrategy.reBrand) {
        reBrand = user.demoStrategy.reBrand;
    }
    const role = user && user.role;
    const companyLinkUrl = user && user.client && user.client.company && user.client.company.companyLinkUrl;

    const isNfiUl = user.client && user.client.strategy && (user.client.strategy.slug === StrategySlugEnum.NfIUL && user.client.strategy.id === NF_IUL_STRATEGY);


    if (userId) {
        let webHookUrl: string = `${webHookURL}/${userId}?callingFrom=${callingFrom}`;
        let estimationDetails: any = await estimationActions.getEstimationDetailsByUserId(userId, callingFrom);
        if (estimationDetails && estimationDetails.id) {
            let responsePayload: any = JSON.parse(estimationDetails && estimationDetails.stressResponse);
            if (responsePayload && responsePayload.hasOwnProperty("success")) {

                let {
                    common,
                    comparison,
                    illustration,
                    stress,
                    reports,
                    run,
                } = responsePayload && responsePayload.success;
                if (reports === false) {  //isReportRequestCompleted
                    if (loader) {
                        setIsReportRequestAutomated(false);
                    } else {
                        setIsReportRequestAutomated(true);
                    }
                    setRequestId(uuid);

                    let existingRunPayload: any = {};
                    
                    //Setting up a template key on the basis of carrier.
                     let templateS3Key = run["deathbenefitonly"] ? "NLG Annual Individual DB Only.pdf" : "NLG Annual Individual Income.pdf";
                    if (reBrand) {
                        templateS3Key = run["deathbenefitonly"] ? "AON individual DB Only.pdf" : "AON individual income.pdf";
                    }
                    if (common['carrier'] === "ALZ") {
                        //Monthly Case Fix
                        if (isNfiUl) {
                            templateS3Key = "ALZ NFIUL.pdf";
                        }
                        else {
                            if (common["paymentfrequency"] === "Monthly") {
                                templateS3Key = run["deathbenefitonly"] ? "ALZ Monthly Individual DB only.pdf" : "ALZ Monthly Individual Income.pdf";
                                if (!!common['contributionamountemployer']) {
                                    templateS3Key = "ALZ Monthly Employer Income.pdf";
                                }
                            } else {
                                templateS3Key = run["deathbenefitonly"] ? "ALZ Annual Individual DB Only.pdf" : "ALZ Annual Individual Income.pdf";
                                if (!!common['contributionamountemployer']) {
                                    templateS3Key = "ALZ Annual Employer Income.pdf";
                                }
                            }
                        }
                    } else if(common['carrier'] === "NLG" && !!common['contributionamountemployer']) {
                        templateS3Key = "NLG Annual Employer Income.pdf";
                    }

                    //Special Case ATLANTIC_HEALTH
                    if (ATLANTIC_HEALTH.includes(companyLinkUrl) && role === 6) {
                        if (common["paymentfrequency"] === "Monthly") {
                            templateS3Key = run["deathbenefitonly"] ? "Atlantic Health Monthly DB Only.pdf" : "Atlantic Health Monthly Income.pdf";
                        } else {
                            templateS3Key = run["deathbenefitonly"] ? "Atlantic Health Annual DB Only.pdf" : "Atlantic Health Annual Income.pdf";

                        }
                    }

                    existingRunPayload["id"] = uuid;
                    existingRunPayload["webhook"] = webHookUrl;
                    existingRunPayload["run"] = {
                        "stress": false,
                        "illustration": false,
                        "comparison": false,
                        "reports": true,
                        "deathbenefitonly": run["deathbenefitonly"],
                    };
                    existingRunPayload["common"] = common;
                    existingRunPayload["reports"] = {
                        "existingrun": {
                            "reports": false,
                            "stress": stress,
                            "illustration": illustration,
                            "comparison": comparison,
                        },
                        "details": {
                            "Kaizen": {
                                "templateS3Key": templateS3Key,
                            },
                            "Final_ClientFacing": {
                                "acceleratedbenefitsridersummary": true,
                                "printpuzzlepieceimageswithabrsummary": true,
                                "benefitsummaryanalysis": false,
                                "diversecreditingoptions": false,
                                "illustratedfuturepolicyactions": false,
                                "inputsummary": false,
                                "internalrateofreturn": false,
                                "monthlylowpointvalues": false,
                                "myplannedlifeinsuranceoffers": true,
                                "myplannedlifeinsuranceoffersdisplayorder": 1,
                                "policychargesandexpenses": false,
                                "salespresentationpages": false,
                                "statutorypremiuminformation": false
                            },
                            "Final_Internal": {
                                "acceleratedbenefitsridersummary": true,
                                "printpuzzlepieceimageswithabrsummary": true,
                                "benefitsummaryanalysis": false,
                                "diversecreditingoptions": false,
                                "illustratedfuturepolicyactions": false,
                                "inputsummary": true,
                                "internalrateofreturn": false,
                                "monthlylowpointvalues": false,
                                "myplannedlifeinsuranceoffers": true,
                                "myplannedlifeinsuranceoffersdisplayorder": 1,
                                "policychargesandexpenses": false,
                                "salespresentationpages": false,
                                "statutorypremiuminformation": false
                            },
                            "LowPoint": {
                                "acceleratedbenefitsridersummary": true,
                                "printpuzzlepieceimageswithabrsummary": true,
                                "benefitsummaryanalysis": false,
                                "diversecreditingoptions": false,
                                "illustratedfuturepolicyactions": false,
                                "inputsummary": true,
                                "internalrateofreturn": false,
                                "monthlylowpointvalues": false,
                                "myplannedlifeinsuranceoffers": true,
                                "myplannedlifeinsuranceoffersdisplayorder": 1,
                                "policychargesandexpenses": false,
                                "salespresentationpages": false,
                                "statutorypremiuminformation": false
                            }
                        },
                    };
                    await estimationActions.getEstimatesFromCloud(existingRunPayload, user.role, {}, userId, callingFrom);
                } else if (reports && Object.keys(reports).length > 0) {
                    //setIsDataLoading(false);
                    if (typeof setIsReportDataLoading === "function") {
                        setIsReportDataLoading(false);
                    }
                    setIsReportRequestCompleted(true);
                }

            }
        }
        return false;
    }
    return false;
};

export const getMinimumDesireLifeTimeBenefits = (values: any ,ratecls?:string) => {
    let healthType: string = values && values.healthType || "Standard";
    let tobaccoCons: string = values && values.tobacco === "used" ? "Tobacco" : "NT";
    let rateClass:string;
    if(ratecls){
         rateClass = ratecls
    }else{
         rateClass = `${healthType}_${tobaccoCons}`;
    }
    let contributionObj:any = _.find(desireLifeTimeBenefits,
            {
                'issueAge': values && values.issueAge,
                'healthType': rateClass,
                'gender': (values && values.gender === 1) ? "Male":"Female"
            }
    );
    if(contributionObj && Object.keys(contributionObj).length > 0){
        return contributionObj.minContribution;
    }
    return 500000;
}

export function numberToThousandSeparator(x: string | number) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                width: "100%",
            },
            button: {
                marginRight: theme.spacing(1),
            },
            instructions: {
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(1),
            },
            backdrop: {
                zIndex: theme.zIndex.drawer + 1,
                color: "#000",
            },
        }),
);

function getSteps(carrierId) {
    const carrierCode = getCarrierCode(carrierId as number);
        switch (carrierCode) {
            case "ALZ":
                return ["Step 1 of 4", "Step 2 of 4", "Step 3 of 4", "Step 4 of 4"];
            default:
                return ["Step 1 of 4", "Step 2 of 4", "Step 3 of 4", "Step 4 of 4"];
        }
}

function getStepContent(step: number, callingFrom: string, carrierId?: number) {
    if(isALGCarrier(carrierId)){
        switch (step) {
            case 0:
                return <StepOne callingFrom={callingFrom} carrierId={carrierId}/>;
            case 1:
                return <StepTwo callingFrom={callingFrom}/>;
            case 2:
                return <StepThree callingFrom={callingFrom}/>;
            case 3:
                return <StepFour callingFrom={callingFrom}/>;
            default:
                return <></>;
        }
    }else{
        switch (step) {
            case 0:
                return <StepOne callingFrom={callingFrom} carrierId={carrierId}/>;
            case 1:
                return <StepTwo callingFrom={callingFrom}/>;
            case 2:
                return <StepThree callingFrom={callingFrom}/>;
            case 3:
                return <StepFour callingFrom={callingFrom}/>;
            default:
                return <></>;
        }
    }

}

export const getClientEstimationData = async (props: {
    estimationActions: any, userId: string | number | undefined, setResponseData: Function, setLifeDeathBenefitSeeking: Function, handleResult: Function,
    handleReset: Function, callingFrom: string | undefined, setShowResults?: Function
}) => {
    const {
        estimationActions,
        userId,
        setResponseData,
        setLifeDeathBenefitSeeking,
        handleReset,
        handleResult,
        callingFrom,
    } = props;
    let estimationDetails: any = await estimationActions.getEstimationDetailsByUserId(userId, callingFrom);
    if (estimationDetails && estimationDetails.id) {
        if (!!(estimationDetails && estimationDetails.stressResponse)) {
            let responsePayload: any = JSON.parse(estimationDetails && estimationDetails.stressResponse);
            if (responsePayload && responsePayload.success) {
                setResponseData(responsePayload && responsePayload.success);
                setLifeDeathBenefitSeeking(responsePayload.success && responsePayload.success.run && responsePayload.success.run.deathbenefitonly);
                handleResult();
            } else {
                setResponseData([]);
                handleReset();
            }
        } else {
            setResponseData([]);
            handleReset();
        }
    } else {
        setResponseData([]);
        handleReset();
    }
};

export default function Steppers(props: { callingFrom: string | undefined, getStepperCurrentState?: Function | undefined , setIllustrationId?:Function,editCalculationInputs?:boolean,setEditCalculationInputs?:Function }) {
    const { callingFrom = "ESTIMATOR", getStepperCurrentState , setIllustrationId,  editCalculationInputs,
        setEditCalculationInputs } = props;
    console.log("Calling From:", callingFrom);
    const classes = useStyles();
    const estimationActions = useActions(Actions);
    const { user: { client, accessType }, user } = useSelector((state: RootState) => state.auth);
    const carrierId = user.accessType === "client" ? user.demoCarrierId : (user.client && user.client.carrier ? user.client.carrier.id : 2);
    const profileInfo = useSelector((state: RootState) => state.profile);
    let videoName = "kai-zen-strategies-estimate-video.mp4";
    let strategySlug: string = "kaizen";
    let currentTheme = kaiZenTheme;
    let _user: string = `${user && user.firstName}`;
    const steps = getSteps(carrierId);
    if (client) {
        strategySlug = (client.strategy && client.strategy.slug) || strategySlug;
    } else if (accessType === "client" && (user.role === 1 || user.role === 2 || user.role === 3 || user.role === 4 || user.role === 15)) {
        strategySlug = (user.demoStrategy && user.demoStrategy.slug) || strategySlug;
    }
    if (strategySlug === "trizen" || strategySlug === "esd") {
        videoName = "tri-zen-strategies-estimate-video.mp4";
        currentTheme = TriZenTheme;
    }
    //const successData = !twentyFourHrsGone(lastEstimatedTime, currentDateTime) && localStorage.getItem("success") ? JSON.parse(localStorage.getItem("success") || "") : "";
    const [activeStep, setActiveStep] = React.useState(0);
    const [progressBar, setProgressBar] = React.useState(15);
    const [showResults, setShowResults] = React.useState<boolean | undefined>(undefined);
    const [responseData, setResponseData] = React.useState<any>([]);
    const [lifeTimeDeathBenefitSeeking, setLifeDeathBenefitSeeking] = React.useState(false);
    const [isReportRequestCompleted, setIsReportRequestCompleted] = React.useState<boolean>(false);

    const [isReportRequestAutomated, setIsReportRequestAutomated] = React.useState<boolean>(false);
    const [requestId, setRequestId] = React.useState<null | string>(null);

    const [isEstimationDataLoading, setIsEstimationDataLoading] = React.useState<boolean>(false);
    const [isReportDataLoading, setIsReportDataLoading] = React.useState<boolean>(false);
    const [estimatorData, setEstimatorData] = React.useState<any>(null);
    const createAllocationPayLoad = useCreateAllocationPayLoad(estimatorData && estimatorData.id)
    const [editLifeTimeDeathBenefitAmount, setEditLifeTimeDeathBenefitAmount] = React.useState<boolean>(false);
    const [contributionConfirmation, setContributionConfirmation] = React.useState<boolean>(false);
    // const [editCalculationInputs, setEditCalculationInputs] = React.useState<boolean>(false);
    const [recalculate, setRecalculate] = React.useState<boolean>(false);
    const [isCalculatedByProfileData, setIsCalculatedByProfileData] = React.useState<boolean>(false);
    const [renderQuickForm, setRenderQuickForm] = React.useState<boolean>(false);
    // const [issueAge, setIssueAge] = React.useState<number>(0);
    const btnVideoUrl: string = `${LOGO_BASE_URL}estimation/${videoName}`;
    let totalSteps: number = steps.length;

    React.useEffect(() => {
        getClientEstimationData({
            estimationActions,
            userId: user && user.id,
            setResponseData,
            setLifeDeathBenefitSeeking,
            handleResult,
            handleReset,
            callingFrom,
            setShowResults,
        }).then(() => {

        });

        if (responseData && responseData !== "") {
            if (localStorage.getItem("lifeDeathBenefitSeeking")) {
                setLifeDeathBenefitSeeking(Number(localStorage.getItem("lifeDeathBenefitSeeking")) === 1);
            }
        }
        return () => {
            setActiveStep(0);
        };
    }, []);

    React.useEffect(() => {
        setProgressBar(2);
        if (activeStep <= totalSteps) {
            if (activeStep === 0) {
                setProgressBar(2);
            } else if (activeStep === 1) {
                setProgressBar(33);
            } else if (activeStep === 2) {
                setProgressBar(66);
            } else if (activeStep === 3) {
                setProgressBar(100);
            }
        }
    }, [activeStep]);

    React.useEffect(() => {
        if (typeof getStepperCurrentState === "function") {
            getStepperCurrentState({
                activeStep,
                setIsReportDataLoading,
                isReportRequestCompleted,
                responseData,
                isReportDataLoading,
                setIsReportRequestCompleted,
                showResults,
            });
        }
    }, [activeStep, isReportRequestCompleted, responseData, setIsReportDataLoading, isReportDataLoading, setIsReportRequestCompleted, showResults]);

    React.useEffect(() => {
        const dobInProfile: string | undefined | null = (profileInfo && profileInfo.client && profileInfo.client.dob) || (user && user.client && user.client.dob) || null;
        const genderInProfile: number | undefined | null = (profileInfo && profileInfo.client && profileInfo.client.gender) || (user && user.client && user.client.gender) || null;

        if (callingFrom === "ENROLLMENT") {
            if (responseData && responseData.hasOwnProperty("common")) {
                const { common } = responseData;
                let resDOB = datePickerFormat(common && common.dateofbirth);
                let resGender: number = ((common && common.gender) === "Male") ? 1 : 2;
                let isDobSame: boolean = moment(dobInProfile).isSame(resDOB);
                if (!isDobSame || (genderInProfile !== resGender)) {
                    clearEstimationData().then(() => {
                        console.log("Either dob or gender not match with the profile data");
                    });
                }
            }
        }
    }, [responseData]);

    /*React.useEffect(() => {
        if (callingFrom === "ESTIMATOR" || callingFrom === "ENROLLMENT") {
            setRenderQuickForm(true);
        }
    }, [callingFrom]);*/

    const handleNext = () => {
        setActiveStep((prevActiveStep) => {
            return prevActiveStep + 1;
        });
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => {
            return prevActiveStep - 1;
        });
    };

    const handleResult = () => {
        setShowResults(true);
    };

    const handleReset = () => {
        localStorage.removeItem("success");
        localStorage.removeItem("lastEstimatedTime");
        localStorage.removeItem("lifeDeathBenefitSeeking");
        setActiveStep(0);
        setShowResults(false);
        setLifeDeathBenefitSeeking(false);
        setResponseData([]);
    };

    const clearEstimationData = async () => {
        await estimationActions.clearEstimation(callingFrom);
        handleReset();
    };

    const renderResults = (callingFrom: string, isRenderUnderExpansionPanel: boolean) => {
        return (
                <EstimationResult
                        clearEstimationData={clearEstimationData}
                        responseData={responseData}
                        lifeTimeDeathBenefitSeeking={lifeTimeDeathBenefitSeeking}
                        isReportRequestCompleted={isReportRequestCompleted}
                        setIsReportRequestCompleted={setIsReportRequestCompleted}
                        handleButtonClick={(loader: any) => handleButtonClick({
                            loader,
                            user,
                            setIsEstimationDataLoading,
                            setIsReportRequestAutomated,
                            setRequestId,
                            setIsReportRequestCompleted,
                            estimationActions,
                            setIsReportDataLoading,
                            callingFrom,
                        })}

                        isReportDataLoading={isReportDataLoading}
                        setEditLifeTimeDeathBenefitAmount={setEditLifeTimeDeathBenefitAmount}
                        setContributionConfirmation={setContributionConfirmation}
                        setEditCalculationInputs={setEditCalculationInputs as Function}
                        isItUnderExpansionPanel={isRenderUnderExpansionPanel}
                        calculateAgain={calculateAgain}
                        callingFrom={callingFrom}
                />
        );
    };

    const renderEstimationFormikHandler = (callingFrom: string, carrierId?: number) => {
        const estimationFormikHandler =
                <EstimationFormikHandler
                        activeStep={activeStep}
                        handleBack={handleBack}
                        handleNext={handleNext}
                        handleResult={handleResult}
                        totalSteps={totalSteps}
                        handleReset={handleReset}
                        setRequestId={setRequestId}
                        setIsEstimationDataLoading={setIsEstimationDataLoading}
                        getEstimatorData={(data: any) => {
                            setEstimatorData(data);
                        }}
                        callingFrom={callingFrom}
                        isCalculatedByProfileData={isCalculatedByProfileData}
                        clearEstimationData={clearEstimationData}
                >
                    {getStepContent(activeStep, callingFrom, carrierId)}
                </EstimationFormikHandler>;

        switch (callingFrom) {
            case "ENROLLMENT":
                return (
                        <Grid container spacing={2} justify="center">
                            <Grid item xs={12}>
                                <Card>
                                    <CardContent>
                                        <Typography component={"div"} variant="body1">Please complete this form so we
                                            can calculate your contribution totals and benefits.</Typography>
                                        <ProgressBar progressBar={progressBar}/>
                                        <Typography className="mt5 floatLeft w100"
                                                    variant="caption">{steps[activeStep]}</Typography>
                                        {estimationFormikHandler}
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                );
            case "ESTIMATOR":
                return (
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={7}>
                                <Typography variant="h3" className={"mt40 textBlack"}>Customize My Design</Typography>
                                <Typography component={"div"} variant="body1">Hello {_user}, the Custom Design tool allows you to tailor a proposal to your needs. ILIA will communicate with the insurance carrier in real time to generate an accurate illustration. You will have the ability to edit your details so you can weigh your options. When you have a design that meets your needs, you can start enrollment from the results page.</Typography>
                                <ProgressBar progressBar={progressBar}/>
                                <Typography className="mt5 floatLeft w100" variant="caption">{steps[activeStep]}</Typography>
                                {estimationFormikHandler}
                            </Grid>

                            <Grid item xs={12} md={5}>
                                <StepperReactPlayer btnVideoUrl={btnVideoUrl}/>
                            </Grid>
                        </Grid>
                );
            case "CUSTOM_DESIGN":
                return (
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={7}>
                                <Typography variant="h3" className="mt40 textBlack mt80small" align="left">Custom
                                    Design</Typography>
                                <ProgressBar progressBar={progressBar}/>
                                <Typography className="mt5 floatLeft w100"
                                            variant="caption">{steps[activeStep]}</Typography>
                                {estimationFormikHandler}
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <StepperReactPlayer btnVideoUrl={btnVideoUrl}/>
                            </Grid>
                        </Grid>
                );
            default:
                return (
                        <Grid container spacing={2} justify="center">
                            <Grid item xs={12}>
                                {estimationFormikHandler}
                            </Grid>
                        </Grid>
                );
        }
    };

    // const renderQuickEstimationFormikHandler = (callingFrom: string) => {
    //     const estimationQuickFormikHandler =
    //             <EstimationQuickFormikHandler
    //                     activeStep={activeStep}
    //                     handleBack={handleBack}
    //                     handleNext={handleNext}
    //                     handleResult={handleResult}
    //                     totalSteps={totalSteps}
    //                     handleReset={handleReset}
    //                     setRequestId={setRequestId}
    //                     setIsEstimationDataLoading={setIsEstimationDataLoading}
    //                     getEstimatorData={(data: any) => {
    //                         setEstimatorData(data);
    //                     }}
    //                     callingFrom={callingFrom}
    //                     isCalculatedByProfileData={isCalculatedByProfileData}
    //                     clearEstimationData={clearEstimationData}
    //                     setIssueAge={setIssueAge}
    //                     issueAge={issueAge}
    //             >
    //                 {getStepContent(activeStep, callingFrom)}
    //             </EstimationQuickFormikHandler>;
    //
    //     switch (callingFrom) {
    //         case "ESTIMATOR":
    //             return (
    //                     <Grid container spacing={2} justify="center">
    //                         <Grid item xs={12} md={7}>
    //                             <Typography variant="h3" className="mt20 mb20">Customize My Design</Typography>
    //                             <Typography component={"div"} variant="body1">Hello {_user}, the Custom Design tool allows you to tailor a proposal to your needs. ILIA will communicate with the insurance carrier in real time to generate an accurate illustration. You will have the ability to edit your details so you can weigh your options. When you have a design that meets your needs, you can start enrollment from the results page.</Typography>
    //                             {estimationQuickFormikHandler}
    //                         </Grid>
    //                         <Grid item xs={12} md={5}>
    //                             <StepperReactPlayer btnVideoUrl={btnVideoUrl}/>
    //                         </Grid>
    //                     </Grid>
    //             );
    //         case "ENROLLMENT":
    //             return (
    //                     <Grid container spacing={2} justify="center">
    //                         <Grid item xs={12}>
    //                             <Card>
    //                                 <CardContent>
    //                                     <Typography component={"div"} variant="body1">Please complete this form so we
    //                                         can calculate your contribution totals and benefits.</Typography>
    //                                     {estimationQuickFormikHandler}
    //                                 </CardContent>
    //                             </Card>
    //                         </Grid>
    //                     </Grid>
    //             );
    //         default:
    //             return (
    //                     <Grid container spacing={2} justify="center">
    //                         <Grid item xs={12}>
    //                             {estimationQuickFormikHandler}
    //                         </Grid>
    //                     </Grid>
    //             );
    //     }
    // };

    const calculateAgain = () => {
        if (user && (user.role === 5 || user.role === 6) && callingFrom === "ESTIMATOR") {
            const { common } = responseData;
            let resDOB = datePickerFormat(common && common.dateofbirth);
            let dobClient = user && user.client && user.client.dob;

            let resGender = common && common.gender;
            let genderClient = user && user.client && user.client.gender === 1 ? "Male" : "Female";

            let dob: boolean = moment(resDOB).isSame(dobClient);

            if (dob && (resGender === genderClient)) {
                setContributionConfirmation(true);
            } else {
                setRecalculate(true);
            }
        }
    };

    const estimationConfirmation = async () => {
        let res: boolean = await estimationActions.confirmEstimation(callingFrom);
        if (res) {
            getClientEstimationData({
                estimationActions,
                userId: user && user.id,
                setResponseData,
                setLifeDeathBenefitSeeking,
                handleResult,
                handleReset,
                callingFrom,
                setShowResults,
            });
            setTimeout(() => {
                history.push("/new-enrollment");
            }, 1000);
        }
    };

    return (
            <MuiThemeProvider theme={currentTheme}>
                <Socket
                        onCompleteReportDataLoading={(estimationResults:any)=>{
                            const responseData: any = estimationResults;
                            setIllustrationId && setIllustrationId(responseData && responseData.reports && responseData.reports.client && responseData.reports.client.illustrationId)
                        }}
                        handleResult={handleResult}
                        setResponseData={setResponseData}
                        handleReset={handleReset}
                        userId={user && user.id}
                        page={"estimator"}
                        setIsReportRequestCompleted={setIsReportRequestCompleted}
                        setLifeDeathBenefitSeeking={setLifeDeathBenefitSeeking}
                        isReportRequestAutomated={isReportRequestAutomated}
                        handleButtonClick={(loader: any) => handleButtonClick({
                            loader, user, setIsEstimationDataLoading, setIsReportRequestAutomated, setRequestId,
                            setIsReportRequestCompleted, estimationActions, callingFrom, setIsReportDataLoading, createAllocationPayLoad
                        })}
                        requestId={requestId}
                        setIsEstimationDataLoading={setIsEstimationDataLoading}
                        setIsReportDataLoading={setIsReportDataLoading}
                        callingFrom={callingFrom}
                />
                <Grid item xs={12} sm={12}>
                    <div style={{ maxWidth: "1700px" }}>
                        {
                                typeof showResults === "boolean" && !showResults && !renderQuickForm && renderEstimationFormikHandler(callingFrom, carrierId)
                        }
                        {/*{*/}
                        {/*        typeof showResults === "boolean" && !showResults && renderQuickForm && renderQuickEstimationFormikHandler(callingFrom)*/}
                        {/*}*/}

                        {
                                isEstimationDataLoading &&
                                <Backdrop className={classes.backdrop} open={isEstimationDataLoading}>
                                    <TestingProcessDialog
                                            open={isEstimationDataLoading}
                                            isEstimationDataLoading={isEstimationDataLoading}
                                            handleClose={() => {
                                                setIsEstimationDataLoading(false);
                                            }
                                            }
                                            getEstimationProgress={estimationActions.getEstimationProgress}
                                    />
                                </Backdrop>
                        }

                        {
                                ((callingFrom === "ESTIMATOR" || callingFrom === "CUSTOM_DESIGN") && typeof showResults === "boolean" && showResults) &&
                                renderResults(callingFrom, false)
                        }

                        {
                                (callingFrom === "ENROLLMENT" && typeof showResults === "boolean" && showResults) &&
                                <React.Fragment>
                                    <Card className="boxShadow pt20 pb20">
                                        <CardContent>
                                            <ClientEstimationResult
                                                    setEditLifeTimeDeathBenefitAmount={setEditLifeTimeDeathBenefitAmount}
                                                    setEditCalculationInputs={setEditCalculationInputs}
                                                    responseData={responseData}
                                                    setIsReportRequestCompleted={setIsReportRequestCompleted}
                                                    setOpenEstimationDialog={clearEstimationData}
                                                    isEditable={true}
                                                    callingFrom={callingFrom}
                                            />
                                        </CardContent>
                                    </Card>

                                    <div className="questionsBox contributionBox mt30">
                                        <ExpansionPanel>
                                            <ExpansionPanelSummary
                                                    expandIcon={<ExpandMoreIcon/>}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                            >
                                                <Typography variant="body1" className="mb0 w100">
                                                    Full Estimated Benefits and Distributions
                                                </Typography>
                                            </ExpansionPanelSummary>
                                            <ExpansionPanelDetails style={{ display: "unset" }}>
                                                {renderResults(callingFrom, true)}
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                    </div>
                                </React.Fragment>
                        }

                        {
                                editLifeTimeDeathBenefitAmount &&
                                <EditLifeTimeDeathBenefitAmount open={editLifeTimeDeathBenefitAmount}
                                                                handleClose={() => {
                                                                    setEditLifeTimeDeathBenefitAmount(false);
                                                                }}
                                                                setIsEstimationDataLoading={setIsEstimationDataLoading}
                                                                setRequestId={setRequestId}
                                                                estimatorData={estimatorData}
                                                                isEstimationDataLoading={isEstimationDataLoading}
                                                                setTestingProgress={setIsEstimationDataLoading}
                                                                setEditLifeTimeDeathBenefitAmount={setEditLifeTimeDeathBenefitAmount}
                                                                userId={user && user.id} // here userId is client id
                                                                userObj={user}
                                                                callingFrom={callingFrom}
                                                                responseData={responseData}
                                />
                        }

                        {
                                editCalculationInputs &&
                                <EditCalculationInputsDialog open={editCalculationInputs}
                                                             handleClose={() => {
                                                                 setEditCalculationInputs && isFunc(setEditCalculationInputs) && setEditCalculationInputs(false);
                                                             }}
                                                             setIsEstimationDataLoading={setIsEstimationDataLoading}
                                                             setRequestId={setRequestId}
                                                             estimatorData={estimatorData}
                                                             isEstimationDataLoading={isEstimationDataLoading}
                                                             setTestingProgress={setIsEstimationDataLoading}
                                                             userId={user && user.id} // here userId is client id
                                                             userObj={user}
                                                             callingFrom={callingFrom}
                                                             responseData={responseData}
                                                             carrierId={carrierId}
                                />
                        }

                        {
                                isReportRequestCompleted &&
                                <ViewFilesList
                                        isReportRequestCompleted={isReportRequestCompleted}
                                        setIsReportRequestCompleted={setIsReportRequestCompleted}
                                        userId={user && user.id} // here userId is client id
                                        callingFrom={callingFrom}
                                />
                        }

                        {
                                contributionConfirmation &&
                                <ContributionConfirmationDialog
                                        open={contributionConfirmation}
                                        handleClose={() => {
                                            setContributionConfirmation(false);
                                        }}
                                        responseData={responseData}
                                        estimationConfirmation={estimationConfirmation}
                                />
                        }
                        {
                                recalculate &&
                                <Recalculate
                                        recalculate={recalculate}
                                        setRecalculate={setRecalculate}
                                        handleReset={handleReset}
                                        setIsCalculatedByProfileData={setIsCalculatedByProfileData}
                                />
                        }
                    </div>
                </Grid>
            </MuiThemeProvider>
    );
}
