import * as React from "react";
import {Button, List, ListItem, ListItemIcon, ListItemText,  Link,} from "@material-ui/core";
import {useSelector} from "react-redux";
import {RootState} from "../../reducers";
import ListAltIcon from '@material-ui/icons/ListAlt';
import * as authActions from "../../actions/auth";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import {useActions} from "../../actions";
import {history} from "../../configureStore";
import { isServicingAccessible } from "../../constants";
import ClientServiceDashbaord from "../clientServicing/ClientServiceDashbaord";

const ParentSidebar: React.FC<any> = (props: any) => {
    
    const { 
        user: { role, accessType, defaultStatergyLogo },
        user
    } = useSelector((state: RootState) => state.auth);
    const isServiceDashboardAccessible = user && user.inviteStatus && (isServicingAccessible(user) || false);
    const { client } = useSelector((state: RootState) => state.profile);
	const {toggleParentPortal} = useActions(authActions);
    return (<>

    <List className="listTwo">
                <React.Fragment>
                <ListItem className="menuHead" button>
                    <Link className="backBtn floatLeft w100 displayF" style={{fontSize:"20px"}} color="primary" onClick={() => {
                        toggleParentPortal(false);
                       isServiceDashboardAccessible || user.accessType === "client" ? history.push("/client/dashboard") : history.push("/dashboard")
                    }}>
                        <ArrowBackIosIcon className="marginRight5"/>
                        Back
                    </Link>
                </ListItem>
                <ListItem className={`menuHead active`} button  disabled={user.accessType === 'client'}>
                    <React.Fragment>
                        <ListAltIcon/>
                        <ListItemText
                            className={` ${user.accessType === "client" ? 'disabled' : ''} `}
                            onClick={() => {
                             
                            }}
                            primary={
                                <>
                                    <span style={{float:'left',marginTop:'6px'}}>Parent Portal </span>
                                </>
                            }/>
                    </React.Fragment>
                </ListItem>
				</React.Fragment>
			</List>
    </>)                              
};

export default ParentSidebar;