import React from "react";
import clsx from "clsx";
import {
    AppBar,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    IconButton,
    Link,
    Menu,
    MenuItem,
    Toolbar,
    Typography,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import Badge from "@material-ui/core/Badge";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import configureStore, { history } from "../configureStore";
import { IliaAvatar } from "./LeftPanel";
import { useStyles } from "./css/LeftPanelCss";
import { AccountType, AccountTypeByName, isServicingAccessible, LOGO_BASE_URL } from "../constants";
import IliaNotificationPopUp from "./IliaNotificationPopUp";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { dateUtils } from "../utils";
import { Button } from "./formInputs";
import { isBigScreen, isTouchScreen } from "../utils/commonUtils";
import { Account } from "../model/account";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../reducers";
import { useActions } from "../actions";
import * as NotificationActions from "../actions/notification";
import * as Actions from "../actions/auth";
import { ActionType } from "../model";

const { persistor } = configureStore();

const MainHeader = (props) => {
    const classes = useStyles();
    const { setShowDemoSelectionPopup, setProgress } = props;
    const { user, parentPortal } = useSelector((state: RootState) => state.auth);
    const isServiceDashboardAccessible = user && user.inviteStatus && (isServicingAccessible(user) || false);
    const profileImage = useSelector((state: RootState) => state.profileImage);
    const profilePageArr: any = [3, 4, 5, 6, AccountType.Carrier, 12, 13, 18, 19];
    const demoLinks: any = [1, 2, 3, 4, 15, AccountType.Aggregator, AccountType.AggregatorMember];
    let isShowNotifyIcon: boolean = true;
    const dispatch = useDispatch();
    const leftPanelOpen = useSelector((state: RootState) => state.leftPanelOpen);
    const authActions = useActions(Actions);
    const notificationActions = useActions(NotificationActions);
    const [estimationPage, setEstimationPage] = React.useState<boolean>(history.location.pathname.search("/client/estimation-new") > -1);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [notificationAnchorEl, setNotificationAnchorEl] = React.useState<null | HTMLElement>(null);
    const [notificationsData, setNotificationsData] = React.useState<Object[]>([]);
    const [notificationsCount, setNotificationsCount] = React.useState<Number>(0);
    const [topRightProfileImage, setTopRightProfileImage] = React.useState("");
    const [deleteModal, setDeleteModal] = React.useState<boolean>(false);
    const [checkClick, setCheckClick] = React.useState(0);
    const [notificationBox, setNotificationBox] = React.useState<boolean>(false);
    const [individualNotification, setIndividualNotification] = React.useState<any>({});
    const [clientDemo, setClinetDemo] = React.useState(!!((user && demoLinks.find((p: number) => p === user.role)) && user.accessType === "client"));

    const openMenu = Boolean(anchorEl);
    const openNotificationTray = Boolean(notificationAnchorEl);

    const getNotificationCount = (notificationsData: any) => {
        let unreadtNotification = notificationsData.filter((notification: any) => {
            return notification.status === 0;
        });
        setNotificationsCount(unreadtNotification.length);
    };
    const handleDrawerOpen = () => {
        dispatch({ type: ActionType.LEFTPANEL, payload: !leftPanelOpen })
        if (document.body.style && (document.body.style as any).WebkitOverflowScrolling) {
            (document.body.style as any).WebkitOverflowScrolling = "touch";

        }
        document.body.style.overflow = !leftPanelOpen && isTouchScreen() ? "hidden" : null;
    };

    function handleClose() {
        setAnchorEl(null);
    }

    function handleMenu(event: React.MouseEvent<HTMLElement>) {
        setAnchorEl(event.currentTarget);
    }

    const handleNotification = async (event: React.MouseEvent<HTMLElement>) => {
        setNotificationAnchorEl(event.currentTarget);
        // Call List notification Action
        let response = await notificationActions.getNotification();
        if (response.success) {
            getNotificationCount([]);
            setNotificationsData(response.data);
            updateReadNotificationStatus(response.data);
        }
    };
    const updateReadNotificationStatus = (notifications: any) => {
        let notificationsIds = notifications.map((notification: any) => {
            return notification.id;
        });
        notificationActions.updateNotificationStatus(notificationsIds);
    };
    const handleNotificationTrayClose = () => {
        setNotificationAnchorEl(null);
    };
    const onLogout = () => {
        setProgress(true);
        setAnchorEl(null);
        authActions.LogOut();
        history.replace("/accounts");
        setTimeout(() => setProgress(false), 1000);
    };
    const demoClient = async () => {
        setShowDemoSelectionPopup(true);
    };

    const exitDemoClient = async () => {
        await authActions.exitDemoClient();
        setShowDemoSelectionPopup(false);
        setClinetDemo(false);
        persistor.flush().then(() => {
            window.location.replace("/accounts");
        }).catch(() => {
            window.location.replace("/intro");
        });
    };
    const renderNotificationTray = (openNotificationTray: any) => {
        return (
            <>
                <IliaNotificationPopUp notificationBox={notificationBox} setNotificationBox={setNotificationBox}
                                           individualNotification={individualNotification}/>
                <Menu
                    id="notificationTray-appbar"
                    anchorEl={notificationAnchorEl}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    open={openNotificationTray}
                    onClose={handleNotificationTrayClose}
                    classes={{ paper: "leftPanelMenu" }}
                >
                    <Typography variant="h4" className="f16 ml15 mb15 mt10 textBlue">What’s
                        New {notificationsData.length > 0 && <Link
                            onClick={() => setDeleteModal(true)} className="floatRight mr10">Clear All</Link>}
                    </Typography>
                    {notificationsData && notificationsData.length >= 0 && (
                        notificationsData.map((notification: any, index: string | number | undefined) => {
                            return (
                                <MenuItem key={index} className="border1PxBottom">
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <div className="container-fluid pages_container">
                                                <DeleteOutlineIcon
                                                    className="floatRight notificationDeleteIcon colorRed"
                                                    onClick={() => {
                                                        //console.log("notification.id");
                                                        deleteNotification(notification.id, null);
                                                                    }}/>
                                                <div onClick={() => {
                                                    setNotificationBox(true);
                                                    setIndividualNotification(notification);
                                                }}>
                                                    <Typography variant="h4" className="floatLeft w100">
                                                        {/* <PeopleAltIcon className="floatLeft mr5"/> Upgrades to Groups */}
                                                        {notification.title}
                                                    </Typography>
                                                    <Typography align="left" className="floatLeft w100 mb0">
                                                        {dateUtils.asDbDateFormat(notification.createdAt)}
                                                    </Typography>
                                                </div>
                                            </div>
                                        </Grid>

                                    </Grid>
                                </MenuItem>
                            );
                        })
                    )}
                    {notificationsData.length === 0 && (
                        <MenuItem>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={12} lg={12} style={{ padding: "12px 2px" }}>
                                    <Card className="muiCard" style={{ padding: "0 10px" }}>
                                        <Typography variant="caption" className="mb0">
                                            No Notifications.
                                        </Typography>
                                    </Card>
                                </Grid>
                            </Grid>
                        </MenuItem>
                    )}
                    {deleteModal &&
                        <Dialog
                            fullWidth
                            maxWidth={"sm"}
                            open={true}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogContent dividers>
                                <Typography variant="body1" className="mb0 f18"> Are you sure to clear all
                                    notifications? </Typography>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    className="floatRight"
                                    type="button"
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    label="Yes"
                                    onClick={async () => {
                                        deleteNotification(0, "ALL");
                                        let response: any = await notificationActions.getNotification();
                                        if (response.success) {
                                            getNotificationCount(response.data);
                                        }
                                        setDeleteModal(false);
                                    }}
                                />
                                <Button
                                    className="bgRed floatRight"
                                    type="button"
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    label="No"
                                    onClick={() => setDeleteModal(false)}
                                />
                            </DialogActions>
                        </Dialog>
                    }
                </Menu>
            </>
        );
    };

    const deleteNotification = (id: String | Number, type?: string | null) => {
        if (notificationsData && notificationsData.length > 0) {
            let eaxcatNotificsation = notificationsData.filter((notification: any) => notification.id !== id);
            setNotificationsData(eaxcatNotificsation);
            getNotificationCount(eaxcatNotificsation);
            // Call Delete Action
            notificationActions.deleteNotification(
                id, type,
            );
        }
        if (type && type === "ALL") {
            setNotificationsData([]);
        }
    };

    if (user && user.role && (user.role === AccountTypeByName.master_admin)) {
        isShowNotifyIcon = false;
    }

    React.useEffect(() => {
        if (user && user.image) {
            setTimeout(() => setTopRightProfileImage((user && user.image) || ""), 500);
        }
    }, [user]);

    React.useEffect(() => {
        if (profileImage.image) {
            setTimeout(() => setTopRightProfileImage(LOGO_BASE_URL + profileImage.image), 500);
        }
        if (profileImage.image === null) {
            setTimeout(() => setTopRightProfileImage(""), 500);
        }
    }, [profileImage]);

    React.useEffect(() => {
        const runInitialSteps = async () => {
            if (user && user.role === 6 && user.client && user.client.company) {
                if (user.client.company.user) {
                    //setCompanyLogo(user.client.company.user.image);
                }
            }
            history.listen(() => {
                if (history.location.pathname.search("/client/estimation-new") > -1) {
                    setEstimationPage(true);
                    setCheckClick(Math.random());
                } else {
                    setEstimationPage(false);
                }
            });
        };

        const getNotifications = async () => {
            let response: any = await notificationActions.getNotification();
            if (response.success) {
                getNotificationCount(response.data);
                setNotificationsData(response.data);
                return;
            }
        };
        let isLeadLayout: boolean = history.location.pathname.search("/kaizen") > -1 || history.location.pathname.search("/agent-link/") === 0 || false;
        if (!isLeadLayout) {
            getNotifications().then();
        }
        runInitialSteps().then();
    }, []);

    React.useEffect(() => {
        if (isBigScreen()) {
            // TODO check
            dispatch({ type: ActionType.LEFTPANEL, payload: false });
        } else {
            dispatch({ type: ActionType.LEFTPANEL, payload: true });
        }
    }, [estimationPage, checkClick]);

    React.useEffect(() => {
        if (props.accessType === "") {
            setClinetDemo(false);
        }
    }, [props.accessType]);
    const agentPath: string = window.location.pathname;
    const isAgentpath = agentPath.includes("agent-link/");
    return (
        <AppBar className={clsx(classes.appBar, {
            [classes.appBarShift]: leftPanelOpen,
            }, clientDemo && "clientDemo", leftPanelOpen && "displayNoneSmall")} position="fixed" style={{display: isAgentpath ? "none": "block"}}>
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    className="menuButton"
                >
                        <MenuIcon className="btnToggleUp"/>
                </IconButton>
                <div className={classes.sectionDesktop}>
                    {isShowNotifyIcon &&
                        <IconButton
                            className="notificationsIcon mr30"
                            aria-label="User Welcome"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            color="inherit"
                            onClick={handleNotification}
                        >
                            <Badge badgeContent={notificationsCount} color="error"
                                className="notificationsCount">
                                        <NotificationsNoneIcon/>
                            </Badge>
                        </IconButton>
                    }
                    {
                        renderNotificationTray(openNotificationTray)
                    }
                    <p className="miniF0 f18">
                        Welcome!
                        <strong style={{ padding: 5 }}>
                            {
                                user && user.role === 12 && user.trustee && (user.trustee.trusteeName)
                            }
                            {
                                user && user.role === 13 && user.bank && (user.bank.bankName)
                            }
                            {
                                (user && user.role !== 12 && user.role !== 13) && (user.firstName)
                            }
                        </strong>
                    </p>

                        <IliaAvatar onClick={handleMenu} topRightProfileImage={topRightProfileImage}/>

                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        open={openMenu}
                        onClose={handleClose}
                        className={clientDemo ? "clientDemoMenu ClientSetting" : "ClientSetting"}
                    >
                        {
                            (user && profilePageArr.find((p: number) => p === user.role)) &&
                            user.role !== AccountType.Aggregator &&
                            <MenuItem onClick={() => {
                                handleClose();
                                if (user.accessType !== "client") {
                                    if (user.role === 7) {
                                        history.push("/carrier/profile");
                                        return;
                                    }
                                    history.push("/profile");
                                }
                            }}>
                                {user.role === 4 ? "My Agent Account" : "My Profile"}
                            </MenuItem>
                        }

                        {
                            parentPortal && <MenuItem onClick={() => {
                                handleClose();
                                authActions.toggleParentPortal(false);
                                isServiceDashboardAccessible || user.accessType === "client" ? history.push("/client/dashboard") : history.push("/dashboard")
                            }}>
                                   Exit Portal
                            </MenuItem>
                        }

                        {
                            (user && 3 === user.role) &&
                            <MenuItem onClick={() => {
                                handleClose();
                                if (user.accessType !== "client") {
                                    history.push("/imos/details");
                                }
                            }}> IMO Details </MenuItem>
                        }
                        {
                            (user && 18 === user.role) &&
                            <MenuItem onClick={() => {
                                handleClose();
                                history.push("/aggregator/details");
                            }}> Aggregator Details </MenuItem>
                        }
                        {
                                    user && user.role !== 4 && !parentPortal && (
                                <MenuItem onClick={() => {
                                    handleClose();
                                    if (user.accessType !== "client") {
                                        history.push("/account");
                                    }
                                }}>
                                    {(user && user.role === AccountType.Aggregator) ? 'Reset Password' : 'My Account'}
                                </MenuItem>
                            )
                        }
                        {!parentPortal && <MenuItem onClick={() => {
                            handleClose();
                            if (user.accessType !== "client") {
                                history.push("/bugs-feedback");
                            }
                        }}>Bugs/Feedback</MenuItem>}
                        {
                            user && user.role === 8 &&
                            <MenuItem onClick={() => {
                                handleClose();
                                if (user.accessType !== "client") {
                                    history.push("/group-settings");
                                }
                            }}>Group Settings</MenuItem>
                        }
                        {
                            (user && demoLinks.find((p: number) => p === user.role)) && user.accessType !== "client" &&
                            <MenuItem onClick={() => {
                                handleClose();
                                demoClient().then();
                            }}>Demo</MenuItem>
                        }
                        {
                            (user && demoLinks.find((p: number) => p === user.role)) && user.accessType === "client" && !user.demoClientId &&
                            <MenuItem onClick={() => {
                                handleClose();
                                exitDemoClient().then();
                            }}>Exit Demo</MenuItem>
                        }
                        <MenuItem onClick={onLogout}>Logout</MenuItem>
                    </Menu>
                </div>
            </Toolbar>
        </AppBar>
    );
};

export default MainHeader;
