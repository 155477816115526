import * as React from "react";
import { Redirect, Route, RouteProps } from "react-router";
import {AccountType, isServicingAccessible} from '../constants';
import { Account } from "../model/account";
import { can } from "./navigation/AdminNavigation";
import {AccountStatus} from "../model";
import { useSelector } from "react-redux";
import { RootState } from "../reducers";

export interface PrivateRouteProps extends RouteProps {
	isAuthenticated: boolean;
	authenticationPath: string;
	allowedRoles : number[];
	user: Account;
    routeType?: "parentPortal" | "",
	permissions?: number[];
    parentPortal?: boolean ,
    isServicing?: boolean;
    isNormalDashBoard? : boolean
}

const parentPortalRoute = "/parentPortal"

export class PrivateRoute extends Route<PrivateRouteProps> {
	public render() {
		let redirectPath: string = "";

        // parentPortal is enable but route routeType is not parentPortal then we redirect to default Route
        if( this.props.routeType != "parentPortal" && this.props.parentPortal){
            redirectPath = parentPortalRoute;
        }

		if (!this.props.isAuthenticated) {
			redirectPath = this.props.authenticationPath;
		}

		const { user, permissions } = this.props

		const hasNoPermission = 
			user && permissions && permissions.some(permission => !can(user, permission))
		if (hasNoPermission) {
			redirectPath = this.props.authenticationPath;
		}


		let slug: any = "kaizen";
		if (this.props.isAuthenticated && user.client && user.client.strategy) {
			slug = user.client.strategy.slug;
		}

		if (redirectPath) {
			const renderComponent = () => (<Redirect to={{ pathname: redirectPath }}/>);
			return<Route {...this.props} component={renderComponent} render={undefined}/>;
		} else {
			const role = user.role || -1;
            const isAccessServices = user && user.inviteStatus && isServicingAccessible(user) || false;
            const isNormalDashBoard = this.props.isNormalDashBoard || false;
            let nonClientUser = [ AccountType.Individual, AccountType.Multilife].indexOf(role) === -1; // Non Client Access
			let clientRoutesAccess = this.props.allowedRoles.indexOf(role) > -1; //Client user  Access
			let clientAllowedRoutesAccess = this.props.allowedRoles.indexOf( AccountType.Individual ) > -1; // Client user Access Rotes
            let isServicingPage = this.props.isServicing || false;

			// TODO: seems similar clientRoutesAccess
			let allUsersRoutesAccess = this.props.allowedRoles.indexOf(role) > -1; // All user access routes
            let isAccessType = user.accessType === "client";

			if( clientRoutesAccess || nonClientUser || allUsersRoutesAccess ){
                if ((!nonClientUser && !isAccessServices && isServicingPage) || (!nonClientUser && isAccessServices && isNormalDashBoard)) {
                    return <Route {...this.props} />;
                }
				if(allUsersRoutesAccess && !isAccessType){
					return <Route {...this.props} />
				}

				if(  nonClientUser && !isAccessType &&  clientAllowedRoutesAccess){
					return <Route {...this.props}  component={React.lazy(() => import("../pages/accounts/AccountPage"))}/>
				}
				
				if(  nonClientUser && isAccessType &&  !clientAllowedRoutesAccess ){
					return <Route {...this.props}  component={React.lazy(() => import(`../pages/client/${slug}/ClientIntro`))} />;
				}

				return <Route {...this.props} />
			}

			return <Route {...this.props}  component={React.lazy(() => import(`../pages/client/${slug}/ClientIntro`))} />;
		}
	}
}