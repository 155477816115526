import {getCarrierCode} from "../../../../utils/commonUtils";
import numeral from "numeral";
import {getEmployerAndParticipantCont} from "../../Enrollment/EnrollmentHome/EnrollmentApplication/Utilities";

export const InvestmentValues = {
    SP500: "S&P500",
    lowVolatility: "lowVolatility",
    // Todo @tejveer remove unsed constants after complete work
    SP500ALZ: "S&P500ALZ", //this is for S&P Futures
    lowVolatilityALZ: "lowVolatilityALZ",
    Conservative: "Conservative",
    Moderate: "Moderate",
    Aggressive: "Aggressive",
    ConservativePlus: "ConservativePlus",
    ModeratePlus: "ModeratePlus",
    AggressivePlus: "AggressivePlus",
};

export const isSuffixExist  = (str) =>{
    return !!InvestmentValues[str+"Plus"]
}

export function getInvestmentValue(str: keyof typeof InvestmentValues) {
   return  checkSuffixPlus( str) ? str.substring(0,str.length - 4) : str
}
export const checkSuffixPlus = (str:any, suffix= 'Plus') =>{

    // Check if either string is null or empty
    if (((str === null) || (str === '')) || ((suffix === null) || (suffix === '')))
    {
        return false; // Return false if either string is null or empty
    }
    else
    {
        // Convert both strings to type string
        str = str.toString();
        suffix = suffix.toString();
    }
    // Check if the suffix is found at the end of the string
    return str.indexOf(suffix, str.length - suffix.length) !== -1;
}
export const InvestmentValuesLabel = {
    [InvestmentValues.SP500]: "S&P 500",
    [InvestmentValues.lowVolatility]: "low Volatility",
    [InvestmentValues.SP500ALZ]: "S&P Futures",
    [InvestmentValues.lowVolatilityALZ]: "BUDBI Classic",
    [InvestmentValues.Conservative]: "Conservative",
    [InvestmentValues.Moderate]: "Moderate",
    [InvestmentValues.Aggressive]: "Aggressive",
    [InvestmentValues.ConservativePlus]: "Conservative PLUS",
    [InvestmentValues.ModeratePlus]: "Moderate PLUS",
    [InvestmentValues.AggressivePlus]: "Aggressive PLUS",
};

export const allocationMapper = {
    [InvestmentValues.SP500]: ["SP500PointToPointCapFocus"],
    [InvestmentValues.lowVolatility]: ["BalancedTrendIndexPointToPointNoCap", "USPacesetterIndexPointtoPointNoCap"],
    [InvestmentValues.SP500ALZ]: ["130"],
    [InvestmentValues.lowVolatilityALZ]: ["100"],
    [InvestmentValues.Conservative]: ["101", "131", "130", "129"],
    [InvestmentValues.Moderate]: ["77", "128", "127", "126"],
    [InvestmentValues.Aggressive]: ["86", "125", "124", "123"],
    [InvestmentValues.ConservativePlus]: ["101", "131", "129", "130", "3"],
    [InvestmentValues.ModeratePlus]: ["77", "128", "127", "126", "3"],
    [InvestmentValues.AggressivePlus]: ["86", "125", "124", "123", "3"],
}


export const allocationIndexs = {
    "41": {
        desc:"",
    },
    "86" : {
        desc:"The PIMCO Tactical Balanced ER Index comprises two underlying components that shift weights daily based on historical realized volatility. The first component is U.S. Equity Futures Custom Index, which tracks futures on large-cap equities, similar to futures on the S&P 500. The second component is PIMCO Synthetic Bond ER which is a custom index made up of a small number of instruments dsigned to provide exposure to futures on U.S. investment-grade and Treasure bond markets. The Select bonus multiplies anyu annual indexed interest by 40% but also includes a 1% annual asset charge. This bonus is designed for clients who are willing to pay a charge in return for a higher bonus and greater indexed interest potential.",
    },
    "66":{
        desc:"",
    },
    "77":{
        desc:"The PIMCO Tactical Balanced ER Index comprises two underlying components that shift weights daily based on historical realized volatility. The first component is U.S. Equity Futures Custom Index, which tracks futures on large-cap equities, similar to futures on the S&P 500. The second component is PIMCO Synthetic Bond ER which is a custom index made up of a small number of instruments dsigned to provide exposure to futures on U.S. investment-grade and Treasure bond markets. The Bonused bonus multiplies any annual indexted interest by 15%. This bonus is designed for clients looking for a moderate choice with bonus potential but no charges associated with the bonus.",
    },
    "102":{
        desc:"",
    },
    "101":{
        desc:"The PIMCO Tactical Balanced ER Index comprises two underlying components that shift weights daily based on historical realized volatility. The first component is U.S. Equity Futures Custom Index, which tracks futures on large-cap equities, similar to futures on the S&P 500. The second component is PIMCO Synthetic Bond ER which is a custom index made up of a small number of instruments dsigned to provide exposure to futures on U.S. investment-grade and Treasure bond markets. The Classic bonus provides a 1% guaranteed bonus to the policy's current value - designed for clients who may want a conservative approach and a guaranteed credit.",
    },
    "7":{
        desc:"The S&P 500 Index represents a broad cross-section of common stocks traded on every major U.S. stock exchange. The index is a selection of 500 leading companies from 100 distinct industry groups found in 10 leading American industrial market sectors. The point-to-point crediting method traches changes in the index value through the policy year; if there is a positive change in the index value from the previous year, the policy is credited with any earned interest subject to a cap or participation rate, and if there is a negative change, the indexed interest for that year will be zero.",
    },
    "5":{
    desc:"The S&P 500 Index represents a broad cross-section of common stocks traded on every major U.S. stock exchange. The index is a selection of 500 leading companies from 100 distinct industry groups found in 10 leading American industrial market sectors. The monthly sum crediting method tracks the monthly changes in an index, subject to a cap. At the end of each year, the 12 monthly changes (positive and negative) are added up; if the total is positive, this is credited to the policy, and if negative the indexed interest for that year will be zero."
    },
    "47":{
    desc:"The S&P 500 Index represents a broad cross-section of common stocks traded on every major U.S. stock exchange. The index is a selection of 500 leading companies from 100 distinct industry groups found in 10 leading American industrial market sectors. The trigger method tracks changes in the index value throught the the policy year, and any change in the in the index's value that is greater than or equal to zero will trigger a predetermined interest rate - the Trigger Interest Rate - to be credited to the policy (e.g., if the Trigger Interest Rate is 5%, and the index returned 2.5%, youd be credited with 5%). Negative changes would credit 0%"
    },
    "18":{
        desc:""
    },
    "80":{
        desc:"The PIMCO Tactical Balanced ER Index comprises two underlying components that shift weights daily based on historical realized volatility. The first component is U.S. Equity Futures Custom Index, which tracks futures on large-cap equities, similar to futures on the S&P 500. The second component is PIMCO Synthetic Bond ER which is a custom index made up of a small number of instruments dsigned to provide exposure to futures on U.S. investment-grade and Treasure bond markets."
    },
    "3":{
        desc:""
    },
    "125":{
        desc:"The Blended Futures Index is a non-volatility-controlled index that is constructed by allocating to futures indexes in fixed percentages to proved a 60/40 mix between equity and bond indexes. The components of the Blended Futures Index use an excess return methodology by tracking the proce of futures. Futures prices reflect the expected future proced of an index and account for expected dividends. The Select bonus multiplies anyu annual indexed interest by 40% but also includes a 1% annual asset charge. This bonus is designed for clients who are willing to pay a charge in return for a higher bonus and greater indexed interest potential."
    },
    "124":{
        desc:"The S&P 500 Futures Index ER is constructed from the front-quarter E-mini futures contract on the S&P 500. It tracks three-month futures on teh S&P 500 Index. The S&P 500 Futures Inex ER uses an excess return methodology by tracking the price of futures. Futures prices reflect the expected future price of an index and account for expected dividends. The Select bonus multiplies anyu annual indexed interest by 40% but also includes a 1% annual asset charge. This bonus is designed for clients who are willing to pay a charge in return for a higher bonus and greater indexed interest potential."
    },
    "123":{
        desc:"The Bloomberg US Dynamic Balance III ER Index is comprised of varying exposure to the Bloomberg US Equity Futures Basket ER Index, which is comprised of three sub-indexes: the Bloomberg US Equity Custom Futures ER Index, the Bloomberg US Small Cap Custom Futures ER Index, and the Bloomberg NDX Equity Custom Futres ER Index, with weights of 80%, 10%, and 10%, respectively. These sub-indexes are rebalanced daily. The Select bonus multiplies anyu annual indexed interest by 40% but also includes a 1% annual asset charge. This bonus is designed for clients who are willing to pay a charge in return for a higher bonus and greater indexed interest potential."
    },
    "128":{
        desc:"The Blended Futures Index is a non-volatility-controlled index that is constructed by allocating to futures indexes in fixed percentages to proved a 60/40 mix between equity and bond indexes. The components of the Blended Futures Index use an excess return methodology by tracking the proce of futures. Futures prices reflect the expected future proced of an index and account for expected dividends. The Bonused bonus multiplies any annual indexted interest by 15%. This bonus is designed for clients looking for a moderate choice with bonus potential but no charges associated with the bonus."
    },
    "127": {
        desc: "The S&P 500 Futures Index ER is constructed from the front-quarter E-mini futures contract on the S&P 500. It tracks three-month futures on teh S&P 500 Index. The S&P 500 Futures Inex ER uses an excess return methodology by tracking the price of futures. Futures prices reflect the expected future price of an index and account for expected dividends."
    },
    "126": {
        desc: "The Bloomberg US Dynamic Balance III ER Index is comprised of varying exposure to the Bloomberg US Equity Futures Basket ER Index, which is comprised of three sub-indexes: the Bloomberg US Equity Custom Futures ER Index, the Bloomberg US Small Cap Custom Futures ER Index, and the Bloomberg NDX Equity Custom Futres ER Index, with weights of 80%, 10%, and 10%, respectively. These sub-indexes are rebalanced daily. The Bonused bonus multiplies any annual indexted interest by 15%. This bonus is designed for clients looking for a moderate choice with bonus potential but no charges associated with the bonus."
    },
    "131": {
        desc: "The Blended Futures Index is a non-volatility-controlled index that is constructed by allocating to futures indexes in fixed percentages to proved a 60/40 mix between equity and bond indexes. The components of the Blended Futures Index use an excess return methodology by tracking the proce of futures. Futures prices reflect the expected future proced of an index and account for expected dividends. The Classic bonus provides a 1% guaranteed bonus to the policy's current value - designed for clients who may want a conservative approach and a guaranteed credit."
    },
    "130": {
        desc: "The S&P 500 Futures Index ER is constructed from the front-quarter E-mini futures contract on the S&P 500. It tracks three-month futures on teh S&P 500 Index. The S&P 500 Futures Inex ER uses an excess return methodology by tracking the price of futures. Futures prices reflect the expected future price of an index and account for expected dividends. The Classic bonus provides a 1% guaranteed bonus to the policy's current value - designed for clients who may want a conservative approach and a guaranteed credit."
    },
    "129": {
        desc: "The Bloomberg US Dynamic Balance III ER Index is comprised of varying exposure to the Bloomberg US Equity Futures Basket ER Index, which is comprised of three sub-indexes: the Bloomberg US Equity Custom Futures ER Index, the Bloomberg US Small Cap Custom Futures ER Index, and the Bloomberg NDX Equity Custom Futres ER Index, with weights of 80%, 10%, and 10%, respectively. These sub-indexes are rebalanced daily. The Classic bonus provides a 1% guaranteed bonus to the policy's current value - designed for clients who may want a conservative approach and a guaranteed credit."
    }
}


export const addPlus = (plus, code) =>{
     if(plus && InvestmentValues[code+"Plus"]){
        return code+"Plus"
     }
     return code
}

export const overRideLabels = {
    // [InvestmentValues.ConservativePlus]: {
    //         "101": "20% PIMCO (1% Bonus)",
    //         "131": "20% Blended Futures (1% Bonus)",
    //         "130": "20% S&P Futures (1% Bonus)",
    //         "129": "20% BUDBI (1% Bonus)",
    //         "3": "20% Fixed Account"
    // },
    // [InvestmentValues.ModeratePlus]: {
    //     "77": "20% PIMCO (15% Multiplier)",
    //     "128": "20% Blended Futures (15% Multiplier)",
    //     "127": "20% S&P Futures (15% Multiplier)",
    //     "126": "20% BUDBI (15% Multiplier)",
    //     "3": "20% Fixed Account"
    // },
    // [InvestmentValues.AggressivePlus]: {
    //     "86": "20% PIMCO Select (40% Multiplier)",
    //     "125": "20% Blended Futures Select (40% Multiplier)",
    //     "124": "20% S&P Futures Select (40% Multiplier)",
    //     "123": "20% BUDBI Select (40% Multiplier)",
    //     "3": "20% Fixed Account"
    // },
    // [InvestmentValues.SP500ALZ]: {
    //     "130": "S&P 500® Futures (1% Bonus)"
    // }

}
export const allocationQuestions = {
    [InvestmentValues.SP500]: {
        codes:["SP500PointToPointCapFocus"],
        labels: ["S&P 500 Point to Point Cap Focus"]
    },
    [InvestmentValues.lowVolatility]: {
        codes:["BalancedTrendIndexPointToPointNoCap", "USPacesetterIndexPointtoPointNoCap"],
        labels: ["Balanced Trend Index Point to Point No Cap", "US Pacesetter Index Point To Point No Cap"]
    },
    [InvestmentValues.lowVolatilityALZ]: {
        codes:["100"],
        labels: ["BUDBI Classic 0.90% Guaranteed Bonus No Additional Charge"]
    },
    [InvestmentValues.SP500ALZ]: {
        codes:["130"],
        labels: ["S&P 500® Futures"]
    },
    [InvestmentValues.Conservative]: {
        codes:["101", "131", "130", "129"],
        labels: ["PIMCO (1% Bonus)", " Blended Futures (1% Bonus)", " S&P Futures (1% Bonus))", " BUDBI (1% Bonus)"]
    },
    [InvestmentValues.ConservativePlus]: {
        codes:["101", "131", "129", "130", "3"],
        labels: ["PIMCO (1% Bonus)", " Blended Futures (1% Bonus)", " S&P Futures (1% Bonus))", " BUDBI (1% Bonus)", " Fixed Allocations"]
    },
    [InvestmentValues.Moderate]: {
        codes:["77", "128", "127", "126"],
        labels: ["PIMCO (15% Multiplier)", " Blended Futures (15% Multiplier)", " S&P Futures (15% Multiplier)", " BUDBI (15% Multiplier)"]
    },
    [InvestmentValues.ModeratePlus]: {
        codes:["77", "128", "127", "126", "3"],
        labels: ["PIMCO (15% Multiplier)", " Blended Futures (15% Multiplier)", " S&P Futures (15% Multiplier)", " BUDBI (15% Multiplier)", " Fixed Allocations"]
    },
    [InvestmentValues.Aggressive]: {
        codes: ["86", "125", "124", "123"],
        labels: ["PIMCO Select (40% Multiplier)", " Blended Futures Select (40% Multiplier)", " S&P Futures Select (40% Multiplier)", " BUDBI Select (40% Multiplier)"]
    },
    [InvestmentValues.AggressivePlus]: {
        codes: ["86", "125", "124", "123", "3"],
        labels: ["PIMCO Select (40% Multiplier)", " Blended Futures Select (40% Multiplier)", " S&P Futures Select (40% Multiplier)", " BUDBI Select (40% Multiplier)", " Fixed Allocations"]
    },
}

export function isALGCarrier(carrierId:any){
    const carrierCode = getCarrierCode(carrierId);
    return carrierCode == "ALZ"
}

export function calculateGrowthRate(allocations){
    return allocations && allocations.reduce((total, question) => {
        return Number(question && question.allocationrate) + total
    }, 0) / allocations.length;
}

export const ALZInterestPayload =[
    {
        "allocations": [
            {
                "allocationname": "100",
                "allocationrate": 6.74,
                "allocationweight": 100
            }
        ],
        "from": "1",
        "through": "M"
    }
]

export const stressAllocationForALZ =  [
        {
            "allocations": [
                {
                    "allocationname": "101",
                    "allocationrate": 0,
                    "allocationweight": 25
                },
                {
                    "allocationname": "131",
                    "allocationrate": 0,
                    "allocationweight": 25
                },
                {
                    "allocationname": "130",
                    "allocationrate": 0,
                    "allocationweight": 25
                },
                {
                    "allocationname": "129",
                    "allocationrate": 0,
                    "allocationweight": 25
                }
            ],
            "from": "1",
            "through": "5"
        },
        {
            "allocations": [
                {
                    "allocationname": "101",
                    "allocationrate": 6.72,
                    "allocationweight": 25
                },
                {
                    "allocationname": "131",
                    "allocationrate": 6.72,
                    "allocationweight": 25
                },
                {
                    "allocationname": "130",
                    "allocationrate": 6.72,
                    "allocationweight": 25
                },
                {
                    "allocationname": "129",
                    "allocationrate": 6.72,
                    "allocationweight": 25
                }
            ],
            "from": "6",
            "through": "7"
        },
        {
            "allocations": [
                {
                    "allocationname": "101",
                    "allocationrate": 0,
                    "allocationweight": 25
                },
                {
                    "allocationname": "131",
                    "allocationrate": 0,
                    "allocationweight": 25
                },
                {
                    "allocationname": "130",
                    "allocationrate": 0,
                    "allocationweight": 25
                },
                {
                    "allocationname": "129",
                    "allocationrate": 0,
                    "allocationweight": 25
                }
            ],
            "from": "8",
            "through": "8"
        },
        {
            "allocations": [
                {
                    "allocationname": "101",
                    "allocationrate": 6.72,
                    "allocationweight": 25
                },
                {
                    "allocationname": "131",
                    "allocationrate": 6.72,
                    "allocationweight": 25
                },
                {
                    "allocationname": "130",
                    "allocationrate": 6.72,
                    "allocationweight": 25
                },
                {
                    "allocationname": "129",
                    "allocationrate": 6.72,
                    "allocationweight": 25
                }
            ],
            "from": "9",
            "through": "M"
        }
    ];

export const stressAllocationsData = [
    {
        "allocations": [
            {
                "allocationname": "BalancedTrendIndexPointToPointNoCap",
                "allocationrate": 0,
                "allocationweight": 50
            },
            {
                "allocationname": "USPacesetterIndexPointtoPointNoCap",
                "allocationrate": 0,
                "allocationweight": 50
            }
        ],
        "from": "1",
        "through": "5"
    },
    {
        "allocations": [
            {
                "allocationname": "BalancedTrendIndexPointToPointNoCap",
                "allocationrate": 6.44,
                "allocationweight": 50
            },
            {
                "allocationname": "USPacesetterIndexPointtoPointNoCap",
                "allocationrate": 6.44,
                "allocationweight": 50
            }
        ],
        "from": "6",
        "through": "7"
    },
    {
        "allocations": [
            {
                "allocationname": "BalancedTrendIndexPointToPointNoCap",
                "allocationrate": 0,
                "allocationweight": 50
            },
            {
                "allocationname": "USPacesetterIndexPointtoPointNoCap",
                "allocationrate": 0,
                "allocationweight": 50
            }
        ],
        "from": "8",
        "through": "8"
    },
    {
        "allocations": [
            {
                "allocationname": "BalancedTrendIndexPointToPointNoCap",
                "allocationrate": 6.44,
                "allocationweight": 50
            },
            {
                "allocationname": "USPacesetterIndexPointtoPointNoCap",
                "allocationrate": 6.44,
                "allocationweight": 50
            }
        ],
        "from": "9",
        "through": "9"
    },
    {
        "allocations": [
            {
                "allocationname": "BalancedTrendIndexPointToPointNoCap",
                "allocationrate": 0,
                "allocationweight": 50
            },
            {
                "allocationname": "USPacesetterIndexPointtoPointNoCap",
                "allocationrate": 0,
                "allocationweight": 50
            }
        ],
        "from": "10",
        "through": "12"
    },
    {
        "allocations": [
            {
                "allocationname": "BalancedTrendIndexPointToPointNoCap",
                "allocationrate": 6.44,
                "allocationweight": 50
            },
            {
                "allocationname": "USPacesetterIndexPointtoPointNoCap",
                "allocationrate": 6.44,
                "allocationweight": 50
            }
        ],
        "from": "13",
        "through": "M"
    }
]


