import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ClientProfileTab from "./tabs/ClientProfileTab";
import ParticipantStatusTab from "./tabs/ParticipantStatusTab";
import EnrollmentTab from "./tabs/EnrollmentTab";
import IliaEnrollmentTab from "./tabs/iliaApp/IliaEnrollmentTab";
import ActionItemsTab from "./tabs/ActionItemsTab";
import DocumentsTab from "./tabs/DocumentsTab";
import NotesTab from "./tabs/NotesTab";
import CaseDetailTab from "./tabs/CaseDetailTab";
import ClientSnapshot from "./ClientSnapshot";
import {Grid} from "@material-ui/core";
import {useActions} from "../../../../actions";
import * as ClientActions from "../../../../actions/client";
import * as NotificationActions from "../../../../actions/notification";
import {useSelector} from "react-redux";
import {RootState} from "../../../../reducers";
import BadgeOverlap from "../../../../components/BadgeOverlap";
import AdminActions from "./AdminActions";
import {history} from "../../../../configureStore";
import {AccountType, GWT_GEN2_STRATEGY, PermissionTypes} from "../../../../constants";
import {can} from "../../../../components/navigation/AdminNavigation";
import {TicketsPanels} from "../../../ticket/TicketPage";
import { isAllianzApp, isIliaApp } from "../../Enrollment/EnrollmentHome/EnrollmentApplication/Utilities";
import { LinkTab, useTabWithLocation } from "../../../imo/ImoProfile/ImoProfile";
import CaseDetailAgentTab from "./tabs/CaseDetailAgentTab";
import { useOfferType } from "../../../../hooks/useOfferType";
import TotalRemainingPayments from "../../Enrollment/EnrollmentHome/payment/TotalRemainingPayments";
import { usePolicyContext } from "../../../../components/clientServicing/Payments/PolicyContext";
import {a11yProps as a11yPropsCommon} from '../../../../utils/commonUtils';
import AllianzAppEnrollmentTab from "./tabs/AllianzAppEnrollmentTab";


interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

export function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
            <div
                    role="tabpanel"
                    hidden={value !== index}
                    id={`scrollable-force-tabpanel-${index}`}
                    aria-labelledby={`scrollable-force-tab-${index}`}
                    {...other}
                    className="divAnimation"
            >
                {value === index && (
                        <Box>
                            {children}
                        </Box>
                )}
            </div>
    );
}

export function a11yProps(index: any) {
    return {
        id: `scrollable-force-tab-${index}`,
        "aria-controls": `scrollable-force-tabpanel-${index}`,
    };
}

export const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        width: "100%",
    },
}));
type TabMenuProps = {
    urlClientId: number,
    clientDetail: any,
    role: number | undefined,
    updateActionItem: Function,


    currentTickets: any[],
    currentTicketsPagination?: any,
    setCurrentTicketsPagination?: Function,

    completedTickets: any[],
    completedTicketsPagination: any,
    setCompletedTicketsPagination: Function,
    onResolveTicket: Function
    onTicketCreated: Function,

    // real active tab
    value: number,
    setValue: Function
}

let apiCount: number = 0;

export default function TabsMenu(props: TabMenuProps) {
    const {
        urlClientId, role,
        currentTickets, completedTickets, completedTicketsPagination, setCompletedTicketsPagination, onResolveTicket,
        onTicketCreated, currentTicketsPagination, setCurrentTicketsPagination
    } = props;

    const classes = useStyles();
    const saveCaseDetailButtonRef = React.useRef(null);
    const [actionItem, setActionItem] = React.useState(0);
    const {getClient} = useActions(ClientActions);
    const {getPendingCounts, updatePendingCounts} = useActions(NotificationActions);
    const clientDetail: any = useSelector((state: RootState) => state.clientAccount);
    const user: any = useSelector((state: RootState) => state.auth.user);
    const paymentFrequency: string = clientDetail && clientDetail.client && clientDetail.client.clientPolicies && clientDetail.client.clientPolicies[0] && clientDetail.client.clientPolicies[0].paymentFrequency || "annual";
    let offerType = useOfferType();
    const adminPermission: boolean = can(user, PermissionTypes.ACCOUNT_MANAGEMENT) && clientDetail.inviteStatus > 2;
    const [ssnFlag, setSsnFlag] = React.useState<boolean>((
            role === 1 || role === 2 || role === 4) ? true
            :
            (!!(clientDetail.enrollment && clientDetail.enrollment.clientSigned && clientDetail.client && clientDetail.client.usCitizen))
    );
    const [notificationAlert, setNotificationAlert] = React.useState({
        actionItemsCount: 0,
        participantStatusCount: 0,
        notesCount: 0,
        leadsCount: 0
    });
    const isGWTGen2 = clientDetail && clientDetail.client &&
            !!(
                    clientDetail.client.strategyId === GWT_GEN2_STRATEGY &&
                    clientDetail.client.coverLetterReq &&
                    clientDetail.client.currentlyEmployed === "Other" &&
                    clientDetail.client.parentsAreIncomeEarner &&
                    clientDetail.client.wealthGiftTransfer
            );

    const profileTabs = ["profile", "status", "details", "action-items", "documents", "notes", "case-details", "tickets"];
    const { value, setValue } = useTabWithLocation(profileTabs)

    // const clientDetailTabs = ["client-profile", "participant-status", "enrollment-details", "my-action-items", "documents", "notes", "case-details", "tickets"];
    // const { value, setValue } = useTabWithLocation(clientDetailTabs);
    // const handleChange = async (event: React.ChangeEvent<{}>, newValue: any) => {
    //     // @ts-ignore
    //     setValue(newValue);
    //     if (newValue === 1) {
    //         getClientDetail(urlClientId || 0, ssnFlag)
    //     }
    // };
    const getActiveTab = (value) => {
        let index = value;
        if (role === 3 || role === 8 || role === 15 || role === 16) {
            if (index === 2) {
                index = 4;
            } else if (index === 3) {
                index = 5;
            } else if (index === 4) {
                index = 3;
            }
        }
        if (role === 12) {
            if (index === 2) {
                index = 3;
            } else if (index === 3) {
                index = 4;
            } else if (index === 4) {
                index = 5;
            } else if (index === 5) {
                index = 3;
            }
        }

        return index;
    }

    const getClientDetail = React.useCallback((clientId, ssnFlagValue) => {
        const getClientData = async () => {
            const result = await getClient(clientId, ssnFlagValue);
            if (result && result.statusCode && result.statusCode === 400) {
                apiCount = 0;
                if (role === 3 || role === 15) { //client not found when imo logged in
                    history.replace('/imo/individual');
                } else {
                    history.replace('/client');
                }
            } else {
                if (role === 8 && result.payload.client.companyId != user.company.id) {
                    history.replace('/group-participants');
                }
                if (role === 16 &&
                        result.payload.client.companyId != user.groupSigners.companyId) {
                    history.replace('/group-participants');
                }
            }
        }
        getClientData().then(() => {
        });
    }, [])

    React.useEffect(() => {
        let index = getActiveTab(value);
        //We will not call client detail api IN case of : Case Detail Tab/Notes Tab/Documents Tab

        if (value != "status" && value != "documents" && value != "notes" && value != "tickets" && apiCount < 1) {
            ++apiCount;
            getClientDetail(urlClientId || 0, ssnFlag);
        }
    }, []);

    React.useEffect(() => {
        const getClientData = async () => {
            const result = await getClient(urlClientId || 0, ssnFlag);
            if (result && result.statusCode && result.statusCode === 400) {
                apiCount = 0;
                if (role === 3 || role === 15) { //client not found when imo logged in
                    history.replace('/imo/individual');
                } else {
                    history.replace('/client');
                }
            }
        }
        let index = getActiveTab(value);
        //We will not call client detail api IN case of : Case Detail Tab/Notes Tab/Documents Tab
        if (value != "status" && value != "documents" && value != "notes" && value != "tickets") {
            getClientData().then(() => {
            });
        }
        if (role === AccountType.Carrier) { // for role 7 i.e carrier we do not need to get notifications

            return;
        }
        getNotificationAlerts().then(() => {
            let index = getActiveTab(value);
            if (index === 3 && notificationAlert.actionItemsCount > 0) {
                updateNotificationAlerts({actionItemsCount: 0});
            }
            if (index === 5 && notificationAlert.notesCount > 0) {
                updateNotificationAlerts({notesCount: 0});
            }
        });
    }, [value, actionItem]);

    const getNotificationAlerts = async () => {
        let pendingCounts = await getPendingCounts(urlClientId);
        if (pendingCounts) {
            setNotificationAlert(pendingCounts);
        }
    }

    const updateNotificationAlerts = async (payload: any) => {
        let pendingCounts = await updatePendingCounts(urlClientId, payload);
        if (pendingCounts) {
            setNotificationAlert({...notificationAlert, ...pendingCounts});
        }
    }

    React.useEffect(() => {
        if (clientDetail.client) {
            setTimeout(() => {
                apiCount = 0
            }, 1000);
            setSsnFlag(
                    (role === 1 || role === 2 || role === 4) ? true
                            :
                            (!!(clientDetail.enrollment && clientDetail.enrollment.clientSigned && clientDetail.client && clientDetail.client.usCitizen))
            );
            let index = getActiveTab(value);
            setTimeout(() => {
                if (index === 3 && notificationAlert.actionItemsCount > 0) {
                    updateNotificationAlerts({actionItemsCount: 0});
                }
            }, 500);
        }
    }, [clientDetail.client]);


    const handleChange = async (event: React.ChangeEvent<{}>, newValue: any) => {
        //props.updateActionItem(newValue);
        setValue(newValue)
        if (newValue === 1) {
            getClientDetail(urlClientId || 0, ssnFlag)
        }
    };

    const tabText = (tabIndex) => {
        let index = tabIndex;
        switch (index) {
            case 0:
                return "Below is the client basic profile information used for their invite and enrollment. Additional enrollment specific details can be filled in through the enrollment tab above.";
            case 1:
                return "Below is the list of steps we recorded throughout the invitation and enrollment process. Your clients current status is visible by the blue marker.";
            case 2:
                return "In addition to basic client details, you can optionally pre-fill specific questions for the client to streamline their enrollment process. They will verify and make any necessary changes when they enroll.";
            case 3:
                return "";
            case 4:
                return "All documents relevant for this client including images, applications, and proposals are available to view and download. Documents can be deleted if you added them to the list or by an NIW administrator.";
            case 5:
                return "Below are message logs of client, agent, trustee and NIW admin correspondence during enrollment.";
            case 7:
                return "Below are the current and completed tickets of the client";
        }
    };

    const refreshDetails = () => {
        getClientDetail(urlClientId || 0, ssnFlag);
    }

    const { policy } = usePolicyContext();
    const policyAtListOne = policy && policy.paymentTimetableAnnual && policy.paymentTimetableAnnual.recurringPayments && policy.paymentTimetableAnnual.recurringPayments.length > 0;

    return (<div className={classes.root}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <AppBar position="static" color="default">
                                <Tabs
                                        value={value}
                                        onChange={handleChange}
                                        variant="scrollable"
                                        scrollButtons="on"
                                        indicatorColor="primary"
                                        textColor="primary"
                                        aria-label="scrollable force tabs example"
                                >
                                <LinkTab label="CLIENT PROFILE" value={profileTabs[0]}/>
                                <LinkTab label="PARTICIPANT STATUS" value={profileTabs[1]} />
                                    {(role === 1 || role === 2 || role === 4) &&
                                        <LinkTab label="ENROLLMENT DETAILS" value={profileTabs[2]} />
                                    }
                                    {(role === 4 || role === 12 || (can(user, PermissionTypes.NIW_ACTION_ITEMS) && role !== AccountType.Carrier)) &&
                                        <LinkTab label={<>MY ACTION ITEMS {notificationAlert.actionItemsCount > 0 &&
                                                <BadgeOverlap />}</>} value={profileTabs[3]} />
                                    }
                                <LinkTab label="DOCUMENTS" value={profileTabs[4]} />
                                <LinkTab label={<>NOTES {notificationAlert.notesCount > 0 &&
                                        <BadgeOverlap />}</>} value={profileTabs[5]} />

                                {(role === 1 || role === 2 || role === 4) &&
                                        <LinkTab label={<>Case Details {isGWTGen2 &&
                                                <BadgeOverlap />}</>} value={profileTabs[6]} />
                                }

                                {(role == 1 || role == 2) && can(user, PermissionTypes.TICKETS) &&
                                        <LinkTab label="TICKETS" value={profileTabs[7]} />
                                    }
                                </Tabs>
                            </AppBar>
                        </Grid>
                        <Grid item xs={12} md={12} lg={8}>
                            <Typography variant="body1" className="mt20 mb20">
                                {tabText(value)}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={12} lg={8}>
                        <TabPanel value={value} index={profileTabs[0]}>
                                <ClientProfileTab urlClientId={urlClientId} clientDetail={clientDetail} role={role}
                                                  ssnFlag={ssnFlag}
                                                  setActionItem={() => setActionItem(actionItem + 1)}
                                                  getClientDetail={getClientDetail} />
                            </TabPanel>
                        <TabPanel value={value} index={profileTabs[1]}>
                                <ParticipantStatusTab setActionItem={() => {
                                setValue(profileTabs[4]);
                                }} clientDetail={clientDetail} role={role} />
                            </TabPanel>
                            {(role === 1 || role === 2 || role === 4) &&
                                    <TabPanel value={value}
                                          index={profileTabs[2]}>
                                        {isIliaApp(offerType) ? <IliaEnrollmentTab clientDetail={clientDetail}
                                                                                   setActionItem={(ssnFlagValue: boolean) => {
                                                                                       setSsnFlag(ssnFlagValue);
                                                                                       setActionItem(actionItem + 1);
                                                                                   }} getClientDetail={getClientDetail}
                                                                                   urlClientId={urlClientId}
                                                                                   ssnFlag={ssnFlag} /> :
                                                isAllianzApp(offerType) ? <AllianzAppEnrollmentTab
                                                                clientDetail={clientDetail}
                                                                setActionItem={(ssnFlagValue: boolean) => {
                                                                   setSsnFlag(ssnFlagValue);
                                                                   setActionItem(actionItem + 1);
                                                               }} /> :
                                                        <EnrollmentTab clientDetail={clientDetail}
                                                                setActionItem={(ssnFlagValue: boolean) => {
                                                       if (typeof ssnFlagValue === "boolean") {
                                                           setSsnFlag(ssnFlagValue);
                                                       }
                                                               setActionItem(actionItem + 1)
                                                           }} getClientDetail={getClientDetail}
                                                           urlClientId={urlClientId} ssnFlag={ssnFlag}/>
                                    }
                                </TabPanel>
                        }
                        {(role === 4 || role === 12 || (can(user, PermissionTypes.NIW_ACTION_ITEMS))) &&
                                <TabPanel value={value}
                                          index={profileTabs[3]}>
                                    <ActionItemsTab role={role} clientDetail={clientDetail}
                                                    goToDocuments={() => {
                                                        setValue(profileTabs[4]);
                                                        props.updateActionItem(4)
                                                    }}
                                                    setActionItem={() => setActionItem(actionItem + 1)}
                                    />

                                </TabPanel>
                        }
                        <TabPanel value={value} index={profileTabs[4]}>
                            <DocumentsTab role={role} clientDetail={clientDetail} getClientDetail={getClientDetail} ssnFlag={ssnFlag}/>
                        </TabPanel>
                        <TabPanel value={value} index={profileTabs[5]}>
                            <NotesTab role={role} clientDetail={clientDetail}/>
                        </TabPanel>
                        {(role == 1 || role == 2) &&
                                <TabPanel value={value} index={profileTabs[6]}>
                                    <CaseDetailTab clientDetail={clientDetail} ssnFlag={ssnFlag}
                                                       setActionItem={(ssnFlagValue: boolean) => {
                                               if (typeof ssnFlagValue === "boolean") {
                                                   setSsnFlag(ssnFlagValue);
                                               }
                                               setActionItem(actionItem + 1)
                                                       }}
                                                       caseDetailButtonRef={saveCaseDetailButtonRef}
                                        />
                                    </TabPanel>
                            }
                            {
                                    (role === AccountType.Agent) &&
                                    <TabPanel value={value} index={profileTabs[6]}>
                                        <CaseDetailAgentTab setActionItem={() => setActionItem(actionItem + 1)} />
                                    </TabPanel>
                            }
                        {(role == 1 || role == 2) && can(user, PermissionTypes.TICKETS) &&
                                <TabPanel value={value} index={profileTabs[7]}>
                                        <TicketsPanels
                                                currentTickets={currentTickets}
                                                currentTicketsPagination={currentTicketsPagination}
                                                setCurrentTicketsPagination={setCurrentTicketsPagination}

                                                completedTickets={completedTickets}
                                                completedTicketsPagination={completedTicketsPagination}
                                                setCompletedTicketsPagination={setCompletedTicketsPagination}
                                                onResolveTicket={onResolveTicket}
                                                isGoToClientVisible={false}

                                        />
                                    </TabPanel>
                            }
                        </Grid>
                        <Grid item xs={12} md={12} lg={4}>
                            {((adminPermission && (user.role === 1 || user.role === 2)) || role === 8) &&
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={12}>
                                            <AdminActions role={role} clientDetail={clientDetail}
                                                          setActionItem={() => setActionItem(actionItem + 1)}
                                                          adminPermission={adminPermission}
                                                          onTicketCreated={onTicketCreated}
                                                          offerType={offerType} />
                                        </Grid>
                                    </Grid>
                            }
                            {
                                user.role !== AccountType.Carrier &&
                                <ClientSnapshot
                                        refreshDetails={refreshDetails}
                                        caseDetailButtonRef={saveCaseDetailButtonRef}
                                />
                        }
                        {
                                 [AccountType.Admin, AccountType.MasterAdmin].includes(user.role) && policyAtListOne &&
                                <TotalRemainingPayments paymentFrequency={paymentFrequency} />
                            }
                        </Grid>
                    </Grid>
                </div>

    );
}