import * as Yup from "yup";
import numeral from "numeral";
import moment from "moment";
import {ALPHABET_ONLY, ALPHABET_ONLY_REGEX, REQUIRED_FIELD} from "../../../../../constants";

function PersonalDetailValidate (validationObj, keysValidation, isFromClientDetail, minAge, fieldObj9, fieldObj8,
                                 fieldObj2, fieldObj3, offerType, isGiCorpIncomeCond, strategySlug, client, ageSelected, minVal,
    showClientContribution, fieldObj6, fieldObj7, fullTimeSelected, props, values
) {
    validationObj.forEach((field:any) => {
        if (field.name === "email") {
            keysValidation[field.name] = Yup.string().email("Please enter a valid email").required(REQUIRED_FIELD);
        } else if (!isFromClientDetail && (field.name === "cellPhone" || field.name === "workPhoneNumber" || field.name === "homePhone")) {
            keysValidation[field.name] = Yup.string().required(REQUIRED_FIELD);
        } else if (!isFromClientDetail && field.name === "employeeSSN") {
            keysValidation[field.name] = Yup.string().transform((o, v) => v.replace(/\D+/g, "")).length(9, "Enter Valid Number").required(REQUIRED_FIELD);
        }
        if (field.isRequired) {
            if (field.name && (field.name === "dob")) {
                keysValidation[field.name] = Yup.string().test(
                    'Is Dob Valid?',
                    `Participants age must be between ${minAge}-65 - Contact your agent`,
                    (value) => {
                        let age:number = moment().diff(value, "years");
                        return (!((age || age === 0) && (age < minAge || age > 65)));
                    }
                );
            } else if (field.name && (field.name === "occupationId" || field.name === "industryId")) {
                keysValidation[field.name] = field.type && field.type === "number" ? Yup.number().nullable()
                    .required(REQUIRED_FIELD) : Yup.string()
            } else if (field.name === "birthState"){
                keysValidation[field.name] = Yup.string().trim().matches(ALPHABET_ONLY_REGEX, ALPHABET_ONLY).required(REQUIRED_FIELD);
            } else {
                keysValidation[field.name] = field.filedType && field.filedType === "number" ? Yup.number().required(REQUIRED_FIELD) : Yup.string().required(REQUIRED_FIELD).nullable();
            }
        } else {
            if (field.name && (field.name === "age")) {
                keysValidation[field.name] = Yup.number().min(minAge, `Age should be between ${minAge} and 65.`).max(65, `Age should be between ${minAge} and 65.`).typeError(`Age should be between ${minAge} and 65.`);
            } else if(isFromClientDetail && (field.name === "cellPhone" || field.name === "workPhoneNumber")){
                keysValidation[field.name] = Yup.string();
            } else if(isFromClientDetail && (field.name === "homePhone")){
                keysValidation[field.name] = Yup.string().nullable(true);
            } else if(isFromClientDetail && field.name === "employeeSSN") {
                keysValidation[field.name] = Yup.string().transform((o, v) => v.replace(/\D+/g, "")).length(9, "Enter Valid Number");
            } else if (field.name && (field.name === "occupationId" || field.name === "industryId" || field.name === "highRanking" || field.name === "individualHighRanking" || field.name === "workInIndustries")) {
                keysValidation[field.name] = field.type && field.type === "number" ? Yup.number().nullable(true) : Yup.string();
            } else {
                keysValidation[field.name] = field.filedType && field.filedType === "number" ? Yup.number() : Yup.string();
            }
        }
    });

    [...fieldObj9, ...fieldObj8].forEach((field) => {
        if (field.isRequired) {
            keysValidation[field.name] = Yup.string().when("usCitizen", {
                is : false,
                then : Yup.string().required(REQUIRED_FIELD)
            });
            if(field.options && field.options.length > 0){
                field.options.forEach((option) => {
                    if (option.textField) {
                        keysValidation[option.textField.name] = Yup.string().when("usCitizen", {
                            is : false,
                            then : Yup.string().when(field.name,{
                                is : option.value,
                                then : Yup.string().required(REQUIRED_FIELD)
                            })
                        });
                    }
                })
            }
        }
    });

    fieldObj2.forEach((field) => {
        if (field.isRequired) {
            if(field.name && (field.name === "zipCode")){
                keysValidation[field.name] = Yup.string().required(REQUIRED_FIELD).matches(/(^\d{5}$)/, 'Please enter a valid zip code')
            } else if(field.name && (field.name === "state")){
                keysValidation[field.name] = Yup.string().required(REQUIRED_FIELD).transform((o, v) => {
                    if(v && typeof v === 'object'){
                        return v.key;
                    }
                    return v || ""
                });
            } else {
                keysValidation[field.name] = field.filedType && field.filedType === "number" ? Yup.number().required(REQUIRED_FIELD) : Yup.string().required(REQUIRED_FIELD);
            }
        } else {
            if(field.name && (field.name === "zipCode")) {
                keysValidation[field.name] = Yup.string().matches(/(^\d{5}$)/, 'Please enter a valid zip code')
            } else if(field.name && (field.name === "state")){
                keysValidation[field.name] = Yup.string().transform((o, v) => {
                    if(v && typeof v === 'object'){
                        return v.key;
                    }
                    return v || ""
                });
            } else {
                keysValidation[field.name] = field.filedType && field.filedType === "number" ? Yup.number() : Yup.string();
            }
        }
    });

    fieldObj3.forEach((field) => {
        /*if (field.isRequired) {
            if (offerType !== "gi" && isGiCorpIncomeCond && field.name && (field.name === "annualIncome")) {
                keysValidation[field.name] = Yup.number().required("Required Field").transform((o, v) => numeral(v).value()).min(100000, `Minimum value is ${numeral(100000).format("$0,0")}`);
            } else if (offerType !== "gi" && isGiCorpIncomeCond && field.name === "annualIncome1") {
                keysValidation[field.name] = Yup.number().required("Required Field").transform((o, v) => numeral(v).value()).min(100000, `Minimum value is ${numeral(100000).format("$0,0")}`);
            } else if (offerType !== "gi" && isGiCorpIncomeCond && field.name === "annualIncome2") {
                keysValidation[field.name] = Yup.number().required("Required Field").transform((o, v) => numeral(v).value()).min(100000, `Minimum value is ${numeral(100000).format("$0,0")}`);
            } else if (field.name === "householdAnnualIncome") {
                keysValidation[field.name] = Yup.number().required("Required Field").transform((o, v) => numeral(v).value()).min(100000, `Minimum value is ${numeral(100000).format("$0,0")}`);
            } else {
                keysValidation[field.name] = Yup.string().required("Required Field");
            }
        } else {
            if (offerType !== "gi" && isGiCorpIncomeCond && field.name && (field.name === "annualIncome")) {
                keysValidation[field.name] = Yup.number().nullable(true).transform((o, v) => {
                    return numeral(v).value() || null;
                }).min(100000, `Minimum value is ${numeral(100000).format("$0,0")}`);
            } else if (offerType !== "gi" && isGiCorpIncomeCond && field.name === "annualIncome1") {
                keysValidation[field.name] = Yup.number().nullable(true).transform((o, v) => {
                    return numeral(v).value() || null;
                }).min(100000, `Minimum value is ${numeral(100000).format("$0,0")}`);
            } else if (offerType !== "gi" && isGiCorpIncomeCond && field.name === "annualIncome2") {
                keysValidation[field.name] = Yup.number().nullable(true).transform((o, v) => {
                    return numeral(v).value() || null;
                }).min(100000, `Minimum value is ${numeral(100000).format("$0,0")}`);
            }
        }*/
    });

    fieldObj6.forEach((field) => {
        if(strategySlug === "cashstream"){
            return false;
        }
        if (field.isRequired) {
            keysValidation[field.name] = field.filedType && field.filedType === "number" ? Yup.number().required(REQUIRED_FIELD) : Yup.string().required(REQUIRED_FIELD);
        } else {
            if (field.name && (field.name === "contributionAmount") && isFromClientDetail && ageSelected && showClientContribution) {
                let employerContributionValue:any = 0;
                if (client && client.employerContribution && numeral(client.employerContribution).value() > 0 && props.clientDetail && props.clientDetail.role === 6) {
                    employerContributionValue = client.employerContribution;
                }
                if(employerContributionValue > 0) {
                    keysValidation[field.name] = Yup.number().transform((o, v) => {
                        return (v && parseInt(v.replace(/,/g, ""))) || 0;
                    }).min(numeral(minVal).value() - numeral(employerContributionValue).value(), `Minimum contribution is ${numeral(minVal).format("$0,0")}`);
                } else{
                    keysValidation[field.name] = Yup.number().transform((o, v) => {
                        return (v && parseInt(v.replace(/,/g, ""))) || 0;
                    }).min(minVal, `Minimum contribution is ${numeral(minVal).format("$0,0")}`);
                }
            } else {
                keysValidation[field.name] = field.filedType && field.filedType === "number" ? Yup.number() : Yup.string();
            }
        }
    });

    fieldObj7.forEach((field) => {
        if (field.isRequired) {
            if (field.name && (field.name === "proofExpiryMonth" || field.name === "proofExpiryYear")) {
                if (field.name === "proofExpiryMonth") {
                    keysValidation[field.name] = Yup.number().min(1, "Please select expiry month");
                } else if (field.name === "proofExpiryYear") {
                    keysValidation[field.name] = Yup.number().min(1, "Please select expiry year");
                }
            } else if (field.name === "employeeIdState"){
                keysValidation[field.name] = Yup.string().trim().matches(ALPHABET_ONLY_REGEX, ALPHABET_ONLY).required(REQUIRED_FIELD);
            } else {
                keysValidation[field.name] = field.filedType && field.filedType === "number" ? Yup.number().required(REQUIRED_FIELD) : Yup.string().required(REQUIRED_FIELD);
            }
        }
    });
    return keysValidation;
}
export default PersonalDetailValidate;