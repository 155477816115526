import React, {useEffect, useState} from "react";
import {useDropzone} from "react-dropzone";
import url from "url";
import api, {Response} from "superagent";
import {Button, Grid, LinearProgress, Tooltip, Typography,} from "@material-ui/core";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {CloudUpload, Edit} from "@material-ui/icons";
import {useActions} from "../../actions";
import * as Alert from "../../actions/alert";
import * as Aws from "../../actions/aws";
import {getContentType, getRandomString, parseEmptyString} from "../../utils/commonUtils";
import { FORBIDDEN_STATUS_CODE, INVALID_FILE_TYPE_ERROR, SnackBarSeverity } from "../../constants";
import {SignedUrlQuery} from "../../model/aws";
import * as Actions from "../../actions/account";
import CircularProgress from "@material-ui/core/CircularProgress";
const reader = new FileReader();

const useStyles = makeStyles((theme: Theme) => ({
    gridList: {
        flexWrap: "nowrap",
        transform: "translateZ(0)",
    },
    title: {
        color: theme.palette.primary.light,
    },
    titleBar: {
        background:
                "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
    },
    dashed: {
        borderStyle: "dashed",
        color: "#cccccc",
        backgroundColor: "#eeeeee",
        // height: '50px'
    },
    mOne: {
        margin: theme.spacing(1),
    },
    mLeftOne: {
        marginLeft: theme.spacing(1),
    },
    mRightTwo: {
        marginRight: theme.spacing(2),
    },
    barColorPrimary: {
        backgroundColor: "#179917",
    },
    colorPrimary: {
        backgroundColor: "#cfcfcf",
    },
    root: {
        height: "5px",
    },
}));

export default function FileUploader({
                                         dirName,
                                         dirType,
                                     onFinish,
                                     fileType,
                                     width,
                                     height,
                                     getFileName,
                                     getOrigionalFileName,
                                         getFileSize,
                                     iconOnly = false,
                                     isSecuredSignedUrl = false,
                                     isCsv = true,
                                     subDirName
                                     }: any) {
    const [files, setFiles] = useState([]);
    const [validate, setValidate] = useState(false);
    const [uploadingPercentage, setUploadingPercentage] = useState(0);
    const [fileUrl, setFileUrl] = useState("");

    const classes = useStyles();
    const alertActions = useActions(Alert);
    const awsActions = useActions(Aws);
    const [readCsvData, setReadCsvData] = useState<boolean>(false);
    const {getRootProps, getInputProps} = useDropzone({
        accept: fileType || "image/*",
        onDropRejected: () => alertActions.setAlert(INVALID_FILE_TYPE_ERROR, SnackBarSeverity.Warning, FORBIDDEN_STATUS_CODE),
        onDrop: acceptedFiles => {
            acceptedFiles.map(async (file) => {
                const preview: string = URL.createObjectURL(file);
                if (width && height) {
                    const i = new Image();
                    i.onload = () => {
                        let reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = async () => {
                            if (i.width > width || i.height > height) {
                                setValidate(true);
                            } else {
                                setValidate(false);
                                setFiles(file as any);
                                if (getFileSize) {
                                    getFileSize(file.size);
                                }
                                await handleSendToS3(file as any);
                            }
                        };
                    };
                    i.src = preview;
                } else {
                    setFiles(file as any);
                    if (getFileSize) {
                        getFileSize(file.size);
                    }
                    await handleSendToS3(file as any);
                }
                return Object.assign(file, {preview});
            });
        },
    });

    interface fileProps {
        name: any,
        preview: any
    }

    interface awsResp extends Response {
        req?: {
            url: string
        }
    }

    const beforeFinish = async (file: File, queryObj: SignedUrlQuery) => {
        const res = await awsActions.validateUploadedFile(getContentType(file.type), isSecuredSignedUrl, queryObj);
        if (!res) {
            // setValidate(true)
            throw "Corrupted file";
        }
    };

    const handleSendToS3 = async (file: any) => {
        const fileName = file.name;
        const queryObj = {
            objectName: (isSecuredSignedUrl && isCsv) ? getRandomString() + ".csv" : file.name,
            contentType: file.type || "text/csv",
            dirName: dirName,
            dirType: dirType,
        };
        const { payload: { signedUrl, filename } } = await awsActions.SignedUrlGet(queryObj, isSecuredSignedUrl);
        api
                .put(signedUrl)
                .send(file)
                .on("progress", (e) => {
                    setUploadingPercentage(Number(e.percent));
                if(Number(e.percent) === 100) {
                    setTimeout(function () {
                        setUploadingPercentage(0)
                    }, 500);
                }
                })
                .then(async (res: awsResp) => {
                    const parseUrl = parseEmptyString(res.req && res.req.url);

                    const parsedUrl = url.parse(parseUrl, true);
                    const fileUrl = `${parsedUrl.protocol}//${parsedUrl.hostname}${parsedUrl.pathname}`;

                    await beforeFinish(file, queryObj);

                    setFileUrl(fileUrl);
                    if (onFinish) {
                        onFinish(fileUrl, filename);
                    }
                    if (getFileName) {
                        getFileName(dirName + "/" + filename);
                    }
                    if (getOrigionalFileName) {
                        getOrigionalFileName(filename);
                    }
                })
                .catch(console.error);
    };

    const uploadingBar = (uploadingPercentage) => (<>
            <LinearProgress
                    classes={{
                        colorPrimary: classes.colorPrimary,
                        barColorPrimary: classes.barColorPrimary,
                        root: classes.root,
                    }}
                    variant="determinate"
                    value={uploadingPercentage}
        /> <span className="floatRight f14 ml5"> {Math.round(uploadingPercentage)}%</span>
        </>
        );

    useEffect(() => () => {
        files.length > 0 && files.forEach((file: fileProps) => URL.revokeObjectURL(file.preview));
    }, [files.length]);

    return (
            <Grid container justify="center">
                <Grid item xs={12} sm={12} md={12} {...getRootProps()}>
                    <div className={iconOnly ? "editIcon mt15" : "fileUpload mt15 "}>
                        {
                            (!iconOnly && fileUrl && !validate) && ((!width && !height) &&
                                    <Edit className="floatLeft"/>)
                        }
                        <input {...getInputProps()} />
                        <div className="linearProgress">
					<span className="linearProgressInput">
						{
                            uploadingPercentage > 0 && uploadingPercentage <= 100 &&
                            uploadingBar(uploadingPercentage)
                        }
					</span>

                        </div>
                        <div className="textCenter DocumentUpload">

                            {
                                iconOnly
                                        ? <Tooltip title="Edit">
                                            <Edit className={classes.mRightTwo}/>
                                        </Tooltip>
                                        : <React.Fragment>
                                            <div className="floatLeft">
                                                <CloudUpload className="floatLeft"/>
                                                <Typography align="left" className="floatLeft te">Drag and Drop File<br/> Here
                                                    to Upload</Typography>
                                            </div>
                                            <div className="floatRight">
                                                <Button
                                                        disabled
                                                        className="mt20"
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                >
                                                    Browse Files
                                                </Button>
                                            </div>
                                            {
                                                validate &&
                                                <strong className="w100 floatLeft" style={{ color: "red" }}>Please check Width
                                                    or Height of File</strong>
                                            }
                                        </React.Fragment>
                            }
                        </div>
                    </div>
                    {
                        readCsvData &&
                        <CircularProgress className="circularProgress" disableShrink/>
                    }
                </Grid>
            </Grid>
    );
}