import * as React from "react";
import { useState } from "react";
import { useFormikContext } from "formik";
import { Grid, Button, Tooltip } from "@material-ui/core";
import { Formik } from "formik";
import { Label } from "../../../formInputs";
import { ErrorCommon, NumberTextFieldWithCb, SelectField, TextFieldCommon } from "../../../formikFormInputs";
import { AllianzLifeCarrierDetail, genderDropDown } from "../../../../constants";
import { noop } from "lodash";
import { useActions } from "../../../../actions";
import * as Actions from "../../../../actions/estimation";
import GroupEstimationContact from "./GroupEstimationContact";
import { Dialog as GenericDialog, GenericDialogProps } from "../../../dialog";
import { Viewer } from "../../../index";

const ANNUALLY = "Annual";

const getNearestValue = (from)=>{
    let minimumValue = 22000;
    while (minimumValue < Number(from)){
        minimumValue += 2000
    }
    return minimumValue
}
function createDropDown(from) {
    const dropDown: any[] = [];
   let minimumValue = getNearestValue(from)
    for (let i = minimumValue; i <= 100000; i += 2000) {
        dropDown.push({
            id: i,
            value: i,
            name: i,
        });
    }
    return dropDown;
}
function ageDropDown(): any[] {
    const dropDown: any[] = [];
    for (let i = 18; i <= 65; i += 1) {
        dropDown.push({
            id: i,
            value: i,
            name: i,
        });
    }
    return dropDown;

}


export default function GroupEstimationForm(props: { onSubmit?: Function, isAtlanticHealth?:boolean }) {
    const { onSubmit, isAtlanticHealth } = props
    const estimationActions = useActions(Actions);
    const [responseData, setResponseData] = React.useState<any>(false);
    const [showEr, setShowEr] = React.useState(false);
    const ages = Array.from({ length: 65 - 18 + 1 }, (_, index) => index + 18);
    const [minimumContribution , setMinimumContribution ] = React.useState(22000);
    const initialValues = {
        issueAge: "45",
        gender: 1,
        contribution: 22000,
        contributionFrequency: ANNUALLY,
        carrier: "NLG",
    };
    const getQuickEstimates = async (values, setSubmitting) => {
        
        let estimationValues:any = {
            issueage: values.issueAge,
            contribution: values.contribution,
            gender: values.gender === 1 ? "Male" : "Female",
        };
        if (isAtlanticHealth){
            estimationValues.groupId = "atlantic"
        }
        setSubmitting(true)
        const response = await estimationActions.getQuickEstimationPublic(estimationValues);
        setResponseData(response);
        setTimeout(() => {
            if (response) {
                setSubmitting(false);
            }
        }, 2000);
    };

    const validate = ((value: any) => {
        let errors: {
            issueAge?:string
        } = {};
        if (!value.issueAge){
            errors.issueAge = "Required";
        }
        return errors;
    });

    const grpInfo = [
        {
            name: "issueAge",
            label: <Label label={"Age"} required={true}/>,
            isRequired: true,
            placeholder: "Age",
            id: "issueAge",
            variant: "outlined",
            className: "SelectFieldWidth",
            component: SelectField,
            options: ageDropDown(),
        },
        {
            name: "gender",
            label: <Label label={"Select Gender"} required={true}/>,
            isRequired: false,
            component: SelectField,
            id: "gender",
            className: "SelectFieldWidth",
            variant: "outlined",
            options: genderDropDown,
            helperFieldText: "",

        },
        {
            component: SelectField,
            disabled: false,
            label: <Label label={'Annual Contribution'} required />,
            name: "contribution",
            id: "contribution",
            onSelectChange: noop,
            options: createDropDown(minimumContribution),
        }
        ];

    const dialogProps: GenericDialogProps = {
        'aria-labelledby': "Estimation-Results-dialog-title",
        fullWidth: true,
        maxWidth: "md",
        content: (
                <>
                    {
                            (responseData) &&
                            <>
                                <Button
                                    type="submit"
                                    size="small"
                                    variant="outlined"
                                    color="primary"
                                    className="mb15 floatLeft mt20"
                                    onClick={() => setShowEr(false)}
                                >Re Calculate
                                </Button>
                                <Button
                                    type="submit"
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    className="mb15 floatRight mt20"
                                    onClick={() => onSubmit && onSubmit(false)}
                                >Create Account</Button>

                                <GroupEstimationContact responseData={responseData}
                                                        lifeTimeDeathBenefitSeeking={false} />
                               <Button
                                        type="submit"
                                        size="small"
                                        variant="outlined"
                                        color="primary"
                                        className="mb15 floatLeft mt20"
                                        onClick={() => setShowEr(false)}
                                >Re Calculate
                               </Button>
                                <Button
                                        type="submit"
                                        size="small"
                                        variant="contained"
                                        color="primary"
                                        className="mb15 floatRight mt20"
                                        onClick={() => onSubmit && onSubmit(false)}
                                >Create Account</Button>

                            </>
                    }
                </>

        ),
        dividers: true,
        onClose: () => setShowEr(false),
        open: showEr,
        title: 'View Estimation Results'
    };



    return (
            <React.Fragment>
                <Formik
                        validateOnMount={true}
                        initialValues={initialValues}
                        enableReinitialize={true}
                        initialTouched={{
                            issueAge:true
                        }}
                        validate={values => validate(values)}
                        validateOnChange={true}
                        onSubmit={(values, { setSubmitting }) => {
                            onSubmit && onSubmit();
                        }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          setSubmitting,
                          handleSubmit,
                      }) => {
                        return (
                                <form className="floatLeft w100" onSubmit={handleSubmit}>
                                    <FormActions setMinimumContribution={setMinimumContribution}/>
                                    <Grid container spacing={2}>
                                        {grpInfo.map((field: any, index) => {
                                            return (
                                                    <Grid item xs={12} className="mb15">
                                                        <TextFieldCommon
                                                                {...field}
                                                                values={values}
                                                                onChange={handleChange}
                                                        />
                                                        <ErrorCommon errors={errors}
                                                                     name={field.name}
                                                                     touched={touched} />
                                                    </Grid>
                                            );
                                        })}
                                        <Grid item xs={12}>
                                          <Button
                                                    type="submit"
                                                    size="small"
                                                    variant="contained"
                                                    color="primary"
                                                    className="mb15 floatRight"
                                            >Create Account</Button>


                                            <Button
                                                    type="button"
                                                    onClick={()=> {
                                                        if(!Object.keys(errors).length){
                                                            setShowEr(true);
                                                            return getQuickEstimates(values,setSubmitting);
                                                        }
                                                    }}
                                                    size="small"
                                                    variant="outlined"
                                                    color="primary"
                                                    className="mb15 floatLeft floatRightSM"
                                            >
                                                Calculate
                                            </Button>

                                        </Grid>
                                    </Grid>

                                        {
                                                showEr &&
                                                <GenericDialog {...dialogProps} />
                                        }


                                </form>
                        );
                    }
                    }

                </Formik>




            </React.Fragment>
    );
}

const FormActions = ({setMinimumContribution}:any) =>{
    const {values, setFieldValue}= useFormikContext<any>()
    const estimationActions = useActions(Actions);
    const getMinConForAnnualContribution = async (values: any) => {
        let query = {
            rateClass: "Standard_NT",
            carrierId: AllianzLifeCarrierDetail.value,
            gender: values["gender"],
            issueAge: values["issueAge"]
        };
        let minContResp = await estimationActions.getMinimumContributionPublic(query);
        if(minContResp.minimumContribution){
            setMinimumContribution(minContResp.minimumContribution);
            setFieldValue('contribution',getNearestValue(minContResp.minimumContribution),true)
        }
    }
    React.useEffect(()=>{
        getMinConForAnnualContribution(values)
    },[values["gender"], values["issueAge"]])
    return <></>

}