import * as React from "react";
import {List, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import {
    AccountBox as AccountBoxIcon,
    DateRange as DateRangeIcon,
    FormatListBulleted as FormatListBulletedIcon,
    Notifications,
    People as PeopleIcon,
    Restore,
} from "@material-ui/icons";

import LinkIcon from "@material-ui/icons/Link";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import {useSelector} from "react-redux";
import {RootState} from "../../reducers";
import {history} from "../../configureStore";
import {AccountTypeByName, PermissionTypes} from "../../constants";
import BadgeOverlap from "../BadgeOverlap";
import AddToQueueIcon from "@material-ui/icons/AddToQueue";
import ReceiptIcon from "@material-ui/icons/Receipt";
import ContactPhoneIcon from "@material-ui/icons/ContactPhone";
import OndemandVideoIcon from "@material-ui/icons/OndemandVideo";
import CommentIcon from "@material-ui/icons/Comment";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import PlacesIcon from '@material-ui/icons/Place';

import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';
import ListItemComponent from './ListItemComponent'
export const isAdmin = user => user.role === 1 || user.role === 2;

export const can = (user, permissionTypeId) => {
	if(!isAdmin(user))
		return true;

	if(user.role === 1)
		return true;

	const permissions = user.permissions || [];
	const permission = permissions.find(x => x.permissionType.id === permissionTypeId);
	return user.role === 2 && permission && permission.isEnabled;
}

function AdminNavigation(props: any) {
	const { handleNavClick } = props;
	const [active, setActive] = React.useState<string>(
		history.location.pathname
	);
	const { user } = useSelector((state: RootState) => state.auth);
	
	let isShowNotifyTab = false;
	if (
		user &&
		user.role &&
		(user.role === AccountTypeByName.master_admin ||
			user.role === AccountTypeByName.admin)
	) {
		isShowNotifyTab = true;
	}
	let isPendingItemsCount: boolean = !!(user && user.pendingItemsCount && user.pendingItemsCount > 0);
	let isLeadsCount: boolean = !!(user && user.leadsCount && user.leadsCount > 0);
    let isAgentApproval: boolean = !!(user && user.agentApproval && user.agentApproval > 0);
    let isCurrentTicketCount:boolean = !!(user && user.currentTicketCount && user.currentTicketCount > 0);
	return (
		<React.Fragment>
			<List className="mt20 mb20">
                <ListItemComponent
                        className={
                            active === "/accounts" || active === "accounts"
                                    ? "active"
                                    : ""
                        }
                        url="/accounts" handleNavClick={handleNavClick}
                        setActive={setActive}
                        listItemText={user.role === 2 && !can(user, PermissionTypes.ACCOUNT_MANAGEMENT) ?
                                "ilia Account Management" : "Account Management"}>
                    {<PeopleIcon/>}
                </ListItemComponent>
                {
                        can(user, PermissionTypes.CARRIER_REQUESTS) &&
                        <ListItemComponent className={active === "/carrierRequests" ? "active" : ""}
                                           handleNavClick={handleNavClick} listItemText="Carrier Requests"
                                           setActive={setActive} url="/carrierRequests">{<PlaylistAddCheckIcon />}</ListItemComponent>
                }
                {isShowNotifyTab && (
                        <ListItemComponent className={active === "/admin/notifications" ? "active" : ""}
                                           handleNavClick={handleNavClick} listItemText="Notifications"
                                           setActive={setActive}
                                           url="/admin/notifications">{<Notifications/>}</ListItemComponent>
                )}
				{
					can(user, PermissionTypes.TRANCHE_MANAGEMENT) &&
                        <ListItemComponent className={active === "/tranche" ? "active" : ""}
                                           handleNavClick={handleNavClick} listItemText="Tranche Management"
                                           setActive={setActive} url="/tranche">
                            {<LocalAtmIcon />}
                        </ListItemComponent>
				}
				<ListItem
					className={active === "/document" ? "active" : ""}
					button
					onClick={() => {
						setActive("/document");
						history.push("/document");
						handleNavClick();
					}}
				>
					<ListItemIcon>
						<AddToQueueIcon />
					</ListItemIcon>
					<ListItemText primary="Document Management" />
				</ListItem>
				<ListItem
					className={active === "/payments" ? "active" : ""}
					button
					onClick={() => {
						setActive("/payments");
						history.push("/payments");
						handleNavClick();
					}}
				>
					<ListItemIcon>
						<AddToQueueIcon />
					</ListItemIcon>
					<ListItemText primary="Payments" />
				</ListItem>
				{
					can(user, PermissionTypes.ESTIMATOR_MANAGEMENT) &&
                        <ListItemComponent className={active === "/estimator" ? "active" : ""}
                                           handleNavClick={handleNavClick} listItemText="Estimator Management"
                                           setActive={setActive} url="/estimator">
                            {<AddToQueueIcon />}
                        </ListItemComponent>
				}
				{
					can(user, PermissionTypes.STRATEGY_MANAGEMENT) &&
                        <ListItemComponent className={active === "/strategies" ? "active" : ""}
                                           handleNavClick={handleNavClick} listItemText="ilia Product Type Management" setActive={setActive} url="/strategies">
                            {<FormatListBulletedIcon />}
                        </ListItemComponent>
				}
				{can(user, PermissionTypes.NIW_ACTION_ITEMS) && (
                        <ListItemComponent className={active === "/action-items" ? "active" : ""}
                                           handleNavClick={handleNavClick} listItemText="NIW Action Items"
                                           setActive={setActive} url="/action-items" isPendingItemsCount={isPendingItemsCount}>
                            {<ReceiptIcon />}
                        </ListItemComponent>
				)}
                <ListItem
                        className={
                            ""
                        }
                        button
                        onClick={() => {
                            window.open("https://www.niwmarketing.com/", "_blank")
                            return;
                        }}
                >
                    <ListItemIcon>
                        <DateRangeIcon />
                    </ListItemIcon>
                    <ListItemText primary="Events & Webinars" />
                </ListItem>
<ListItemComponent
                        className={
                            active === "/admins/ILIATrainingPage" ? "active" : ""
                        }
                        handleNavClick={handleNavClick}
                        listItemText="ILIA Training"
                        setActive={setActive}
                        url="/admins/ILIATrainingPage"
                >
                    {<AccountBoxIcon /> }
                </ListItemComponent>
                <ListItemComponent
                        className={
                            active === "/admins/marketing-material" ? "active" : ""
                        }
                        handleNavClick={handleNavClick}
                        listItemText="Marketing Materials"
                        setActive={setActive}
                        url="/admins/marketing-material"
                >
                    {<OndemandVideoIcon/>}
                </ListItemComponent>

                <ListItemComponent className={active === "/admins/news-social" ? "active" : ""}
                                   handleNavClick={handleNavClick} listItemText="News & Social Posts"
                                   setActive={setActive} url="/admins/news-social">
                    {<CommentIcon />}
                </ListItemComponent>
				{
					can(user, PermissionTypes.ADMIN_ACTIVITY_LOG) &&
                        <ListItemComponent className={active === "/admins/activitylogs" ? "active" : ""}
                                           handleNavClick={handleNavClick} listItemText="Admin Activity logs"
                                           setActive={setActive} url="/admins/activitylogs">
                            {<Restore />}
                        </ListItemComponent>
				}
				{
					can(user, PermissionTypes.LEAD_MANAGEMENT) &&
                        <ListItemComponent className={active === "/lead-management" ? "active" : ""}
                                           handleNavClick={handleNavClick} listItemText="Lead Management"
                                           setActive={setActive} url="/lead-management" isLeadsCount={isLeadsCount}>
                            {<ContactPhoneIcon />}
                        </ListItemComponent>
				}
				{
					can(user, PermissionTypes.LINK_REPORTING) &&
                        <ListItemComponent className={active === "/agent-link-reporting" ? "active" : ""}
                                           handleNavClick={handleNavClick} listItemText="Link Reporting"
                                           setActive={setActive} url="/agent-link-reporting">
                            {<LinkIcon/>}
                        </ListItemComponent>
				}
                <ListItemComponent className={active === "/marketing-emails" ? "active" : ""}
                                   handleNavClick={handleNavClick} listItemText="Marketing Emails"
                                   setActive={setActive} url="/marketing-emails">
                    {<ContactPhoneIcon />}
                </ListItemComponent>
				{
					can(user, PermissionTypes.TICKETS) &&
                        <ListItemComponent className={active === "/ilia-tickets" ? "active" : ""}
                                           handleNavClick={handleNavClick} listItemText="ilia Tickets"
                                           setActive={setActive} url="/ilia-tickets" isCurrentTicketCount={isCurrentTicketCount}>
                            {<ConfirmationNumberIcon />}
                        </ListItemComponent>
				}
                {
                    can(user, PermissionTypes.BLACK_LIST) &&
                    <ListItemComponent className={active === "/blacklist" ? "active" : ""}
                                       handleNavClick={handleNavClick} listItemText="Blacklist"
                                       setActive={setActive} url="/blacklist">
                        {<PersonAddDisabledIcon/>}
                    </ListItemComponent>
                }
				<ListItem
					className={active === "/approved-agents" ? "active" : ""}
					button
					onClick={() => {
						setActive("/approved-agents");
						history.push("/approved-agents");
						handleNavClick();
					}}
				>
					<ListItemIcon>
						<LockOpenIcon />
					</ListItemIcon>
					<ListItemText primary="Agent Approvals" />
                    {isAgentApproval && <BadgeOverlap />}
				</ListItem>
				
			     {can(user, PermissionTypes.POWER_BI_REPORTS) &&
		        	<ListItem
					className={active === "/reports" ? "active" : ""}
					button
					onClick={() => {
						setActive("/reports");
						history.push("/reports");
						handleNavClick();
					}}
                >
                    <ListItemIcon>
                        <ReceiptIcon />
                    </ListItemIcon>
                    <ListItemText primary="Reporting" />
                </ListItem>
                }
                <ListItem
                        className={active === "/tranche-dates" ? "active" : ""}
                        button
                        onClick={() => {
                            setActive("/tranche-dates");
                            history.push("/tranche-dates");
                            handleNavClick();
                        }}
                >
                    <ListItemIcon>
                        <LockOpenIcon />
                    </ListItemIcon>
                    <ListItemText primary="Tranche Dates" />
                </ListItem>
                <ListItem
                        className={active === "/import-ghost-client" ? "active" : ""}
                        button
                        onClick={() => {
                            setActive("/import-ghost-client");
                            history.push("/import-ghost-client");
                            handleNavClick();
                        }}
                >
                    <ListItemIcon>
                        <LockOpenIcon />
                    </ListItemIcon>
                    <ListItemText primary="Import Ghost Client" />
                </ListItem>
			</List>
		</React.Fragment>
	);
}

export default AdminNavigation;
