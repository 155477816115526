import * as React from "react";
import { ChangeEvent, ReactNode, useState } from "react";
import { connect, useSelector } from "react-redux";
import { parse } from 'json2csv';
import moment from 'moment';
import {
    Card,
    CardContent,
    Checkbox,
    FormControl,
    Grid,
    InputLabel,
    ListItemText,
    ListSubheader,
    MenuItem,
    Select,
    TextFieldProps,
    Typography,
} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {useActions} from "../../actions";
import * as TrancheActions from "../../actions/tranche";
import * as AccountActions from "../../actions/account";
import * as CarrierActions from "../../actions/carrier";
import * as ClientActions from "../../actions/client";
import {AccountStatusArr, AccountType} from "../../model";
import {RootState} from "../../reducers";
import {
    AccountTypeByFilter,
    AccountTypeByName,
    accountTypeDropDown,
    ADMIN_TYPES,
    agentTypeDropDown,
    allOption,
    defaultRowsPerPage,
    imoClassDropDown,
    imoRegionDropDown,
    IrrStatus,
} from "../../constants";
import { Button as MuiButton, Link, TableCell, TableRow } from "@material-ui/core";
import {
    AdminTable,
    AgentTable,
    BankTable,
    CarrierTable,
    GroupLinkTable,
    IndividualTable,
    MultiLifeTable,
    Pagination,
    TrusteeTable,
} from "../../components/table";
import {AccountSortList} from "../../model/account";
import {CircularLoading, InpAdornment, Search, SearchProgress} from "../../components/Common";
import {Button, DeleteModel} from "../../components/formInputs";
import AllParticipantTable from "../../components/table/AllParticipantTable";
import ClientListTable from "../../components/table/ClientListTable";
import Stats from "../../components/Stats";
import useQueryString from "../../hooks/useQueryString";
import {checkIfbigLabelInMobile, downloadAsCSV, ucFirst} from "../../utils/commonUtils";
import ImoTab, {imoTabs} from "../imo/ImoTab";
import TableWrap from "../../components/TableWrap/TableWrap";
import AggregatorTable from "../../components/table/AggregatorTable";
import * as ApprovedAgentActions from "../../actions/approvedAgents";
import { tablesCsvMapping } from "../../utils/TablesHelper";

type StatusTypesProps = {
    value: string[]
    onChange(newValue: string[]): void
    setOpenTickets?: Function
    openTickets?: boolean,
    carrier?: boolean,
    setCarrier?: Function
}


const filterStatusTypes = {
    "Invitations": ["0", "1", "2"],
    "Enrollment": ["99", "4", "15", "11", "12", "13", "16", "17", "18", "19", "20", "21", "22", "27", "25"],
    "Tickets": [{ label: "Open Tickets", value: true }],
    "Carrier": [{ label: "Allianz Life (Not ALZ App offerType)", value: true }]
};

const participantTypes = [
    "Individual",
    "Group",
    "Ghost",
    "Clientlist"
];
type callingFromType = "parentPortal" | "dropdown" | "navigation"
type AccountPropsType = {
    callingFrom: callingFromType,
    listType : string,
    history : any,
    defaultBasePath?: "parentPortal" | "accounts",
    accountList?: any,
    role: string
    setTitle : Function,
    carriers?: any,
    auth?: any,
    loadingAccounts?: any,
    approvedImoList?: any,
}
const defaultFilter = (callingFrom : callingFromType) =>{
    switch (callingFrom){
        case "parentPortal":
            return 5;
        default:
            return 2
    }
}

function AccountList(props: AccountPropsType) {
    const classes = useStyles();
    const adminTypeDropdown = [
        {
            id: 0,
            name: "Select Account",
        },
        ...ADMIN_TYPES,
    ];
    const { accountList, approvedImoList, auth, carriers, loadingAccounts, defaultBasePath = "accounts" } = props;
    const trancheActions = useActions(TrancheActions);
    const accountActions = useActions(AccountActions);
    const carrierActions = useActions(CarrierActions);
    const clientActions = useActions(ClientActions);
    const [page, setPage] = useQueryString("page", 0);
    const [rowsPerPage, setRowsPerPage] = useQueryString("rowsPerPage", defaultRowsPerPage);
    const [search, setSearch] = useQueryString("search", "");
    const [searching, setSearching] = React.useState(false);
    const { user: { id : userid, accessType , demoClientId } } = useSelector((state: RootState) => state.auth)
    const isClientDemoAccess = (accessType === "client" && demoClientId);
    const [filter, setFilter] = React.useState(2);
    const [orderBy, setOrderBy] = useQueryString("orderBy", "createdAt");
    const [order, setOrder] = useQueryString("order", "desc");
    const [id, setId] = React.useState("");
    const [refresh, setRefresh] = React.useState(0);
    const [statusTypes, setStatusTypes] = React.useState<string[]>([]);
    const [imoCarrierFilter, setImoCarrierFilter] = React.useState<any>("All Carriers");
    const [tranches, setTranches] = React.useState<string[]>([]);
    const [trancheArr, setTrancheArr] = React.useState<string[]>([]); // selected tranche arr (multiple selected arr)
    const [adminType, setAdminType] = React.useState<string>("Select Account");
    const [openTickets, setOpenTickets] = React.useState<boolean>(false);
    const [carrier, setCarrier] = React.useState<boolean>(false);
    const [agentType, setAgentType] = useQueryString("agentType", "All");
    const [imoType, setImoType] = React.useState<any>(allOption.value);
    const [region, setRegion] = React.useState<any>(allOption.value);
    const [imoTab, setImoTab] = React.useState(imoTabs[0]);
    const [participantTypeArr, setParticipantTypeArr] = React.useState<string[]>([]);
    const profileInfo = useSelector((state: RootState) => state.profile);

    const basePath: string = defaultBasePath || "accounts";
    const setTitle = props.setTitle;

    const getTranches = async () => {
        const response: any = await trancheActions.getAllTranches();
        let trancheArr: string[] = [];
        response && response.length > 0  && response.forEach((item)=>{
            trancheArr.push(item.trancheNumber);
        })
        setTranches(trancheArr);
    };
    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof AccountSortList) => {
        const isDesc = orderBy === property && order === "desc";
        setOrder(isDesc ? "asc" : "desc");
        setOrderBy(property);
    };
    const deleteUser = async () => {
        return false;
    };
    const handleReInvite = async (id: string) => {
        await accountActions.resendInvitation(id);
        setRefresh(Math.random());
    };
    function fetchList(queryObj){
        if(props.callingFrom == "parentPortal" && isClientDemoAccess ){
            queryObj.parentId = profileInfo.client && profileInfo.client.userId || "";
        }else if (props.callingFrom == "parentPortal"){
            queryObj.parentId = userid || "";
        }
       return accountActions.listAccounts(queryObj);
    }
    const handleChangeStatus = async (status: number, id: string) => {
        await clientActions.updateClientStatus(Object.assign({}, {
            id,
            status,
        }));
        let queryObj: any = {
            page,
            rowsPerPage,
            searchText: search,
            filter,
            trancheNo: trancheArr.join(","),
            statusTypes: getStatusTypes(),
            agentType,
        };

        if (props.role) {
            queryObj.filter = AccountTypeByName[props.role];
            setFilter(AccountTypeByName[props.role]);
            setTitle(AccountTypeByName[props.role]);
        }
        fetchList(queryObj)

    };
    const getStatusTypes = () => {
        if (statusTypes.findIndex(p => p === "99") >= 0) {
            return statusTypes.concat(filterStatusTypes.Enrollment).join(",");
        }
        return statusTypes.join(",");
    };
    const getDeletedUserName = (id: string, clientList: boolean) => {
        let fullName: any = [];
        clientList ?
                accountList.rows.map((val: any) => (id === val.id) && fullName.push(val.clientListName))
                : accountList.rows.map((val: any) => (id === val.id) && fullName.push(val.firstName + " " + val.lastName));
        return fullName[0];
    };
    const handleRefresh = () => {
        setRefresh(Math.random());
    };
    const updateCarrierTypeDropDown = () => {
        carrierTypeDropDown = [{ id: 0, name: "All Carriers", value: "All Carriers" }];
        if (carriers.data) {
            carriers.data && carriers.data.forEach((c: any) => {
                const pushTo = {
                    id: c.id,
                    value: c.carrierName,
                    name: c.carrierName,
                };
                carrierTypeDropDown.push(pushTo);
            });
        }
        return carrierTypeDropDown;
    };
    const handleTextSearchChange = e => {
        const textSearch = e.target.value;
        if (textSearch.trim().length === 0) {
            setOrderBy("createdAt");
            setOrder("desc");
            setSearch("");
        } else {
            setSearch(textSearch);
        }
        setSearching(true);
        setPage(0);
    };
    const onAccTypeChange = (e) => {
        const val: any = e.target.value;
        setPage(0);
        setFilter(val);
        setStatusTypes([]);
        setTrancheArr([]);
        setParticipantTypeArr([]);
        setSearch("");
        setOrder("desc");
        setOrderBy("createdAt");
        setTitle(val);
        props.history.push("/" + basePath + "/" + AccountTypeByFilter[val]);
    };

    let carrierTypeDropDown: object[] = [];
    let searchPlaceHolder = "Search by FirstName, LastName, Email";

    switch (filter) {
        case 3:
            searchPlaceHolder = "Search by IMO or Name";
            break;
        case 7:
            searchPlaceHolder = "Search by name, Email";
            break;
        case 8:
            searchPlaceHolder = "Search by Group Name, Email";
            break;
        case 13:
            searchPlaceHolder = "Search by Bank Name, FirstName, LastName, Email";
            break;
        case 14:
            searchPlaceHolder = "Search by Client List Name";
            break;
        case 18:
            searchPlaceHolder = "Search by Aggregator Name, FirstName, LastName, Email";
            break;
    }

    const endAdornment = (
            <InpAdornment children={<SearchProgress loading={searching} />} position="end" />
    );
    const searchProps: TextFieldProps = {
        fullWidth: true,
        InputProps: { endAdornment },
        label: checkIfbigLabelInMobile(searchPlaceHolder),
        onChange: handleTextSearchChange,
        value: search,
        variant: "outlined",
    };

    React.useEffect(() => {
        let queryObj: any = { page, rowsPerPage, searchText: search, filter, sortBy: orderBy, order };
        if (props.callingFrom === "navigation" && props.history.location.key) {
            queryObj.sortBy = "createdAt";
            queryObj.page = 0;
            queryObj.order = "desc";
            queryObj.rowsPerPage = defaultRowsPerPage;
            queryObj.filter = AccountTypeByName[props.role];
            setPage(0);
        }
    }, [props.history.location.key]);

    React.useEffect(() => {
        let queryObj: any = {
            page,
            rowsPerPage,
            searchText: search,
            filter,
            sortBy: orderBy,
            order,
            trancheNo: trancheArr.join(","),
            participantType: participantTypeArr.join(","),
            statusTypes: getStatusTypes(),
            agentType,
            imoType,
            region,
        };
        if (props.role) {
            queryObj.filter = AccountTypeByName[props.role];
            setFilter(AccountTypeByName[props.role]);
            setTitle(AccountTypeByName[props.role]);
        }
        if (adminType && AccountTypeByName[props.role] === 2) {
            queryObj.adminType = auth.user && auth.user.role === 2 ? "ilia admin" : adminType;
        }
        if (imoCarrierFilter && AccountTypeByName[props.role] === 3) {
            queryObj.imoCarrierFilter = imoCarrierFilter;
        }
        if (AccountTypeByName[props.role] === 3) {
            carrierActions.getAllCarriers();
            updateCarrierTypeDropDown();
        }
        if (openTickets) {
            queryObj.currentTickets = openTickets;
        }

        if (carrier) {
            queryObj.carrierType = carrier;
        }
        if(props.callingFrom == "parentPortal"){
            queryObj.parentId = userid || "";
        }
        fetchList(queryObj)
    }, [
        page,
        rowsPerPage,
        props.role,
        refresh,
        orderBy,
        order,
        imoCarrierFilter,
        statusTypes,
        trancheArr,
        adminType,
        agentType,
        imoType,
        region,
        participantTypeArr,
        profileInfo.client && profileInfo.client.userId
    ]);

    React.useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            let queryObj: any = {
                page,
                rowsPerPage,
                searchText: search,
                filter,
                sortBy: orderBy,
                order,
                trancheNo: trancheArr.join(","),
                statusTypes: getStatusTypes(),
                agentType,
                imoType,
                region,
                participantType: participantTypeArr.join(","),
            };
            if (props.role) {
                queryObj.filter = AccountTypeByName[props.role];
                setFilter(AccountTypeByName[props.role]);
                setTitle(AccountTypeByName[props.role]);
            }
            if (adminType && AccountTypeByName[props.role] === 2) {
                queryObj.adminType = auth.user && auth.user.role === 2 ? "ilia admin" : adminType;
            }
            if (imoCarrierFilter && AccountTypeByName[props.role] === 3) {
                queryObj.imoCarrierFilter = imoCarrierFilter;
            }
            if (AccountTypeByName[props.role] === 3) {
                carrierActions.getAllCarriers();
                updateCarrierTypeDropDown();
            }
            if (searching) {
                fetchList(queryObj)
                        .then(() => setSearching(false));
            }
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
    }, [search]);

    React.useEffect(() => {
        if (auth && auth.user && auth.user.role <= 2) {
            getTranches();
        }
    }, []);

    const agentTypeOptions = [
        {
            text: "All",
            value: 0,
        },
        ...agentTypeDropDown,
    ];
    
    const approvedAgentActions = useActions(ApprovedAgentActions);
    const _getAgentRegistrationRequests = async (status) => {
        await approvedAgentActions.getImoRegistrationRequests({
            page, rowsPerPage, status: status, searchText: search, region, carrier: imoCarrierFilter
        });
    };
    const exportCSV = () =>{
        
        let fields: { label: string, value: any }[] | undefined = tablesCsvMapping(filter, "accountList");
        if (!tablesCsvMapping(filter, "accountList")) {
            return
        }
        const [fileName, csvRes] = ['Account-list', parse(accountList.rows, { fields })];
        downloadAsCSV(fileName, csvRes); 
    }
    React.useEffect(() => {
        setPage(0);
        setRowsPerPage(defaultRowsPerPage);
        if (imoTab === imoTabs[1]) { // 1 = second tab, pending IRR
            _getAgentRegistrationRequests(IrrStatus.PENDING).then();
        }
        if (imoTab === imoTabs[2]) { // 2 = third tab, rejected/deleted IRR
            _getAgentRegistrationRequests(IrrStatus.REJECTED).then();
        }
    }, [imoTab]);

    const resetPagination = () => {
        setPage(0);
        setRowsPerPage(defaultRowsPerPage);
    };


    return (
            <React.Fragment>
                <Card className="muiCard mt0 fixFilter">
                    <CardContent className="muiCardContent">
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12} lg={12} xl={3}>
                                <div className={classes.title}>
                                    <Typography
                                            className="floatLeft mt15 colorBlue"
                                            component="strong"
                                            id="tableTitle"
                                            variant="subtitle1"
                                    >
                                        {AccountType[filter || 0]}
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid
                                    item xs={12} md={12} lg={12} xl={9}
                            >
                                <Grid container spacing={2} justify="flex-end">
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={2}>
                                        <FormControl variant="outlined" fullWidth={true}>
                                            <InputLabel htmlFor="account-type" id="account-type" shrink>
                                                Account Type
                                            </InputLabel>
                                               <AccountTypeList callingFrom={props.callingFrom} filter={filter} onChange={onAccTypeChange} />
                                        </FormControl>
                                    </Grid>

                                    {
                                            filter === 3 &&
                                            <ImoFilters
                                                    imoType={imoType}
                                                    setImoType={setImoType}
                                                    region={region}
                                                    setRegion={setRegion}
                                                    tab={imoTab}
                                                    resetPagination={resetPagination}
                                            />
                                    }
                                    {
                                            filter === 3 && imoTab !== imoTabs[1] && imoTab !== imoTabs[2] &&
                                            <Grid item xs={12} sm={12} md={6} lg={2} xl={2}>

                                                <FormControl variant="outlined" fullWidth={true}>
                                                    <InputLabel htmlFor="carriers" shrink>Carriers</InputLabel>
                                                    <Select
                                                            id="carriers"
                                                            className="muiSelect"
                                                            value={imoCarrierFilter}
                                                            name="carrier"
                                                            onChange={e => {
                                                                resetPagination();
                                                                setImoCarrierFilter(e.target.value);
                                                            }}
                                                            fullWidth
                                                    >
                                                        {
                                                                updateCarrierTypeDropDown() && carrierTypeDropDown.length &&
                                                                carrierTypeDropDown.map((o: any) =>
                                                                        <MenuItem key={o.id} value={o.name}>
                                                                            {o.name}
                                                                        </MenuItem>)
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                    }
                                    {
                                            filter === 4 && auth.user && auth.user.role == 1 &&
                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={2}>
                                                <FormControl variant="outlined" fullWidth={true}>
                                                    <InputLabel
                                                            htmlFor="agent-type"
                                                            id="agent-type"
                                                            shrink
                                                    >Type</InputLabel>
                                                    <Select
                                                            className="muiSelect"
                                                            value={agentType}
                                                            name="agentType"
                                                            onChange={e => {
                                                                resetPagination();
                                                                setAgentType(e.target.value as string);
                                                            }}
                                                            fullWidth
                                                    >
                                                        {
                                                            agentTypeOptions.map((o) =>
                                                                    <MenuItem key={o.value} value={o.text}>
                                                                        {o.text}
                                                                    </MenuItem>)
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                    }

                                    {
                                            filter === 2 && auth.user && auth.user.role == 1 &&
                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={2}>
                                                <FormControl variant="outlined" fullWidth={true}>
                                                    <Select
                                                            className="muiSelect"
                                                            value={adminType}
                                                            name="adminType"
                                                            onChange={e => {
                                                                resetPagination();
                                                                setAdminType(e.target.value as string);
                                                            }}
                                                            fullWidth
                                                    >
                                                        {
                                                            adminTypeDropdown.map((o: any) =>
                                                                    <MenuItem key={o.id} value={o.name}>
                                                                        {o.name}
                                                                    </MenuItem>)
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                    }
                                    {
                                            (filter === 5 || filter === 16) &&
                                            <>
                                                {
                                                        auth && auth.user && auth.user.role <= 2 &&
                                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                                                            <TranchesList
                                                                    tranches={tranches}
                                                                    value={trancheArr}
                                                                    onChange={setTrancheArr}
                                                            />
                                                        </Grid>
                                                }
                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                                                    <StatusTypes
                                                            value={statusTypes}
                                                            onChange={setStatusTypes}
                                                            setOpenTickets={setOpenTickets}
                                                            openTickets={openTickets}
                                                            carrier={carrier}
                                                            setCarrier={setCarrier}
                                                    />
                                                </Grid>
                                            </>
                                    }

                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                                        <Search {...searchProps} />
                                    </Grid>

                                    {
                                            filter === 14 &&
                                            <Grid item xs={12}>
                                                <Stats />
                                            </Grid>
                                    }
                                    {
                                            filter === 16 && auth && auth.user && auth.user.role <= 2 &&
                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                                                <ParticipantTypeList
                                                        participantTypes={participantTypes}
                                                        value={participantTypeArr}
                                                        onChange={setParticipantTypeArr}
                                                />
                                            </Grid>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        {
                                !loadingAccounts && filter === 3 &&
                                <ImoTab
                                        accountList={accountList}
                                        order={order}
                                        orderBy={orderBy}
                                        handleRequestSort={handleRequestSort}
                                        setId={setId}
                                        handleReInvite={handleReInvite}
                                        history={props.history}
                                        handleRefresh={handleRefresh}
                                        page={page}
                                        rowsPerPage={rowsPerPage}
                                        tab={imoTab}
                                        setTab={setImoTab}
                                        searchText={search}
                                        region={region}
                                        imoCarrierFilter={imoCarrierFilter}
                                />
                        }
                        <TableWrap>
                            {
                                    loadingAccounts && <CircularLoading />
                            }

                            {
                                    !loadingAccounts && filter === 2 &&
                                    <AdminTable
                                            accountList={accountList}
                                            order={order}
                                            orderBy={orderBy}
                                            handleRequestSort={handleRequestSort}
                                            handleClickOpen={(i: string) => setId(i)}
                                            handleClickReInvite={(id: string) => handleReInvite(id)}
                                            history={props.history}
                                            handleRefresh={handleRefresh}
                                            page={page}
                                            rowsPerPage={rowsPerPage}
                                            tab={imoTab}
                                            setTab={setImoTab}
                                            searchText={search}
                                            region={region}
                                            imoCarrierFilter={imoCarrierFilter}
                                    />
                            }
                            {
                                    !loadingAccounts && filter === 4 &&
                                    <AgentTable
                                            accountList={accountList}
                                            order={order}
                                            orderBy={orderBy}
                                            handleRequestSort={handleRequestSort}
                                            handleClickOpen={(i: string) => setId(i)}
                                            handleClickReInvite={(id: string) => handleReInvite(id)}
                                            history={props.history}
                                            showList={true}
                                            basePath={basePath}
                                            handleRefresh={handleRefresh}
                                            page={page}
                                            rowsPerPage={rowsPerPage}
                                    />
                            }
                            {
                                    !loadingAccounts && filter === 5 &&
                                    <IndividualTable
                                            accountList={accountList}
                                            order={order}
                                            listType={props.listType}
                                            orderBy={orderBy}
                                            handleRequestSort={handleRequestSort}
                                            callingFrom={props.callingFrom == "parentPortal" ? props.callingFrom : "accountList"}
                                            handleClickOpen={(i: string) => setId(i)}
                                            handleClickReInvite={(id: string) => handleReInvite(id)}
                                            handleChangeStatus={(status: number, id: string) => handleChangeStatus(status, id)}
                                            history={props.history}
                                            basePath={basePath}
                                            handleRefresh={handleRefresh}
                                            page={page}
                                            rowsPerPage={rowsPerPage}
                                    />
                            }
                            {
                                    !loadingAccounts && filter === 8 &&
                                    <MultiLifeTable
                                            accountList={accountList}
                                            order={order}
                                            orderBy={orderBy}
                                            handleRequestSort={handleRequestSort}
                                            callingFrom="accountList"
                                            handleClickOpen={(i: string) => setId(i)}
                                            handleClickReInvite={(id: string) => handleReInvite(id)}
                                            history={props.history}
                                            handleRefresh={handleRefresh}
                                            page={page}
                                            rowsPerPage={rowsPerPage}
                                    />
                            }
                            {
                                    !loadingAccounts && filter === 10 &&
                                    <GroupLinkTable
                                            order={order}
                                            orderBy={orderBy}
                                            handleRequestSort={handleRequestSort}
                                            accountList={accountList}
                                            handleClickOpen={(i: string) => setId(i)}
                                            history={props.history}
                                            page={page}
                                            rowsPerPage={rowsPerPage}
                                    />
                            }
                            {
                                    !loadingAccounts && filter === 7 &&
                                    <CarrierTable
                                            accountList={accountList}
                                            order={order}
                                            orderBy={orderBy}
                                            handleRequestSort={handleRequestSort}
                                            handleClickOpen={(i: string) => setId(i)}
                                            history={props.history}
                                            handleRefresh={handleRefresh}
                                            page={page}
                                            rowsPerPage={rowsPerPage}
                                    />
                            }
                            {
                                    !loadingAccounts && filter === 12 &&
                                    <TrusteeTable
                                            accountList={accountList}
                                            order={order}
                                            orderBy={orderBy}
                                            handleRequestSort={handleRequestSort}
                                            handleClickOpen={(i: string) => setId(i)}
                                            history={props.history}
                                    />
                            }
                            {
                                    !loadingAccounts && filter === 13 &&
                                    <BankTable
                                            accountList={accountList}
                                            order={order}
                                            orderBy={orderBy}
                                            handleRequestSort={handleRequestSort}
                                            handleClickOpen={(i: string) => setId(i)}
                                            history={props.history}
                                    />
                            }
                            {
                                    !loadingAccounts && filter === 16 &&
                                    <AllParticipantTable
                                            accountList={accountList}
                                            order={order}
                                            orderBy={orderBy}
                                            callingFrom="accountList"
                                            handleRequestSort={handleRequestSort}
                                            handleClickOpen={(i: string) => setId(i)}
                                            history={props.history}
                                            handleRefresh={handleRefresh}
                                            page={page}
                                            rowsPerPage={rowsPerPage}
                                    />
                            }
                            {
                                    !loadingAccounts && filter === 14 &&
                                    <ClientListTable
                                            accountList={accountList}
                                            order={order}
                                            orderBy={orderBy}
                                            callingFrom="accountList"
                                            handleRequestSort={handleRequestSort}
                                            handleClickOpen={(i: string) => setId(i)}
                                            history={props.history}
                                            page={page}
                                            rowsPerPage={rowsPerPage}
                                    />
                            }
                            {
                                    !loadingAccounts && filter === 18 &&
                                    <AggregatorTable
                                            accountList={accountList}
                                            order={order}
                                            orderBy={orderBy}
                                            handleRequestSort={handleRequestSort}
                                            handleClickOpen={(i: string) => setId(i)}
                                            history={props.history}
                                    />
                            }
                        </TableWrap>
                            <div 
                                className="floatRight"
                                style={{display:"flex",   alignItems: "centre"}}
                                > 
                            {![imoTabs[1], imoTabs[2]].includes(imoTab) && <Button 
                                        className="btnSmall  mt10 mb20"
                                        color="primary"
                                        style={{ color:"#fff"}}
                                        onClick={() => exportCSV()}
                                        size="small"
                                        label="Export List"
                                        type={"button"}
                                        variant="contained"
                                    />
                                    }
                            <Pagination
                                    page={Number(page)}
                                    rowsPerPage={Number(rowsPerPage)}
                                    setPage={setPage}
                                    setRowsPerPage={setRowsPerPage}
                                    accountList={(imoTab === imoTabs[2] ? approvedImoList : imoTab === imoTabs[1] ? approvedImoList : accountList)}
                            />
                    </div>
                     
                    
                    </CardContent>
                </Card>
                {
                        id &&
                        <DeleteModel
                                accountType={ucFirst(AccountTypeByFilter[filter])}
                                content={`Delete ${filter === 2 ? "Master " : ""}${ucFirst(AccountTypeByFilter[filter])} Currently Not Available`}
                                disableDelete={true}
                                handleClose={() => setId("")}
                                handleYes={deleteUser}
                                open={true}
                                title={filter === 14 ? getDeletedUserName(id, true) : getDeletedUserName(id, false)}
                        />}
            </React.Fragment>
    );
}

const ImoFilters = ({ imoType, setImoType, region, setRegion, resetPagination, tab }) => {
    return (
            <>
                {tab === imoTabs[0] &&
                        <Grid item xs={12} sm={12} md={6} lg={2} xl={2}>
                            <FormControl variant="outlined" fullWidth={true}>
                                <InputLabel htmlFor="imo-type" shrink>Type</InputLabel>
                                <Select
                                        id="imo-type"
                                        className="muiSelect"
                                        value={imoType}
                                        name="imoType"
                                        onChange={e => {
                                            resetPagination();
                                            setImoType(e.target.value);
                                        }}
                                        fullWidth
                                >
                                    {
                                        [
                                            allOption,
                                            ...imoClassDropDown.filter(x => x.name !== "Other"),
                                        ].map((o: any) =>
                                                <MenuItem key={o.id} value={o.value}>
                                                    {o.name}
                                                </MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                }
                {tab === imoTabs[0] &&
                        <Grid item xs={12} sm={12} md={6} lg={2} xl={2}>
                            <FormControl variant="outlined" fullWidth={true}>
                                <InputLabel htmlFor="imo-region" shrink>Region</InputLabel>
                                <Select
                                        id="imo-region" className="muiSelect" value={region} name="region" onChange={e => {
                                    resetPagination();
                                    setRegion(e.target.value);
                                }} fullWidth
                                >
                                    {
                                        [allOption, ...imoRegionDropDown].map((o: any) =>
                                                <MenuItem key={o.id} value={o.value}>
                                                    {o.name}
                                                </MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                }
            </>
    );
};

const StatusTypes = (props: StatusTypesProps) => {
    const { value, onChange, openTickets, setOpenTickets, carrier, setCarrier } = props;
    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        onChange(event.target.value as string[]);
    };

    const getMenuItems = (subheader) => {
        return [
            <ListSubheader disableSticky={true}>{subheader}</ListSubheader>,
            ...filterStatusTypes[subheader].map(statusTypeIdx => {
                if (subheader == "Tickets") {
                    return filterStatusTypes[subheader].map((ticket: any, idx: number) => {
                        return (
                                <MenuItem key={idx}>
                                    <Checkbox
                                            checked={openTickets} onChange={() => {
                                        if (typeof setOpenTickets === "function") {
                                            setOpenTickets(!openTickets);
                                        }
                                    }}
                                    />
                                    <ListItemText primary={ticket.label} />
                                </MenuItem>
                        );
                    });
                }
                if (subheader == "Carrier") {
                    return filterStatusTypes[subheader].map((item: any, idx: number) => {
                        return (
                                <MenuItem key={idx}>
                                    <Checkbox
                                            checked={carrier} onChange={() => {
                                        if (typeof setCarrier === "function") {
                                            setCarrier(!carrier);
                                        }
                                    }}
                                    />
                                    <ListItemText primary={item.label} />
                                </MenuItem>
                        );
                    });
                }
                return (
                        <MenuItem value={statusTypeIdx} key={statusTypeIdx}>
                            <Checkbox checked={value.indexOf(statusTypeIdx) > -1} />
                            <ListItemText primary={AccountStatusArr[statusTypeIdx]} />
                        </MenuItem>
                );
            }),
        ];
    };

    const displayStatus = (selected) => {
        selected = selected.filter(value => !!value);
        if (selected.length === 0) {
            return "All Status Types";
        }

        return selected.map(statusTypeIdx => AccountStatusArr[statusTypeIdx]).join(", ");
    };

    return (
            <FormControl variant="outlined" fullWidth={true}>
                <InputLabel htmlFor="status-types" id="status-types" shrink>Status Types</InputLabel>
                <Select
                        multiple
                        id="status-types-id"
                        labelId="status-types"
                        renderValue={displayStatus}
                        onChange={handleChange}
                        value={value}
                        displayEmpty={true}
                >
                    {
                        Object.keys(filterStatusTypes).map(subheader => getMenuItems(subheader))
                    }
                </Select>
            </FormControl>
    );
};

const TranchesList = (props: any) => {
    const { value, onChange, tranches } = props;

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        onChange(event.target.value as string[]);
    };

    const displayTranche = (selected) => {
        selected = selected.filter(value => !!value);
        if (selected.length === 0) {
            return "All";
        }
        return selected.join(", ");
    };

    return (
            <FormControl variant="outlined" fullWidth={true}>
                <InputLabel htmlFor="status-types" id="status-types" shrink>Tranche No</InputLabel>
                <Select
                        multiple
                        id="status-types-id"
                        labelId="status-types"
                        onChange={handleChange}
                        renderValue={displayTranche}
                        value={value}
                        displayEmpty={true}
                >
                    {
                            tranches && tranches.map(item => (
                                    <MenuItem value={item} key={item}>
                                        <Checkbox checked={value.indexOf(item) > -1} />
                                        <ListItemText primary={item} />
                                    </MenuItem>
                            ))
                    }
                </Select>
            </FormControl>
    );
};

const ParticipantTypeList = (props: any) => {
    const { value, onChange, participantTypes } = props;

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        onChange(event.target.value as string[]);
    };

    const displayParticipantType = (selected) => {
        selected = selected.filter(value => !!value);
        if (selected.length === 0) {
            return "All";
        }
        return selected.join(", ");
    };

    return (
            <FormControl variant="outlined" fullWidth={true}>
                <InputLabel htmlFor="client-types" id="client-types" shrink>Participant Type</InputLabel>
                <Select
                        multiple
                        id="client-types-id"
                        labelId="client-types"
                        onChange={handleChange}
                        renderValue={displayParticipantType}
                        value={value}
                        displayEmpty={true}
                >
                    {
                            participantTypes && participantTypes.map(item => (
                                    <MenuItem value={item} key={item}>
                                        <Checkbox checked={value.indexOf(item) > -1} />
                                        <ListItemText primary={item} />
                                    </MenuItem>
                            ))
                    }
                </Select>
            </FormControl>
    );
};

const useStyles = makeStyles((theme: Theme) => createStyles({
    paper: {
        width: "100%",
        minWidth: 260,
        display: "inline-block",
    },
    root: {
        width: "100%",
        marginTop: "0",
        overflowX: "auto",
        height: "70vh",
    },
    table: {
        minWidth: 700,
    },
    actions: {
        color: theme.palette.text.secondary,
    },
    title: {
        flex: "0 0 auto",
    },
    list: {
        width: 500,
    },
    container: {
        maxHeight: 600,
    },
}));

function mapStateToProps(state: RootState) {
    return {
        auth: state.auth,
        accountList: state.accounts,
        approvedImoList: state.approvedImoList,
        carriers: state.carriers,
        loadingAccounts: state.loadingAccounts,
    };
}

export default connect(mapStateToProps)(AccountList);


function AccountTypeList({callingFrom, filter, onChange } : {callingFrom: "parentPortal" | "dropdown" | "navigation", filter: number, onChange: (event: ChangeEvent<{   name?: string | undefined;   value: unknown; }>, child: ReactNode) => void}) {

    switch (callingFrom){
        case "parentPortal":
            return <Select
            className="muiSelect"
            value={filter}
            onChange={onChange}
            fullWidth
            >
                {
                        accountTypeDropDown.length &&
                        accountTypeDropDown.map((o: any) => [
                                    5,
                                ].find(p => p === o.id) &&
                                <MenuItem key={o.id} value={o.id}>
                                    {o.name}
                                </MenuItem>)
                }</Select>
        default :
            return <Select
                    className="muiSelect"
                    value={filter}
                    onChange={onChange}
                    fullWidth
            >
                <ListSubheader
                        disableSticky={true} className={"clickDisable logoBlueClr"}
                >Accounts</ListSubheader>
                {
                        accountTypeDropDown.length &&
                        accountTypeDropDown.map((o: any) => [
                                    2,
                                    3,
                                    4,
                                    7,
                                    12,
                                    13,
                                    18
                                ].find(p => p === o.id) &&
                                <MenuItem key={o.id} value={o.id}>
                                    {o.name}
                                </MenuItem>)
                }
                <ListSubheader
                        disableSticky={true} className={"clickDisable logoBlueClr"}
                >Participants</ListSubheader>
                {
                        accountTypeDropDown.length &&
                        accountTypeDropDown.map((o: any) => [
                                    5,
                                    8,
                                    14,
                                    10,
                                    16,
                                ].find(p => p === o.id) &&
                                <MenuItem key={o.id} value={o.id}>
                                    {o.name}
                                </MenuItem>)
                }
            </Select>
    }


}