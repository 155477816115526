import React from "react";
import {Grid, Link, Typography} from "@material-ui/core";
import {Formik} from "formik";
import {Label} from "../../../../../../components/formInputs";
import {AutoComplete} from "../../../../../../components/formikFormInputs";
import {stateDropDown} from "../../../../../../constants";
import GetFormContent from "./BeneficiariesList/GetFormContent";
import {useActions} from "../../../../../../actions";
import * as AccountActions from "../../../../../../actions/account";
import * as Yup from "yup";
import * as Aws from "../../../../../../actions/aws";
import { values } from "lodash";

type placeObj = { id: number, key?: string, name: string, value: string };
let stateDropDownArr: placeObj[] = stateDropDown.map((state: any) => {
    let title:string = state && state.isCommunityState ? `${state.name} (Community State)`:`${state.name}`;
    return {
        id: state.id || "",
        title: title || "",
        key: state.value || "",
        name: state.name,
        value: state.value || "",
    };
});

const checkIsCommunityState = (stateCode: string) => {
    let findResult: any = stateDropDown.find((state: any) => {
        return state.value === stateCode;
    });
    if (!findResult) {
        return false;
    }
    return findResult.isCommunityState;
};


const CommunityStateSelection = (props: any) => {
    const awsActions = useActions(Aws);
    const {updateClientFields} = useActions(AccountActions);
    const {user, isFromClientDetail, setIsSubmitting, setIsCommunityState, marriageStatusValue, setMarriageStatus, setIsErrors, setActionItem, auth, stateValue, setCommunityStateVal, setStateValue} = props;
    const [isHide, setIsHide] = React.useState(true);


    const isClientSigned = (user && user.enrollment && (user.enrollment.clientSigned || user.enrollment.envelopeId)) || false;
    const role:number = auth && auth.user && auth.user.role;
    let initialValues = {
        communityState: (user.client && user.client.state) || "",
        marriageStatus: (user && user.client && user.client.marriageStatus) || ""
    };
    const [initVal, setInitVal] = React.useState(initialValues);
    const getStateObj = (stateVal: string) => {
        if (stateVal) {
            const stateList = JSON.parse(JSON.stringify(stateDropDownArr));
            let stateObj: any = stateList.find((item: any, index) => {
                return item.value === stateVal
            })
            if (stateObj) {
                let stateObjFound = JSON.parse(JSON.stringify(stateObj));
                stateObjFound.key = stateObjFound && stateObjFound.value;
                stateObjFound.title = stateObjFound && stateObjFound.name;
                return stateObjFound;
            }
        }
        return null;
    }
    React.useEffect(() => {
        if (isFromClientDetail && stateValue) {
            const stateObj = getStateObj(stateValue);
            if (stateObj) {
                setInitVal({
                    ...initVal,
                    communityState: stateValue
                })
            }
        }
    }, [stateValue])
    React.useEffect(() => {
        if (user && user.client && user.client.state) {
            const stateObj = getStateObj(user.client.state);
            if (stateObj) {
                setInitVal({
                    ...initVal,
                    communityState: stateObj
                })
            }
        }
    }, [user && user.client && user.client.state])

    React.useEffect(() => {
        console.log(user && user.client && user.client.state)
        let isCommunityState: boolean = checkIsCommunityState((user && user.client && user.client.state) || "");
        setIsHide(!isCommunityState);
        setMarriageStatus((user && user.client && user.client.marriageStatus) || "");
        setIsCommunityState(isCommunityState);
    }, [])

    const fieldObj = [
        {
            id: "communityState",
            name: "communityState",
            helperFieldText: "",
            label: <Label label={"Client State of Residence"} required={true}/>,
            placeholder: "Client State of Residence",
            parentLabel: "Community State Identification",
            isRequired: true,
            disabled:isClientSigned,
            className: "mt10",
            component: AutoComplete,
            contentList: stateDropDownArr,
            cb: (value)=> {
            },
            onSelectChange: (val: string, setFieldValue:Function, submitForm:Function) => {
                let isCommunityState: boolean = checkIsCommunityState(val);
                setIsHide(!isCommunityState);
                if( setFieldValue ){
                    setFieldValue("marriageStatus", "");
                }
                setIsCommunityState(isCommunityState);
                let stickyFooter: any = document.getElementById("stickyFooter");
                if (stickyFooter) {
                    stickyFooter.style.display = 'block';
                }
                if(submitForm){
                    setTimeout(()=>submitForm(), 500);
                }
                if (typeof setCommunityStateVal === 'function') {
                    setCommunityStateVal(val);
                }
                if (typeof setStateValue === 'function') {
                    setTimeout(()=>setStateValue(""), 500);
                }
            },
            variant: "outlined",
            size: {
                md: 5,
                lg: 5,
            },
            show:true,
            needUpdate: stateValue ? true :  false
        },
        {
            id: "marriageStatus",
            name: "marriageStatus",
            parentLabel: <Label label={"Are you married?"} required={true}/>,
            isRadio: true,
            disabled:isClientSigned,
            isHide: isHide,
            onSelectChange: (val: string) => {
                setMarriageStatus(val);
            },
            size: {
                md: 6,
                lg: 6,
            },
            show:true,
        },
    ];
    /* Validations of fields set */
    let keysValidation = {
        communityState: Yup.string().required("Required Field").transform((o, v) => {
            if (v && typeof v === 'object') {
                return v.key;
            }
            return v || ""
        })
    };
    if(!isHide){
        keysValidation["marriageStatus"] = Yup.string().required("Required Field");
    }

    const validate = (initialValue:any) => {
        let errors:any = false
        if(!initialValue.communityState){
            errors = true;
        }
        if(!initialValue.marriageStatus && !isHide){
            errors = true;
        }
        return errors;
    }

    const downloadForm = async () => {
        let documentUri: string = "beneficiary-form.pdf";
        let dirName: string = "enrollment";
        let file = documentUri.split(".");
        let fileType = file[file.length - 1].toUpperCase();
        const contentType = (fileType === "PDF" || fileType === "pdf") ? ("application/pdf") : ("application/vnd.openxmlformats-officedocument.presentationml.presentation");
        let queryObj = {
            objectName: documentUri,
            contentType: contentType,
        };
        const s3url = await awsActions.getSecureUrlAndDownload(dirName, queryObj);
        if (s3url && s3url.Body) {
            let blob = new Blob([new Buffer(s3url.Body, "binary")]
                    , { type: s3url.ContentType });
            const url = window.URL.createObjectURL(blob);
            const link = window.document.createElement("a");
            link.href = url;
            link.setAttribute("download", documentUri);
            window.document.body.appendChild(link);
            link.click();
        }
    };

    return (
            <Formik
                    initialValues={initVal}
                    enableReinitialize={true}
                    validationSchema={Yup.object().shape(keysValidation)}
                    validateOnChange={true}
                    onSubmit={async (values: any, {setSubmitting}) => {
                        try {
                            let payload = values;
                            payload["communityState"] = typeof values["communityState"] === 'object' ? values["communityState"].key : values["communityState"];
                            await updateClientFields({
                                marriageStatus : marriageStatusValue,
                                state : payload.communityState,
                                formType: "COMMUNITY_STATE_SELECTION",
                            }, (user && user.client && user.client.id) || "", isFromClientDetail);
                            //setActionItem(clientDetail.employeeSSN ? true : false );
                            setActionItem(
                                    ( role === 1 || role === 2 || role === 4) ? true
                                            :
                                            ( user.enrollment && user.enrollment.clientSigned && user.client.usCitizen ? true : false )
                            );

                            setTimeout(() => {
                                setSubmitting(false);
                            }, 400);

                        } catch (e) {
                            return false;
                        }
                    }}
            >
                {
                    (formikProps: any) => {
                        setIsSubmitting(formikProps.isSubmitting);
                        setIsErrors(validate(formikProps.values));
                        const isCommunityState: boolean = checkIsCommunityState((formikProps.values &&
                                formikProps.values.communityState &&
                                formikProps.values.communityState.value) || (formikProps.values &&
                                formikProps.values.communityState) || "");
                        return (
                                <form style={props.hideUperInputs && {display:"none"}} className="floatLeft w100 dpNone "
                                      onSubmit={formikProps.handleSubmit} autoComplete="off">
                                    <Grid container>

                                        <GetFormContent fieldObj={fieldObj} {...formikProps}
                                                        enableOnChangeSubmit={true} isFromClientDetail={isFromClientDetail}/>
                                        {formikProps.values.marriageStatus === "Married" && isCommunityState &&
                                        <Grid item xs={12}>
                                            <Typography variant="body1" className="mt15 textRed">
                                                This state is a community state and will require the spouse as a primary
                                                beneficiary to receive 50% or more death benefit. If the spouse wishes
                                                to waive this requirement please have the spouse fill out and notarize
                                                &nbsp;<Link  onClick={downloadForm}>this waiver form</Link> and fax it to 972-755-1585. For
                                                questions please call
                                                800-294-9940.
                                            </Typography>
                                        </Grid>
                                        }
                                    </Grid>
                                </form>
                        );
                    }
                }
            </Formik>
    );
};

export default CommunityStateSelection;