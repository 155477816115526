import {Auth} from "../model/user";
import {ActionType, PasswordPayload} from "../model";
import request from "axios";
import {API_URL} from '../constants';

export const LogIn = (auth: Auth) => async (dispatch: Function, getState: Function, api: { post: Function }) => {
	try {
		let resp = await api.post('sessions', auth, { jwt: 1 });
		let payload = {};
		if (resp.statusCode ===403 ) payload = { message: resp.message, statusCode: 403 };
		dispatch({ type: ActionType.ALERT, payload: payload });
		return dispatch({ type: ActionType.LOGIN, payload: resp });
	} catch (err) {
        if (err.statusCode === 406) { //approved agents are not allowed to login: NIWC-1876.
            return {type: ActionType.LOGIN, payload: {message: err.message, statusCode: 406}}; //406 is not acceptable
        }
		return dispatch({ type: ActionType.ALERT, payload: err });
	}
};

export const InvitedLogIn = (auth: Auth) => async (dispatch: Function, getState: Function, api: { post: Function }) => {
	try {
		let resp = await api.post('sessions', auth, { jwt: 1 });
		let payload = {};
		if (resp.statusCode ===403 ) payload = { message: resp.message, statusCode: 403 };
		dispatch({ type: ActionType.ALERT, payload: payload });
		return { type: ActionType.LOGIN, payload: resp };
	} catch (err) {
		return dispatch({ type: ActionType.ALERT, payload: err });
	}
};

export const forgotPassword = (payload: PasswordPayload) => async (dispatch: Function, getState: Function, api: { post: Function }) => {
	try {
		let resp = await api.post('account/forgot/password', payload);
		dispatch({ type: ActionType.ALERT, payload: { message: resp, statusCode: 200 } });
		return dispatch({ type: ActionType.FORGOT_PASSWORD, payload: resp });
	} catch (err) {
		return dispatch({ type: ActionType.ALERT, payload: err });
	}
};

export const resetPassword = (payload: PasswordPayload) => async (dispatch: Function, getState: Function, api: { put: Function }) => {
	try {
		let resp = await api.put('account/reset/password', payload);
		dispatch({ type: ActionType.ALERT, payload: { message: resp, statusCode: 200 } });
		dispatch({ type: ActionType.RESET_PASSWORD, payload: resp });
		return true;
	} catch (err) {
		dispatch({ type: ActionType.ALERT, payload: err });
		return false;
	}
};

export const getAccountDetails = () => async (dispatch: Function, getState: Function, api: { get: Function }) => {
	try {
		let resp = await api.get('sessions', {}, {});
		dispatch({
			type: ActionType.USER_DETAILS,
			payload: resp
		});
		return true;
	} catch (err) {
        localStorage.clear();
		dispatch({ type: ActionType.LOGOUT, payload: {} });
		return false;
	}
};

export const getInvitedAccountDetails = (token:string) => async (dispatch: Function, getState: Function, api: { get: Function }) => {
	try {
		let resp = await request.get(`${API_URL}/sessions`, {
			headers: {
				'Authorization': `Bearer ${token}`
			  }
		});
		dispatch({
			type: ActionType.USER_DETAILS,
			payload: resp
		});
		return true;
	} catch (err) {
        localStorage.clear();
		dispatch({ type: ActionType.LOGOUT, payload: {} });
		return false;
	}
};

type VPAL = (token: string,preferred: string) => (dispatch: Function) => Promise<any>;
export const verifyPreferredAgentLogin: VPAL = (accessToken) => async (dispatch) => {
    try {
        const config = {headers: {'Authorization': `Bearer ${accessToken}`}};
        const url = `${API_URL}/sessions`;
        const resp = await request.get(url, config);
        const payload = {accessToken, preferredLoginViaNIWCorp: true, user: resp.data};
        dispatch({payload, type: ActionType.LOGIN});
        return payload;
    } catch (e) {
        localStorage.clear();
		dispatch({ type: ActionType.LOGOUT, payload: {} });
		return false;
    }
};

export const LogOut = () => async (dispatch: Function, getState: Function, api: {}) => {
    localStorage.clear();
    sessionStorage.clear();
	return dispatch({ type: ActionType.LOGOUT, payload: {} });
};

export const closeAlert = () => async (dispatch: Function, getState: Function, api: {}) => {
	return dispatch({ type: ActionType.ALERT, payload: {} });
};

export const alertNotification = (payload:{statusCode:number, message:string}) => async (dispatch: Function, getState: Function, api: {}) => {
    return dispatch({ type: ActionType.ALERT, payload: payload });
};


export const demoClient = (strategyId: number, carrierId: number, demoClientId?: number) => async (dispatch: Function, getState: Function, api: { put: Function }) => {
    try {
        let demoPayload: any = {
            accessType: "client",
            demoStrategyId: strategyId,
            demoCarrierId: carrierId
        };
        if (demoClientId) {
            demoPayload.demoClientId = demoClientId;
        }
        await api.put('account/access', demoPayload, {});
        let currentAuth = getState().auth;
        currentAuth.user.accessType = "client";
        dispatch({type: ActionType.ALERT, payload: {}});
        return dispatch({type: ActionType.DEMO_CLIENT, payload: currentAuth});
    } catch (err) {
        return dispatch({type: ActionType.ALERT, payload: "Error Entering Demo"});
    }
};

export const toggleParentPortal = (state:boolean) => async (dispatch: Function, getState: Function, api: { put: Function }) => {
    try {
        dispatch({type: ActionType.ALERT, payload: {}});
        return dispatch({type: ActionType.PARENT_PORTAL, payload: state});
    } catch (err) {
        return dispatch({type: ActionType.ALERT, payload: "Error in  Parent Portal"}); // change message
    }
};

export const exitDemoClient = () => async (dispatch: Function, getState: Function, api: { put: Function }) => {
	try {
		await api.put('account/access', { accessType: "", demoStrategyId: null, demoCarrierId: null, demoClientId: null }, {});
		let currentAuth = getState().auth;
		currentAuth.user.accessType = "";
		dispatch({ type: ActionType.ALERT, payload: {} });
		return dispatch({ type: ActionType.EXIT_DEMO_CLIENT, payload: currentAuth });
	} catch (err) {
		return dispatch({ type: ActionType.ALERT, payload: "Error Exiting Demo" });
	}
};



export const authGroupLinkParticipant = (payload: any) => async (dispatch: Function, getState: Function, api: { post: Function }) => {
	try {
		const resp: any = await api.post('account/group-link/auth', payload, {});
		if (resp && resp.id) {
			dispatch({ type: ActionType.ALERT, payload: { message: "Successfully Authenticated.", statusCode: 200 } });
		} else {
			dispatch({ type: ActionType.ALERT, payload: { message: "Unable to Authenticate, Please try again.", statusCode: 400 } });
		}
		return resp;
	} catch (err) {
		return dispatch({ type: ActionType.ALERT, payload: err });
	}
}

export const setGroupLinkParticipantPassword = (payload: any) => async (dispatch: Function, getState: Function, api: { post: Function }) => {
	try {
		const resp = await api.post('account/group-link/password', payload, {});
		if (resp) {
			dispatch({ type: ActionType.ALERT, payload: { message: "Successfully Created.", statusCode: 200 } });
			return dispatch({ type: ActionType.LOGIN, payload: resp });
		} else {
			return dispatch({ type: ActionType.ALERT, payload: resp });
		}
	} catch (err) {
		return dispatch({ type: ActionType.ALERT, payload: err });
	}
}

export const authCompanyLink = (payload: any) => async (dispatch: Function, getState: Function, api: { post: Function }) => {
	try {
		const resp: any = await api.post('account/group/auth', payload, {});
		if (resp && resp.id) {
			dispatch({ type: ActionType.ALERT, payload: { message: "Successfully Authenticated.", statusCode: 200 } });
		} else {
			dispatch({ type: ActionType.ALERT, payload: { message: "Unable to Authenticate, Please try again.", statusCode: 400 } });
		}
		return resp;
	} catch (err) {
		return dispatch({ type: ActionType.ALERT, payload: err });
	}
}

export const authClientListLink = (payload: any) => async (dispatch: Function, getState: Function, api: { post: Function }) => {
	try {
		const resp: any = await api.post('account/clientlist/auth', payload, {});
		if (resp && resp.id) {
			dispatch({ type: ActionType.ALERT, payload: { message: "Successfully Authenticated.", statusCode: 200 } });
		} else {
			dispatch({ type: ActionType.ALERT, payload: { message: "Unable to Authenticate, Please try again.", statusCode: 400 } });
		}
		return resp;
	} catch (err) {
		return dispatch({ type: ActionType.ALERT, payload: err });
	}
}

interface CensusPassProps {
	groupParticipantDetail: {
		id: string,
		firstName: string,
		lastName: string,
		email: string,
		phone?: string,
		gender: string
	};
	groupDetail: {
		userId: string
	},
	password: string
	phoneNumber? : string
}


export const setGroupParticipantPassword = (data: CensusPassProps) => async (dispatch: Function, getState: Function, api: { post: Function }) => {
	console.log("data line 143", data)
	try {
		let payload: any = Object.assign({}, {
			censusId: data.groupParticipantDetail.id,
			user: { //group census user
				firstName: data.groupParticipantDetail.firstName,
				lastName: data.groupParticipantDetail.lastName,
				email: data.groupParticipantDetail.email,
				phoneNumber: data.phoneNumber ? data.phoneNumber :  data.groupParticipantDetail.phone,
				password: data.password,
				parent: data.groupDetail.userId,
			},
			groupDetail: data.groupDetail
		});
		const resp = await api.post('account/group-participant/password', payload, {});
		if (resp) {
			dispatch({ type: ActionType.ALERT, payload: { message: "Password Successfully Created. Login Now!", statusCode: 200 } });
		}
		console.log("resp", resp);
		return resp;
		// if (resp) {
		// 	dispatch({ type: ActionType.ALERT, payload: { message: "Successfully Created.", statusCode: 200 } });
		// 	return dispatch({ type: ActionType.LOGIN, payload: resp });
		// } else {
		// 	return dispatch({ type: ActionType.ALERT, payload: resp });
		// }
	} catch (err) {
		return dispatch({ type: ActionType.ALERT, payload: err });
	}
}

interface ClientListProps {
	clientListParticipant: {
		id: number,
		firstName: string,
		lastName: string,
		email: string,
		phone?: string,
	},
	clientListDetail : {
		strategyId :  number,
		agentId: number,
		id: number
	},
	password: string
	phone?: string,
}

export const setClientListParticipantPassword = (data: ClientListProps) => async (dispatch: Function, getState: Function, api: { post: Function }) => {
	try {
		let payload: any = Object.assign({}, {
			participantId: data.clientListParticipant.id,
			user: {
				firstName: data.clientListParticipant.firstName,
				lastName: data.clientListParticipant.lastName,
				email: data.clientListParticipant.email,
				phone: data.phone ? data.phone :  data.clientListParticipant.phone,
				password: data.password,
			},
			clientListDetail : {
				strategyId :  data.clientListDetail.strategyId,
				agentId: data.clientListDetail.agentId,
				id: data.clientListDetail.id
			}
		});
		const resp = await api.post('account/clientlist-participant/password', payload, {});
		if (resp) {
			dispatch({ type: ActionType.ALERT, payload: { message: "Password Successfully Created. Login Now!", statusCode: 200 } });
		}
    	return resp;
	} catch (err) {
		return dispatch({ type: ActionType.ALERT, payload: err });
	}
}

interface OtpProps{
	otp: string,
	token: string
}
export const verifyUserOtp = (payload : OtpProps) => async (dispatch: Function, getState: Function, api: any) => {
	await api.post('account/verify_otp', payload, {});
}

export const publicContactUs = (payload: any) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let resp = await api.post('account/public/contact_us', payload);
        dispatch({ type: ActionType.ALERT, payload: { message: "We will contact you as soon as possible.", statusCode: 200 } });
        return resp;
    } catch (err) {
        return dispatch({ type: ActionType.ALERT, payload: {message: "Something went wrong.", statusCode: 400} });
    }
}