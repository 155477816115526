import { v4 as uuidv4 } from 'uuid';
import {FormikValues} from "../../formikFormInputs";
import {numberToThousandSeparator, webHookURL} from "../../../pages/client/Estimation/V1/Steppers";
import {NSN} from "../../../model/misc";
import { unmaskCurrency } from '../../../utils/commonUtils';
import {ATLANTIC_HEALTH} from "../../../constants";
import {Account} from "../../../model/account";


export const ANNUALLY = "Annual";
export const MONTHLY = "Monthly";

type CER = {
    callingFrom: string | undefined;
    estimationActions: any;
    initRequest: Function;
    onReportsAlreadyExist?: Function;
    onRequestIdCreated: Function;
    user: { loggerInUserId?: NSN | undefined; id: NSN; role: number, companyLinkUrl?: string  };
}

export const rateClassMappingObject = {
    "default": "Standard_NT",
    "Elite": "Elite_NT",
    "Preferred NT": "Preferred_NT",
    "Preferred Plus": "Elite_NT",
    "Preferred Tobacco" : "Preferred_Tobacco",
    "Select NT": "Select_NT",
    "Standard Tobacco": 'Standard_Tobacco',
    'Standard NT': "Standard_NT",
}

export interface FormValues  {
    contributionAmount: string,
    contributionType: '',
    deathBenefitAmount: string,
    healthRating: string,
    incomeEndAge: string,
    incomeOptions: string,
    incomeStartAge: string,
    livingBenefit: any[],
    tranche: string,
    contributionFrequency: typeof MONTHLY | typeof ANNUALLY
}

export type ProposalCommonProps = {
    client: any; // action item
    clientId: number;
    enrollmentId: number | string;
    open: boolean;
    proposalUpdate?: boolean;
    setUploadProposalPopUp: Function;
    refreshList:Function;
}

export enum ProposalEnum {
    UPLOAD_CUSTOM_ILLUSTRATION = 'UPLOAD_CUSTOM_ILLUSTRATION',
    RUN_ILLUSTRATION = 'RUN_ILLUSTRATION'
}

export const contributionOptions = {
    "contribution": {"id": "0", "value": "contribution", "label": "Contribution"},
    "deathBenefits": {"id": "1", "value": "deathBenefits", "label": "Death Benefits"},
};
export const incomeOptions = {
    "income": {"id": "0", "value": "income", "label": "Income"},
    "deathBenefitsOnly": {"id": "1", "value": "deathBenefitsOnly", "label": "Death Benefits Only"},
};


export type UCORIConstantKeyProps = keyof (typeof ProposalEnum);

export const isFormValid = (values: FormikValues, options:{minContribution?:number , minDeathBenefitAmount?:number, atlantic?: boolean} = {minContribution:21350 , minDeathBenefitAmount:100000, atlantic: false}) => {
    let validateObject: {
        isValid: boolean,
        fieldsErrors: { [key: string]: string }
    } = {
        isValid: true,
        fieldsErrors: {},
    };
    let contributionFieldName = "contributionAmount"
    if(options.atlantic){
        contributionFieldName = "totalCombinedContribution"
    }
    if (values.contributionType === contributionOptions.contribution.value) {
        if ((values.contributionType == contributionOptions.contribution.value && !values.deathBenefitAmount)) {
            validateObject = {
                isValid: false,
                fieldsErrors: {
                    deathBenefitAmount: "Required Field"
                }
            };
        } else if (
            values.contributionType == contributionOptions.contribution.value &&
            values.deathBenefitAmount && options.minDeathBenefitAmount &&
            Number(unmaskCurrency(values.deathBenefitAmount.toString())) < Number(options.minDeathBenefitAmount)
        ) {
            validateObject = {
                isValid: false,
                fieldsErrors: {
                    deathBenefitAmount: `Minimum $${numberToThousandSeparator(options.minDeathBenefitAmount)} DeathBenefitAmount required`
                }
            };
        } else if (values.deathBenefitAmount && Number(unmaskCurrency(values.deathBenefitAmount.toString())) > 99000000) {
            validateObject = {
                isValid: false,
                fieldsErrors: {
                    deathBenefitAmount: `Maximum $99,000,000 DeathBenefitAmount allowed`
                }
            };
        }
    }

    if (values.contributionType == contributionOptions.deathBenefits.value && !values.contributionAmount) {
        validateObject = {
            isValid: false,
            fieldsErrors: {
                contributionAmount: "Required Field"
            }
        };
    } else if (
        values.contributionType == contributionOptions.deathBenefits.value &&
        values.contributionAmount && 
        options.minContribution &&
        Number(unmaskCurrency(values[contributionFieldName].toString())) < options.minContribution
    ) {
        // validateObject = {
        //     isValid: false,
        //     fieldsErrors: {
        //         [contributionFieldName]:  options.atlantic ? `Minimum $${options.minContribution} required`: ``
        //     }
        // };
    }
    if (values.incomeOptions == incomeOptions.income.value && (!values.incomeStartAge || !values.incomeEndAge)) {
        validateObject = {
            isValid: false,
            fieldsErrors: {
                incomeEndAge: "Required Field",
                incomeStartAge: "Required Field"
            },
        };
    }

    return validateObject;
}

export const createEstimateReports = async (props: CER) => {
    const {
        // loader = true,
        user,
        onReportsAlreadyExist,
        // setIsReportRequestAutomated,
        // setRequestId,
        onRequestIdCreated,
        // setIsReportRequestCompleted,
        estimationActions,
        initRequest,
        // setIsReportDataLoading,
        callingFrom,
    } = props;

    //let data: any = localStorage.getItem("success") ? JSON.parse(localStorage.getItem("success") || "") : "";
    const uuid = uuidv4();
    let userId: NSN = user && user.id;
    const loggedInId = user && user.loggerInUserId;
    if (userId) {
        let webHookUrl: string = `${webHookURL}/${userId}/${loggedInId}?callingFrom=${callingFrom}`;
        let estimationDetails: any = await estimationActions.getEstimationDetailsByUserId(userId, callingFrom);
        if (estimationDetails && estimationDetails.id) {
            let responsePayload: any = JSON.parse(estimationDetails && estimationDetails.stressResponse);
            if (responsePayload && responsePayload.hasOwnProperty("success")) {

                let {
                    common,
                    comparison,
                    illustration,
                    stress,
                    reports,
                    run,
                } = responsePayload && responsePayload.success;
                if (reports === false) {  //isReportRequestCompleted
                    initRequest()
                    // if (loader) {
                    //     setIsReportRequestAutomated(false);
                    // } else {
                    //     setIsReportRequestAutomated(true);
                    // }
                    onRequestIdCreated(uuid);
                    let existingRunPayload: any = {};

                    //Setting up a template key on the basis of carrier.
                    let templateS3Key = run["deathbenefitonly"] ? "NLG Annual Individual DB Only.pdf" : "NLG Annual Individual Income.pdf";
                    if (common['carrier'] === "ALZ") {
                        //Monthly Case Fix
                        if (common["paymentfrequency"] === "Monthly") {
                            templateS3Key = run["deathbenefitonly"] ? "ALZ Monthly Individual DB only.pdf" : "ALZ Monthly Individual Income.pdf";
                        } else {
                            templateS3Key = run["deathbenefitonly"] ? "ALZ Annual Individual DB Only.pdf" : "ALZ Annual Individual Income.pdf";
                        }
                    }

                    //Special Case ATLANTIC_HEALTH
                    if (ATLANTIC_HEALTH.includes(user.companyLinkUrl || "") && user.role === 6) {
                        templateS3Key = run["deathbenefitonly"] ? "Atlantic Health Monthly DB Only.pdf" : "Atlantic Health Monthly Income.pdf";
                    }

                    existingRunPayload["id"] = uuid;
                    existingRunPayload["webhook"] = webHookUrl;
                    existingRunPayload["run"] = {
                        "stress": false,
                        "illustration": false,
                        "comparison": false,
                        "reports": true,
                        "deathbenefitonly": run["deathbenefitonly"],
                    };
                    existingRunPayload["common"] = common;
                    existingRunPayload["reports"] = {
                        "existingrun": {
                            "reports": false,
                            "stress": stress,
                            "illustration": illustration,
                            "comparison": comparison,
                        },
                        "details": {
                            "Kaizen": {
                                "templateS3Key": templateS3Key,
                            },
                            "Final_ClientFacing": {
                                "acceleratedbenefitsridersummary": true,
                                "printpuzzlepieceimageswithabrsummary": true,
                                "benefitsummaryanalysis": false,
                                "diversecreditingoptions": false,
                                "illustratedfuturepolicyactions": false,
                                "inputsummary": false,
                                "internalrateofreturn": false,
                                "monthlylowpointvalues": false,
                                "myplannedlifeinsuranceoffers": true,
                                "myplannedlifeinsuranceoffersdisplayorder": 1,
                                "policychargesandexpenses": false,
                                "salespresentationpages": false,
                                "statutorypremiuminformation": false
                            },
                            "Final_Internal": {
                                "acceleratedbenefitsridersummary": true,
                                "printpuzzlepieceimageswithabrsummary": true,
                                "benefitsummaryanalysis": false,
                                "diversecreditingoptions": false,
                                "illustratedfuturepolicyactions": false,
                                "inputsummary": true,
                                "internalrateofreturn": false,
                                "monthlylowpointvalues": false,
                                "myplannedlifeinsuranceoffers": true,
                                "myplannedlifeinsuranceoffersdisplayorder": 1,
                                "policychargesandexpenses": false,
                                "salespresentationpages": false,
                                "statutorypremiuminformation": false
                            },
                            "LowPoint": {
                                "acceleratedbenefitsridersummary": true,
                                "printpuzzlepieceimageswithabrsummary": true,
                                "benefitsummaryanalysis": false,
                                "diversecreditingoptions": false,
                                "illustratedfuturepolicyactions": false,
                                "inputsummary": true,
                                "internalrateofreturn": false,
                                "monthlylowpointvalues": false,
                                "myplannedlifeinsuranceoffers": true,
                                "myplannedlifeinsuranceoffersdisplayorder": 1,
                                "policychargesandexpenses": false,
                                "salespresentationpages": false,
                                "statutorypremiuminformation": false
                            }
                        },
                    };
                    await estimationActions.getEstimatesFromCloud(existingRunPayload, user.role, {}, userId, callingFrom);
                } else if (reports && Object.keys(reports).length > 0) {
                    onReportsAlreadyExist && onReportsAlreadyExist(reports);
                }
            }
        }
        return false;
    }
    return false;
};
