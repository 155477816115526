import * as React from "react";
import {Field} from "formik";

export interface FieldProps {
    autoComplete?: string;
    name: string,
    placeholder?: string,
    label?: any | string,
    id?: string,
    onBlur?: Function,
    cb?: any,
    contentList?: any,
    options?: any,
    onChange?: Function,
    component?: Function,
    values?: any,
    onSelectChange?: Function,
    InputProps?: any,
    helperText?: any,
    InputLabelProps?:any,
    className?: string
    multiline?: Boolean,
    disablePast?:Boolean,
    disableFuture?:Boolean,
    saveFormat?: string,
    disabled?: boolean,
}

const TextFieldCommon = (props: FieldProps) => {
    const { name, values, className } = props;
    return <Field
            className={`${className ? className : ""}`}
            value={values && values[name] ? values[name] : ""}
            {...props}
            fullWidth
    />;
};


export default TextFieldCommon;

export type TextFieldCommonFieldProps = FieldProps;