import React from "react";
import {Button, Typography} from "@material-ui/core";
import GenericDialog from "../../../../../components/dialog/GenericDialog";
import {useActions} from "../../../../../actions";
import * as ClientActions from "../../../../../actions/client";
import {sleep} from "../../../../../utils/commonUtils";


type NFIULOptionProps = {
    iulPopUp: boolean,
    setIulPopUp: Function,
    clientId: number
}

export const NFIULOption: React.FC<NFIULOptionProps> = (props) => {
    const { iulPopUp, setIulPopUp, clientId } = props;
    const clientActions = useActions(ClientActions as any);

    const onConfirm = async () => {
        await clientActions.updateCarrierByUserId({ clientId: clientId });
        // TODO NIWD-153 show next popup
        return sleep().then(() => window.location.reload());
    };

    const onClose = async () => {
        setIulPopUp(false);
    };

    const content = (
            <>
                <Typography variant="body1">
                    You have selected the Non-Financed IUL option. Please confirm.
                </Typography>
                <Button
                        size="small"
                        variant="contained"
                        className="mt20 mb20 floatRight"
                        color="secondary"
                        onClick={onClose}
                >
                    NO
                </Button>
                <Button
                        size="small"
                        variant="contained"
                        className="mt20 mb20 floatRight mr15"
                        color="primary"
                        onClick={onConfirm}
                >
                    YES
                </Button>
            </>
    );

    return (
            <GenericDialog
                    aria-describedby="nfiul-dialog"
                    content={content}
                    fullWidth
                    keepMounted
                    maxWidth="sm"
                    onClose={onClose}
                    open={iulPopUp}
                    title="NFIUL OPTION"
            />
    );
};